import React, { useEffect, useLayoutEffect, } from "react";
// import { motion, } from "framer-motion";
// import { motion, } from "framer-motion/dist/framer-motion";
// import Particles from "react-tsparticles";
// import { loadFull, } from "tsparticles";
import gsap from "gsap/dist/gsap";
import { ScrollTrigger, } from "gsap/dist/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import Container from "./container";
// import { ReactComponent as DoragonSticker, } from "../images/doragonsticker3.svg";
// import { ReactComponent as DoragonSticker2, } from "../images/doragonsticker3.5.svg";
import DoragonStickerHero from "./doragonsticker";
// import animatedClouds from "../images/anime-clouds3.gif";
// import sunset1 from "../images/sunset4.jpg";
// import sunset2 from "../images/sunset.gif";

// Motion example -- https://codesandbox.io/s/j2nuy?file=/src/App.js:237-1517
// const Button = ({ action }) => {
//     const [isLoading, setIsLoading] = useState(false);
//     const [bookmarked, setBookmarked] = useState(false);

//     const variants = {
//       loading: {
//         opacity: [1, 0, 1],
//         transition: { repeat: Infinity }
//       },
//       stop: {
//         opacity: 1
//       }
//     };

//     const onClick = async () => {
//       setIsLoading(true);
//       await action();
//       setBookmarked((curr) => !curr);
//       setIsLoading(false);
//     };

//     return (
//       <motion.button
//         className="flex focus:outline-none"
//         onClick={onClick}
//         disabled={isLoading}
//       >
//         <motion.span variants={variants} animate={isLoading ? "loading" : "stop"}>
//           {bookmarked ? <BsBookmarkFill /> : <BsBookmark />}
//         </motion.span>
//       </motion.button>
//     );
//   };

//   const App = () => {
//     const toggleAction = (item) => () =>
//       new Promise((resolve) => {
//         setTimeout(() => {
//           resolve(item);
//         }, 1000);
//       });

//     return (
//       <div className="min-h-screen">
//         <h2 className="text-center text-4xl font-bold text-blue-900">List:</h2>

//         <ul className="w-full m-auto bg-gray-200 p-20">
//           {items.map((item) => (
//             <li key={item} className="flex items-center space-x-2">
//               <span>Item {item}</span>
//               <Button action={toggleAction(item)} />

// const Text = styled(motion.span)`
//   font-size: ${(props) => { return props.theme.fontxl; }};
//   color: ${(props) => { return props.theme.text; }};
//   padding-top: 0.5rem;

//   @media (max-width: 48em) {
//     font-size: ${(props) => { return props.theme.fontlg; }};
//   }
// `;
// import bgclouds from "../images/bgclouds.jpg";
// import bgclouds from "../images/bgclouds2.png";
// import bgclouds from "../images/bgclouds4.png";

import cloudmask2 from "../images/cloud1Mask1.png";
import cloudmask from "../images/cloud1Mask.png";
import cloud11 from "../images/cloud1.1.png";
import Stars from "./stars";
import SeaOfClouds from "./seaOfClouds";

const Loader = ({
    setClicked, clicked, loaded, setLoaded,
}) => {
    console.log("Loader loaded: ", loaded);
    useEffect(() => {
        const text = document.getElementById("text-te5");
        const content = text.textContent;
        text.textContent = "";

        [ ...content ].map((letter) => {
            const newSpan = document.createElement("span");
            newSpan.setAttribute("aria-hidden", "true");
            if (newSpan.textContent === " ") {
                newSpan.innerHTML = "&nbsp;";
                return text.appendChild(newSpan);
            }
            newSpan.textContent = letter;
            return text.appendChild(newSpan);

            // newSpan.innerHTML = `${letter === " " ? "&nbsp;" : letter}`; // Keep space between words
        });
    }, []);
    // Uncomment to use Particles
    // const particlesInit = async (main) => {
    //     // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    //     await loadFull(main);
    // };

    // const particlesLoaded = (container) => {
    //     // you can get the tsParticles instance (container) here
    // };

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    const tl = gsap.timeline();

    const sectionRef = React.useRef(null);
    const scrollDistRef = React.useRef(null);
    const mainRef = React.useRef(null);
    const skyRef = React.useRef(null);
    const cloudMaskRef = React.useRef(null);
    const cloudMask2Ref = React.useRef(null);
    const cloud2Ref = React.useRef(null);
    const cloud3Ref = React.useRef(null);
    const cloud4Ref = React.useRef(null);
    const mountBgRef = React.useRef(null);
    const mountMgRef = React.useRef(null);
    const mountFgRef = React.useRef(null);
    const exploreRef = React.useRef(null);
    const arrowBtnRef = React.useRef(null);

    useLayoutEffect(() => {
        const scrollDist = scrollDistRef.current;
        const main = mainRef.current;
        const sky = skyRef.current;
        const cloudMask = cloudMaskRef.current;
        const cloudMask2 = cloudMask2Ref.current;
        const cloud2 = cloud2Ref.current;
        const cloud3 = cloud3Ref.current;
        const cloud4 = cloud4Ref.current;
        const mountBg = mountBgRef.current;
        const mountMg = mountMgRef.current;
        const mountFg = mountFgRef.current;
        const arrowBtn = arrowBtnRef.current;

        setTimeout(() => {
            gsap.set(main, {
                // position: "fixed",
                background: "#fff",
                // background: "transparent",
                width: "100%",
                minWidth: "120%",
                // maxWidth: "1200px",
                height: "100%",
                top: 0,
                left: "50%",
                x: "-50%",
            });
            gsap.set(scrollDist, { width: "100%", height: "200%" });
            // gsap.set(".akachan-text", { y: 0 });
            // gsap.to(".akachan-text", {
            //     duration: 1,
            //     y: 100,
            //     ease: "power2.inOut",
            //     scrollTrigger: {
            //         trigger: scrollDist,
            //         start: "top top",
            //         // end: "80% 80%",
            //         end: `+=${window.innerHeight * 1}`,
            //         scrub: 1,
            //         // snap: {
            //         //     snapTo: 1 / 3,
            //         //     duration: { min: 0.5, max: 1.5 },
            //         //     delay: 0.6,
            //         //     ease: "power2.inOut",
            //         // },
            //         // pin: true,
            //         markers: {
            //             startColor: "white",
            //             endColor: "white",
            //             fontSize: "20px",
            //             indent: 0,
            //         },
            //     },
            // });

            gsap.timeline({
                scrollTrigger: {
                    // pinType: "fixed",
                    // pin: scrollDist,
                    // pinnedContainer: scrollDist,
                    trigger: scrollDist,
                    // start: "top top",
                    start: "70% 20%",
                    end: `+=${window.innerHeight * 1}`,
                    scrub: 1,

                    // start: "70% 20%",
                    // end: "+=50%",

                    // markers: {
                    //     startColor: "white",
                    //     endColor: "white",
                    //     fontSize: "20px",
                    //     indent: 0,
                    // },
                },
            })
                .fromTo(sky, { y: -50 }, { y: -200 }, 0)
                .fromTo(cloudMask, { y: 200 }, { y: -700 }, 0)
                .fromTo(cloudMask2, { y: 200 }, { y: -700 }, 0)
                .fromTo(cloud2, { y: -150 }, { y: -500 }, 0)
                .fromTo(cloud3, { y: -50 }, { y: -450 }, 0)
                .fromTo(cloud4, { y: -80 }, { y: -450 }, 0)
                .fromTo(mountBg, { y: -10 }, { y: -100 }, 0)
                .fromTo(mountMg, { y: -30 }, { y: -250 }, 0)
                .fromTo(mountFg, { y: -50 }, { y: -600 }, 0);

            gsap.set(".hero-group", { opacity: 0.4 }, { y: -200 });
            // gsap.to("#rect1", { opacity: 1, duration: 1, delay: 1 });
            gsap.to(".hero-group", {
                // duration: 1,
                opacity: 0.2,
                // y: -100,
                ease: "power2.inOut",
                scrollTrigger: {
                    trigger: scrollDist,
                    start: "top top",
                    // end: "80% 80%",
                    end: `+=${window.innerHeight * 1}`,
                    scrub: 1,

                },
            });

            // $("#arrowBtn").on("mouseenter", (e) => {
            //     gsap.to(".arrow", {
            //         y: 10, duration: 0.8, ease: "back.inOut(3)", overwrite: "auto",
            //     });
            // });
            // $("#arrowBtn").on("mouseleave", (e) => {
            //     gsap.to(".arrow", {
            //         y: 0, duration: 0.5, ease: "power3.out", overwrite: "auto",
            //     });
            // });
            // $("#arrowBtn").on("click", (e) => { gsap.to(window, { scrollTo: innerHeight, duration: 1.5, ease: "power1.inOut" }); });

            // scrollTo requires the ScrollTo plugin (not to be confused w/ ScrollTrigger)
            ScrollTrigger.refresh();
        }, 500);
        ScrollTrigger.refresh();
        tl.kill();
        return () => {
        // Let's clear instances
            tl.kill();
            ScrollTrigger.refresh();
            // t1.ScrollTrigger.kill();
            // ScrollTrigger.kill();
            // t1.scrollTrigger.kill();
            // window.removeEventListener("refresh", scroll.update());
        };
    }, [ tl ]);
    useEffect(() => {
        setLoaded(true);
    }, [ ]);
    const doragonStickerHeroRef = React.useRef(null);
    // useEffect(() => {
    //     const paths = document.querySelectorAll("path");
    //     // const group = document.querySelector("#group");
    //     // const group = document.querySelector("#svgg");
    //     const group = doragonStickerHeroRef.current;
    //     const colours = [ chroma.scale([ "#FF7900", "#F94E5D", "#CA4B8C", "#835698", "#445582", "#2F4858" ]), chroma.scale([ "#845EC2", "#D65DB1", "#FF6F91", "#FF9671", "#FFC75F", "#F9F871" ]), chroma.scale([ "#F24B8E", "#F6ACC2", "#FFE3F1", "#59BAB7" ]), chroma.scale([ "#1FAAFE", "#00C6FF", "#00DCE4", "#10ECB8", "#A0F68B", "#F9F871" ]) ];
    //     let currentGradient = 1;

    //     setTimeout(() => {
    //         const tl2 = gsap.timeline({
    //             onReverseComplete: () => {
    //                 tl2.timeScale(1);
    //                 tl2.play(0);
    //             },
    //             onComplete: () => {
    //                 tl2.timeScale(1.5);
    //                 tl2.reverse(0);
    //             },
    //         });

    //         function generatePoints() {
    //             tl2.clear();
    //             // group.innerHTML = "";
    //             group.textContent = "";
    //             let delay = 0;
    //             paths.forEach((path) => {
    //                 const length = path.getTotalLength();
    //                 for (let i = 0; i < length; i += 1) {
    //                     const pointLength = Math.random() * length;
    //                     const point = path.getPointAtLength(pointLength);
    //                     const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    //                     circle.setAttribute("cx", point.x);
    //                     circle.setAttribute("cy", point.y);
    //                     circle.setAttribute("r", Math.random() * 3 + 1);
    //                     group.appendChild(circle);
    //                     const coloursX = (point.x / 476.5) + ((Math.random() - 0.5) * 0.2);
    //                     tl.to(circle, {
    //                         autoRound: false,
    //                         fill: colours[currentGradient % colours.length](coloursX).hex(),
    //                         cx: point.x + (Math.random() - 0.5) * 60,
    //                         cy: point.y + (Math.random() - 0.5) * 60,
    //                         duration: "random(0.5, 2)",
    //                         delay: (delay + pointLength) * 0.002,
    //                         ease: "power2.out",
    //                     }, 0);
    //                 }
    //                 delay += length;
    //             });
    //             tl2.reversed(false).play(0);
    //             currentGradient++;
    //         }
    //         window.addEventListener("click", () => {
    //             generatePoints();
    //         });
    //         generatePoints();
    //     }, 500);
    // }, []);

    return (
        <div
            className="pt-20 z-50 relative"
            style={{
                zIndex: 90,
            }}
        >

            <Container
                className=" bg-gradient-container overflow-hidden relative max-h-9/16 max-w-6xl m-auto"
                style={{
                    zIndex: 100,
                }}
            >

                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="48px"
                viewBox="0 0 24 24"
                width="48px"
                fill="none"
            >
                <g />
                <g>
                    <motion.path
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                        d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
                    />
                </g>
            </svg> */}
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <section
                    className="relative bg-theme-100 z-50 w-full  mx-auto px-4 sm:px-6 pb-16 sm:pb-0  "
                // -section
                // id="home"
                    style={{
                        zIndex: 100,
                        background: "linear-gradient(0deg, rgba(23,106,144,1) 60%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%) no-repeat border-box, radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%)",
                    }}
                    onKeyDown={() => {
                    // console.log("clicked");

                    }}
                >
                    {/* <div className="absolute -left-32  top-0 ">
                    <Stars amount={4} />
                </div>
                <div className="absolute -left-24 top-0 ">
                    <Stars amount={4} />
                </div>
                <div className="absolute right-24 top-0 ">
                    <Stars amount={4} />
                </div> */}
                    <Stars amount={3} />
                    <div className="container bg-black z-40 mx-auto px-4  absolute right-0 bottom-0">

                        <Stars amount={6} />
                    </div>
                    <div className="container mx-auto px-4  absolute -left-20 bottom-0">
                        <Stars amount={3} />
                    </div>
                    <div className="clouds-container">
                        {/* <div
                        className="clouds-1"
                    />
                    <div
                        className="clouds-2 "
                    />
                    <div
                        className="clouds-3"
                        style={{
                            // fill: "linear-gradient(0deg, rgba(11,62,95,1) 3%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%) no-repeat border-box, radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%)",
                        }}
                    /> */}
                    </div>
                    <div className="nightstars" />
                    <div className="nightstars1" />
                    <div className="nightstars2" />
                    <div className="nightshooting-stars" />

                    {/* Illustration behind hero content */}
                    <div className="text-center relative z-50 py-5  ">
                        <div
                            className="logo-text"
                        >
                            {/* <p
                            className=" sm:text-3xl text-xs  flex  text-center m-auto enter-text  "
                            style={{
                                letterSpacing: "0.25em",
                                // width: "110%",
                                // color: "#FAA307",
                                color: "#FAA307",
                                fontWeight: "bold",
                            }}
                        >
                            ENTER INSIDE THE REALM OF
                            {" "}

                        </p> */}
                            <p
                            // className="h3  sm:text-left text-center sm:text-4xl text-xl mb-3 text-theme-800 tracking-widest relative z-50 font-bold leading-loose"
                                id="text-te5"
                                className="relative enter-text mt-10 uppercase text-theme-800 tracking-tighter select-none sm:text-3xl text-xs font-black transition-all duration-500 ease-in-out shadow-text5 hover:shadow-h-text5"
                                style={{
                                    width: "100%",
                                // textShadow: " 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #dfdddc, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2;",
                                }}
                            >
                                WELCOME TO THE WIDE WORlD OF
                                <br />

                            </p>
                            <section className="relative sectionRef  " id="home" ref={sectionRef}>
                                <div
                                    className="scrollDist aboslute "
                                    ref={scrollDistRef}
                                />
                                {/* <svg width="900" height="900">
                                <circle
                                    x="50%"
                                    y="35%"
                                    dy=".35em"
                                    cx="50%"
                                    cy="50%"
                                    r="25"
                                    fill="red"
                                />
                                <path
                                    d="
                                        M 25, 50
                                        a 25,25 0 1,1 50,0
                                        a 25,25 0 1,1 -50,0
                                        "
                                />
                            </svg> */}
                                <div className="main absolute z-10 mt-0   " ref={mainRef}>
                                    <svg viewBox="0 0 1200 800" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                        <mask id="m">

                                            <g className="cloud1" ref={cloudMaskRef}>

                                                <rect fill="#fff" width="100%" height="801" y="799" />
                                                <image
                                                    href={cloudmask2}
                                                    width="1200"
                                                    height="800"
                                                />
                                                <image
                                // src="../images/cloud1Mask.png"
                                                    href={cloudmask}
                                                    width="1200"
                                                    height="800"
                                                />
                                            </g>
                                        </mask>
                                        {/* xlinkHref="https://assets.codepen.io/721952/sky.jpg"  */}
                                        {/* <image alt="sky" ref={skyRef} href="https://assets.codepen.io/721952/sky.jpg" className="sky" width="1200" height="590" /> */}
                                        {/* <image ref={mountBgRef} className="mountBg opacity-50" xlinkHref="https://assets.codepen.io/721952/mountBg.png" width="1200" height="800" /> */}
                                        {/* <DoragonSticker
                                        className="w-full h-full   opacity-40 linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8)) "
                                        style={{
                                            filter: "blur(5px)",
                                            fill: "linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8))",
                                            zIndex: 90,
                                            width: "150%",
                                        }}
                                    /> */}

                                        {/* <DoragonSticker2
                                        // fill="#fff"
                                        className="doragon-sticker-2 w-full h-full   opacity-40 linear-gradient(to right, rgb(100, 72, 153), rgb(400, 68, 68), rgb(234, 179, 8)) "
                                        style={{
                                            // filter: "blur(10px)",
                                            // fill: "linear-gradient(to right, rgb(100, 72, 153), rgb(400, 68, 68), rgb(234, 179, 8))",
                                            zIndex: 90,
                                            width: "250%",
                                        }}
                                    /> */}

                                        <defs>
                                            <linearGradient x1="80%" y1="10%" x2="50%" y2="100%" id="illustration-01">

                                                {/* <stop stopColor="#f9bf23ff" offset="10%" stopOpacity="0.7" opacity="0.8" />
                                            <stop stopColor="#9D0208" offset="77.402%" opacity="0.3" />
                                            <stop stopColor="#C71D5C" offset="95%" opacity="0.2" /> */}
                                                {/* <stop stopColor="#2D82B7" offset="10%" stopOpacity="0.5" opacity="0.8" />
                                            <stop stopColor="#42E2B8" offset="79.402%" stopOpacity="0.3" opacity="0.4" />
                                            <stop stopColor="#C71D5C" offset="95%" stopOpacity="0.3" opacity="0.5" /> */}
                                                <stop stopColor="#2D82B7" offset="10%" stopOpacity="0.6" opacity="0.8" />
                                                <stop stopColor="#42E2B8" offset="79.402%" stopOpacity="0.4" opacity="0.6" />
                                                <stop stopColor="#2D82B7" offset="95%" opacity="0.3" />
                                            </linearGradient>
                                            <filter id="blur">
                                                <feGaussianBlur stdDeviation="5" />
                                            </filter>
                                            <filter id="f2" x="0" y="0" width="200%" height="200%">
                                                <feOffset result="offOut" in="SourceGraphic" dx="10" dy="10" />
                                                <feColorMatrix
                                                    result="matrixOut"
                                                    in="offOut"
                                                    type="matrix"
                                                    values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0"
                                                />
                                                <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="10" />
                                                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                                            </filter>
                                        </defs>
                                        {/* middle circle */}
                                        <g fill="url(#illustration-01)" filter="url(#blur)" fillRule="evenodd" className="doragon-sticker-2">
                                            {/* <circle cx="1200" cy="128" r="128" /> */}

                                            {/* <circle cx="155" cy="443" r="64" /> */}
                                            <circle cx="50%" cy="50%" r="42%" />
                                        </g>

                                        <DoragonStickerHero
                                            refProp={doragonStickerHeroRef}
                                            id="hero-group"
                                            className="hero-group doragon-sticker-2 w-2/6 h-full -mt-10   opacity-20 linear-gradient(to right, rgb(100, 72, 153), rgb(400, 68, 68), rgb(234, 179, 8)) "
                                            style={{
                                                filter: "blur(10px)",
                                                zIndex: 90,
                                                width: "170%",
                                            }}
                                        />

                                        {/* <image href={bgclouds} className="sky " width="1300" height="5390" preserveAspectRatio="true" /> */}

                                        {/* <image ref={mountMgRef} className="mountMg opacity-60" xlinkHref="https://assets.codepen.io/721952/mountMg.png" width="1200" height="800" /> */}
                                        {/* <image ref={mountFgRef} className="mountFg opacity-70 " xlinkHref="https://assets.codepen.io/721952/mountFg.png" width="1200" height="800" /> */}
                                        {/* <img alt="sky" ref={skyRef} src="/images/sky.jpg" className="sky" width="1200" height="590" /> */}
                                        <image ref={cloud2Ref} className="cloud2" xlinkHref="https://assets.codepen.io/721952/cloud2.png" width="1200" height="800" />
                                        <image ref={cloud4Ref} className="cloud1" href={cloud11} width="1200" height="800" />
                                        <image ref={cloud3Ref} className="cloud3" xlinkHref="https://assets.codepen.io/721952/cloud3.png" width="1200" height="800" />

                                        <text
                                            x="50%"
                                            y="35%"
                                            dy=".35em"
                                            textAnchor="middle"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                // background: "#ddd",
                                                // fontSize: "7rem",
                                                textAlign: "center",
                                                fontSize: "13rem",

                                            }}
                                            className="akachan akachan-text enter-text transition-all duration-300 ease-out shadow-text5 hover:shadow-h-text5 hidden "
                                            id="enter-text"
                                        >
                                            Akachan

                                        </text>
                                        <text
                                            x="50%"
                                            y="55%"
                                            dy=".35em"
                                            textAnchor="middle"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                // background: "#ddd",
                                                // fontSize: "12752rem",
                                                textAlign: "center",
                                                fontSize: "13rem",
                                            }}
                                        // className="doragon akachan-text enter-text "
                                            className="akachan doragon-text enter-text transition-all duration-300 ease-out shadow-text5 hover:shadow-h-text5"
                                            id="enter-text"
                                        >
                                            Doragon

                                        </text>

                                        {/* <svg viewBox="0 0 1320 300" className="logo-svg hidden sm:flex"> */}
                                        {/* <text
                        x="350"
                        y="200"
                                    // x="50%"
                                    // y="50%"
                        dy=".35em"
                        textAnchor="middle"
                        className="text-white text-lg"
                    >
                        Akachan
                    </text> */}
                                        {/* </svg> */}
                                        {/* arrow */}
                                        {/* <polyline
                                            className="arrow"
                                            fill="#f9bf23ff"
                                            // scale="scale(2 2)"
                                            transform="scale(1.4 1.4) translate(0 0)"
                                            points="599,250 599,289 590,279 590,282 600,292 610,282 610,279 601,289 601,250"
                                        /> */}
                                        <g mask="url(#m)">
                                            <defs>
                                                <linearGradient id="Gradient1">
                                                    <stop className="stop1" offset="20%" />
                                                    <stop className="stop2" offset="80%" />
                                                    <stop className="stop3" offset="100%" />
                                                </linearGradient>
                                                <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                                                    <stop offset="0%" stopColor="#fff" />
                                                    <stop offset="50%" stopColor="#fff" />
                                                    <stop offset="100%" stopColor="#176a90" />
                                                </linearGradient>
                                            </defs>
                                            <rect id="rect1" fill="url(#Gradient2)" width="100%" height="100%" x={0} y={0}>
                                                {/* <animate
                                                attributeName="fill"
                                                values="#fff;#176a90;#fff"
                                                dur="10s"
                                                repeatCount="indefinite"
                                            /> */}
                                            </rect>
                                            {/* <text
                                            x="50%"
                                            y="75%"
                                            dy=".35em"
                                            textAnchor="middle"
                                            className="text-white text-5xl doragon-text"
                                            id="doragon-text"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                // background: "#ddd",
                                                fontSize: "10rem",
                                                textAlign: "center",
                                            }}
                                        >
                                            赤ちゃんドラゴン
                                        </text> */}
                                            <text
                                                x="50%"
                                                y="40%"
                                                dy=".35em"
                                                textAnchor="middle"
                                            // id="doragon-text"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    fontSize: "13rem",
                                                    textAlign: "center",
                                                }}
                                                id="doragon-text"
                                                className=" doragon-text "
                                            >
                                                赤ちゃん
                                            </text>
                                            <text
                                                x="50%"
                                                y="60%"
                                                dy=".35em"
                                                textAnchor="middle"
                                            // id="doragon-text"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    fontSize: "13rem",
                                                    textAlign: "center",
                                                }}
                                                id="doragon-text"
                                                className=" doragon-text "
                                            >
                                                ドラゴン
                                            </text>

                                            {/* <DoragonSticker2
                                            fill="#fff"
                                            className="w-full h-full   opacity-40 linear-gradient(to right, rgb(100, 72, 153), rgb(400, 68, 68), rgb(234, 179, 8)) "
                                            style={{
                                                // filter: "blur(10px)",
                                                zIndex: 90,
                                                width: "250%",
                                            }}
                                        /> */}

                                        </g>
                                        <rect id="arrowBtn" ref={arrowBtnRef} width="100" height="100" opacity="0" x="550" y="220" style={{ cursor: "pointer" }} />
                                    </svg>

                                </div>
                            </section>
                        </div>
                    </div>

                    <div className="max-w-6xl mx-auto px-4 sm:px-6   ">

                        {/* Hero content */}
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20 items-center self-center sm:h-screen h-auto  z-10  ">
                            <div
                                className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none w-full h-screen z-10 "
                                aria-hidden="true"
                            >
                                <p className="text-white text-xl z-40">ドラゴン</p>

                                <div
                                    className="absolute top-0 left-0 w-full h-screen z-50"
                                />
                            </div>

                            {/* Section header (.layer went here) */}

                            <div className="text-center relative z-50  ">

                                <div
                                    className="logo-text"
                                >

                                    {/*
                                <svg viewBox="0 0 1320 300" className="logo-svg hidden sm:flex">
                                    <text
                                        x="50%"
                                        y="50%"
                                        dy=".35em"
                                        textAnchor="middle"
                                        className="text-white text-lg"
                                    >
                                        Akachan
                                    </text>
                                </svg>
                            </div>
                            <div
                                className="logo-text"

                            >
                                <svg viewBox="0 0 1320 300" className="logo-svg ">
                                    <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                                        Doragon
                                    </text>
                                </svg> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Uncomment for Particles */}
                    {/* <Particles
                    className="absolute top-0 left-0 w-full h-screen z-50 bg-opacity-100 bg-transparent bg-none"
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        fpsLimit: 30,
                        particles: {
                            number: {
                                value: 0,
                                density: {
                                    enable: true,
                                    value_area: 800,
                                },
                            },
                            color: {
                                value: "#FAA307",
                                animation: {
                                    enable: true,
                                    speed: -70,
                                    sync: true,
                                },
                            },
                            shape: {
                                type: "circle",
                            },
                            opacity: {
                                value: 0.7,
                                random: false,
                                animation: {
                                    enable: true,
                                    speed: 0.5,
                                    minimumValue: 0,
                                    sync: false,
                                },
                            },
                            size: {
                                value: 4,
                                random: { enable: true, minimumValue: 1 },
                                animation: {
                                    enable: false,
                                    speed: 20,
                                    minimumValue: 4,
                                    sync: false,
                                },
                            },
                            life: {
                                duration: {
                                    value: 3,
                                },
                                count: 1,
                            },
                            move: {
                                angle: {
                                    value: 45,
                                    offset: 0,
                                },
                                enable: true,
                                gravity: {
                                    enable: true,
                                    acceleration: -0.5,
                                },
                                speed: 10,
                                direction: "top",
                                random: false,
                                straight: false,
                                size: true,
                                outModes: {
                                    default: "destroy",
                                    bottom: "none",
                                },
                                attract: {
                                    enable: false,
                                    distance: 300,
                                    rotate: {
                                        x: 600,
                                        y: 1200,
                                    },
                                },
                            },
                        },

                        detectRetina: true,
                        background: {
                            // color: "#03071E",
                            color: "transparent",
                        },
                        emitters: {
                            direction: "top",
                            rate: {
                                quantity: 15,
                                delay: 0.01,
                            },
                            size: {
                                width: 500,
                                height: 10,
                            },
                            position: {
                                x: 50,
                                y: 100,
                            },
                        },

                    }}
                /> */}
                    {/* </main> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 700 700" width="700" height="700" opacity="1">
                    <defs>
                        <linearGradient gradientTransform="rotate(150, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="ffflux-gradient">
                            <stop stopColor="rgb(12, 65, 98)" stopOpacity="1" offset="0%" />
                            <stop stopColor="rgb(23, 106, 144)" stopOpacity="1" offset="100%" />
                        </linearGradient>
                        <filter id="ffflux-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feTurbulence type="fractalNoise" baseFrequency="0.005 0.003" numOctaves="2" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence" />
                            <feGaussianBlur stdDeviation="20 0" x="0%" y="0%" width="100%" height="100%" in="turbulence" edgeMode="duplicate" result="blur" />
                            <feBlend mode="hard-light" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" in2="blur" result="blend" />

                        </filter>
                    </defs>
                    <rect width="700" height="700" fill="url(#ffflux-gradient)" filter="url(#ffflux-filter)" />

                </svg> */}
                    {/* <div className="shapedividers_com-5008" /> */}
                    <div className="shapedividers_com-658 ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none">

                            <defs>
                                <linearGradient gradientTransform="rotate(150, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="hero-gradient">
                                    <stop stopColor="#E6FFFA" stopOpacity="0.7" offset="10%" opacity="0.1" />
                                    <stop stopColor="rgb(23, 106, 144)" stopOpacity="1" offset="40%" />
                                    <stop stopColor="#2D82B7" stopOpacity="1" offset="100%" />
                                </linearGradient>
                                <filter id="ffflux-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feTurbulence type="fractalNoise" baseFrequency="0.005 0.003" numOctaves="2" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence" />
                                    <feGaussianBlur stdDeviation="20 0" x="0%" y="0%" width="100%" height="100%" in="turbulence" edgeMode="duplicate" result="blur" />
                                    <feBlend mode="hard-light" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" in2="blur" result="blend" />

                                </filter>
                            </defs>

                            <path fill="url(#hero-gradient)" d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" className="shape-fill" />
                            <path fill="url(#hero-gradient)" d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" className="shape-fill" />
                            <path fill="url(#hero-gradient)" d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" className="shape-fill" />

                            {/* <path fill="url(#hero-gradient)" d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" className="shape-fill" />
                        <path fill="url(#hero-gradient)" d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" className="shape-fill" />
                        <path fill="url(#hero-gradient)" d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" className="shape-fill" /> */}
                        </svg>
                    </div>

                    {/* <div className="custom-shape-divider-bottom-1664702694 mt-20 relative "> */}
                    {/* <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <defs>
                            <linearGradient gradientTransform="rotate(150, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="ffflux-gradient"> */}
                    {/* <stop stopColor="rgb(12, 65, 98)" stopOpacity="1" offset="0%" /> */}
                    {/* <stop stopColor="#E6FFFA" stopOpacity="0.7" offset="10%" opacity="0.1" />
                                <stop stopColor="rgb(23, 106, 144)" stopOpacity="1" offset="40%" />
                                <stop stopColor="#2D82B7" stopOpacity="1" offset="100%" />
                            </linearGradient>
                            <filter id="ffflux-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feTurbulence type="fractalNoise" baseFrequency="0.005 0.003" numOctaves="2" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence" />
                                <feGaussianBlur stdDeviation="20 0" x="0%" y="0%" width="100%" height="100%" in="turbulence" edgeMode="duplicate" result="blur" />
                                <feBlend mode="hard-light" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" in2="blur" result="blend" />

                            </filter>
                        </defs>
                        <path fill="url(#ffflux-gradient)" d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill" />
                        <path fill="url(#ffflux-gradient)" d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill" />
                        <path fill="url(#ffflux-gradient)" d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill" />
                    </svg> */}

                    {/* </div> */}
                </section>
            </Container>
        </div>
    );
};

export default Loader;
