import { useState, useEffect, } from "react";

const useDetectOutsideClick = (el, initialState) => {
    const [ isActive, setIsActive ] = useState(initialState);

    useEffect(() => {
        const pageClickEvent = (e) => {
            if (el && el.current && !el.current.contains(e.target)) {
                setIsActive(false);
            }
        };
        if (isActive) {
            window.addEventListener("click", pageClickEvent);
        }
        return () => {
            window.removeEventListener("click", pageClickEvent);
        };
    }, [ isActive, el ]);

    return [ isActive, setIsActive ];
};

export default useDetectOutsideClick;
