/* eslint-disable max-len */
import React, { useEffect, useRef, } from "react";
import { Link, } from "react-router-dom";
// import * as DoragonLogo from "../images/doragonlogo2.png";
import gsap from "gsap/dist/gsap";
// import ScrollToPlugin from "gsap/ScrollToPlugin";
import useDetectOutsideClick from "../utils/useDetectOutsideClick";

function Header({ location }) {
    const container = useRef();

    const [ isMenuOpen, setIsMenuOpen ] = useDetectOutsideClick(container, false);
    // const [ show, setShow ] = useDetectOutsideClick(container, false);
    useEffect(() => {
        // console.log("win", window.location.pathname === "/den");
        // console.log("win", window.location.pathname);
        // location();
        console.log("location", location);
    }, [ window.location.pathname ]);
    return (
        <header
            className=" fixed w-full bg-opacity-50 transition duration-300 ease-in-out bg-theme-700 hover:bg-theme-100 border-b-orange-400 "
            ref={container}
            style={{
                backdropFilter: "blur(10px)",
                // filter: "blur(10px)",
                zIndex: 100,
                borderBottom: "1px solid #FBBF24",
            }}
        >
            <div className="absolute w-full">
                <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                    // smaller size waves
                    style={{ height: "77px", minHeight: "40px", opacity: "0.5" }}
                >
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        {/* <!--Waves Colors--> */}

                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
            <div className="max-w-6xl mx-auto px-5 sm:px-6 relative  ">
                <div className="flex items-center justify-between h-16 md:h-20 ">

                    {/* Site branding */}
                    <div className="flex-shrink-0 mr-4">
                        {/* Logo */}
                        <div
                            className="text-theme-800  hover:text-theme-1000 z-30 text-2xl font-bold md:text-lg tracking-wider"
                            role="button"
                            aria-hidden="true"
                        >
                            <Link to="/">Akachan Doragon</Link>

                        </div>
                    </div>

                    <div className="flex sm:flex-row flex-col md:flex md:flex-row sm:hidden items-center space-x-6 ">
                        <div className="sm:flex flex-row hidden items-center justify-around  mr-10 ">
                            <div
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                                className="text-theme-800 textbg-header py-7 px-4 hover:text-theme-1000 z-30 md:text-lg tracking-wider"
                                onClick={() => {
                                    gsap.to(window, { duration: 1, scrollTo: { y: "#features", offsetY: 50 } });
                                }}
                            >
                                {window.location.pathname !== "/den" && "LORE"}

                            </div>
                            <div
                                className="text-theme-800 textbg-header py-7 px-4  hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                role="button"
                                aria-hidden="true"
                                style={{
                                    fontSize: "1rem",
                                    height: "100%",
                                }}
                                onClick={() => {
                                    gsap.to(window, { duration: 2, scrollTo: "#roadmap" });
                                }}
                            >

                                {window.location.pathname !== "/den" && "Tokenomics"}

                            </div>
                            {/* <div
                                className="text-theme-800 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                                // onClick={() => {
                                //     gsap.to(window, { duration: 2, scrollTo: "#roadmap" });
                                // }}
                            >

                                <a href="https://akachandoragon.gitbook.io/doragon-litepaper/overview/doragon-universe" target="_blank" rel="noreferrer">
                                    {window.location.pathname !== "/den" && "Litepaper"}
                                </a>
                            </div> */}
                            <div
                                className="text-theme-800 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                                onClick={() => {
                                    gsap.to(window, { duration: 2, scrollTo: "#faq" });
                                }}
                            >
                                {window.location.pathname !== "/den" && "FAQ"}

                            </div>
                            <div
                                className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                            >
                                {window.location.pathname === "/den" ? "" : (
                                    <a href="https://app.akachandoragon.com">

                                        <p className="ping bg-none">
                                            Doragon Den
                                        </p>
                                    </a>
                                )}

                            </div>
  
                                <div className="flex flex-row items-center justify-around  mr-10 ">
                                <div
                                className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                            >
                                {window.location.pathname === "/den" ? "" : (
                                     <a href="https://birdeye.so/token/CYF7DokmWdSHPzHaFw8CaVJfTzCMNT3MtMtMeFww2Av?chain=solana"
                                    target="_blank" rel="noreferrer"
                                    >
<>
                                        {/* // <div className=" bg-none group flex relative"> */}
                                             <img src="../images/birdeye.jpg" alt="Birdeye Logo" width={30} height={30}
                                             className="rounded-full"
                                             />
                                                 {/* <span class="group-hover:opacity-100 transition-opacity bg-theme-150 px-1 -mt-6 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Soon...</span> */}
                                        {/* // </div> */}
    </>
                                     </a>
                                )}

                            </div>
                             
                              <div
                                // className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                 
                                className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                              
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                            >
                                {window.location.pathname === "/den" ? "" : (
                                    <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=sol&fixed=in"
                                    target="_blank" rel="noreferrer"
                                    >

                                        <div className=" bg-none group flex relative">
                                           <img src="../images/Raydium-RAY-coin.png" alt="Raydium Logo" width={30} height={30} />
                                           {/* <span class="group-hover:opacity-100 transition-opacity bg-theme-150 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Soon...</span> */}
                                        </div>
                                    </a>
                                )}
</div>
                            </div>
                        
                        </div>

                        
                        {window.location.pathname === "/den" && (
                            <div
                                className="text-theme-900 hover:text-theme-1000 z-30  tracking-wider mt-2 text-sm m-auto "
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                                onClick={() => {
                                    setIsMenuOpen(!isMenuOpen);
                                }}
                            >
                                <Link to="/">Back Home</Link>

                            </div>
                        )}
                        {window.location.pathname !== "/den" && (

                            <div
                                className={`md:hidden w-full flex flex-col absolute   items-center space-y-5 ${isMenuOpen ? " mt-0" : "mt-0"}`}
                                style={{
                                    top: "20px",
                                    left: "30%",
                                }}
                            >
                                <button type="button" className="outline-none menu-button" onClick={() => { setIsMenuOpen(!isMenuOpen); }}>
                                    <svg
                                        className="w-6 h-6 text-white"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 00 24 24"
                                        stroke="currentColor"
                                    >
                                        <path d="m4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                </button>
                                <div
                                    className={`
                                 flex flex-col items-start w-auto absolute  text-left bg-theme-175 text-theme-400 rounded-lg 
                                transition ease-in-out transform duration-300 ${isMenuOpen ? "translate-y-5" : "translate-y-0"}`}
                                    style={{
                                    // top: "100%",
                                        top: "20px",
                                        left: "12%",
                                        borderBottom: "1px solid #FBBF24",
                                        borderRight: "1px solid #FBBF24",
                                        borderLeft: "1px solid #FBBF24",
                                        borderRadius: "0 0 10px 10px",
                                    }}
                                >
                                    {isMenuOpen && (

                                        <ul className="p-3">
                                            <li className="active">
                                                <div
                                                    role="button"
                                                    aria-hidden="true"
                                                    className="text-white hover:text-theme-1000 z-30  tracking-wider mt-2 text-md"
                                                    onClick={() => {
                                                        gsap.to(window, { duration: 1, scrollTo: { y: "#features", offsetY: 50 } });
                                                        setIsMenuOpen(!isMenuOpen);
                                                    }}
                                                >
                                                    {window.location.pathname !== "/den" && "Features"}

                                                </div>
                                            </li>
                                            <li>
                                                {/* <div
                                                    className="text-white hover:text-theme-1000 z-30  tracking-wider mt-2 text-md"
                                                    role="button"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        // gsap.to(window, { duration: 1, scrollTo: { y: "#roadmap", offsetY: -250 } });
                                                        setIsMenuOpen(!isMenuOpen);
                                                    }}
                                                >

                                                    <a href="https://akachandoragon.gitbook.io/doragon-litepaper/overview/doragon-universe" target="_blank" rel="noreferrer">
                                                        {window.location.pathname !== "/den" && "Litepaper"}
                                                    </a>
                                                </div> */}
                                            </li>
                                            <li>
                                                <div
                                                    className="text-white hover:text-theme-1000 z-30  tracking-wider mt-2 text-md"
                                                    role="button"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        gsap.to(window, { duration: 1, scrollTo: { y: "#roadmap", offsetY: -250 } });
                                                        setIsMenuOpen(!isMenuOpen);
                                                    }}
                                                >

                                                    {window.location.pathname !== "/den" && "Tokenomics"}

                                                </div>
                                            </li>
                                            <li>

                                                <div
                                                    className="text-white hover:text-theme-1000 z-30  tracking-wider mt-2 text-md"
                                                    role="button"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        gsap.to(window, { duration: 2, scrollTo: "#faq" });

                                                        setIsMenuOpen(!isMenuOpen);
                                                    }}
                                                >
                                                    {window.location.pathname !== "/den" && "FAQ"}

                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    className="text-theme-900  hover:text-theme-1000 z-30  tracking-wider mt-2 text-md"
                                                    role="button"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        setIsMenuOpen(!isMenuOpen);
                                                    }}
                                                >
                                                    {window.location.pathname === "/den" ? <Link to="/">Back Home</Link> : <Link to="/den">Doragon Den</Link>}

                                                </div>

                                            </li>
                                             <div className="flex flex-row items-center justify-around  mr-10 ">
                                            <div
                                className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                            >
                                {window.location.pathname === "/den" ? "" : (
                                    <a href="https://birdeye.so/token/CYF7DokmWdSHPzHaFw8CaVJfTzCMNT3MtMtMeFww2Av?chain=solana"
                                        target="_blank" rel="noreferrer"
                                    >
<>
                                        {/* // <div className=" bg-none group flex relative"> */}
                                             <img src="../images/birdeye.jpg" alt="Birdeye Logo" width={30} height={30}
                                             className="rounded-full"
                                             />
                                                 {/* <span class="group-hover:opacity-100 transition-opacity bg-theme-150 px-1 -mt-6 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Soon...</span> */}
                                        {/* // </div> */}
    </>
                                    </a>
                                )}

                            </div>
                           
                              <div
                                // className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                                 
                                className="text-theme-900 textbg-header py-7 px-4 hover:text-theme-1000 z-30 text-xs md:text-lg tracking-wider"
                              
                                role="button"
                                aria-hidden="true"
                                style={{ fontSize: "1rem" }}
                            >
                                {window.location.pathname === "/den" ? "" : (
                                    <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=sol&fixed=in"
                                        target="_blank" rel="noreferrer"
                                    >

                                        <div className=" bg-none group flex relative">
                                           <img src="../images/Raydium-RAY-coin.png" alt="Raydium Logo" width={30} height={30} />
                                        </div>
                                    </a>
                                )}

                            </div> 
                            </div>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {/*
                    <div aria-hidden className=" bg-gray-100 flex flex-col justify-center " onClick={() => { setIsMenuOpen(!setIsMenuOpen); }}>
                        <div className="relative py-3 sm:max-w-xl mx-auto">
                            <nav x-data="{ open: false }">
                                <button type="button" className="text-gray-500 w-10 h-10 relative focus:outline-none bg-white">
                                    <span className="sr-only">Open main menu</span>
                                    <div className="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
                                        <span aria-hidden="true" className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" ${isMenuOpen ? "rotate-45 -translate-y-1.5" : ""}`} />
                                        <span aria-hidden="true" className={`block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out" ${isMenuOpen ? "opacity-0" : " "}`} />
                                        <span aria-hidden="true" className={`block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out" ${isMenuOpen ? "-rotate-45 translate-y-1.5" : " "}`} />
                                    </div>
                                </button>
                            </nav>
                        </div>
                    </div>
                    {isMenuOpen && (
                        <div className="sm:hidden flex mobile-menu text-white mt-20">
                            <svg
                                xmlns="<http://www.w3.org/2000/svg>"
                                id="menu-button"
                                className="h-6 w-6 cursor-pointer md:hidden block"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        /
                            <ul className="mt-20">
                                <li className="active"><a href="index.html" className="block text-sm px-2 py-4 text-white bg-green-500 font-semibold">Home</a></li>
                                <li><a href="#services" className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">Services</a></li>
                                <li><a href="#about" className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">About</a></li>
                                <li><a href="#contact" className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">Contact Us</a></li>
                            </ul>
                            </div>
                            )}
                        / */}

                </div>
            </div>
        </header>
    );
}
export default Header;

// <div className="sm:hidden w-full block md:w-auto" id="navbar-default">
//     <ul className="flex flex-col p-4 mt-64 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
//         <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page">Home</a>
//         </li>
//         <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
//         </li>
//         <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
//         </li>
//         <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
//         </li>
//         <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
//         </li>
//     </ul>
// </div>
