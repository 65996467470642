import React, {
    useState, useEffect, useLayoutEffect, useRef,
} from "react";

import { gsap, } from "gsap";
import { ScrollTrigger, } from "gsap/ScrollTrigger";
import Draggable from "gsap/Draggable";
import ReactPlayer from "react-player";

import Doragon1 from "../videos/outlinespin50000-0120.mp4";
import Doragon2 from "../videos/spinwebsite0000-0120.mp4";
import Doragon65 from "../videos/6.5-spin0000-0120.mp4";
import Doragon7 from "../videos/greenriver7-spin0000-0120.mp4";
import Doragon8 from "../videos/redstripe-horn-spin0001-0120.mp4";
import Doragon9 from "../videos/leafy-spin0000-0120.mp4";
import Doragon10 from "../videos/heartember-spin0000-0120.mp4";
import Doragon11 from "../videos/cosmic-spin0000-0120.mp4";
import Doragon12 from "../videos/raincloud-spin0000-0120.mp4";

const Carousel = () => {
    const [ items, setItems ] = useState([
        {
            id: 0,
            active: false,
            el: document.getElementById("carousel-item-1"),
            src: Doragon1,
        },
        {
            id: 1,
            active: false,
            el: document.getElementById("carousel-item-2"),
            src: Doragon2,
        },
        {
            id: 2,
            active: false,
            el: document.getElementById("carousel-item-3"),
            src: Doragon1,
        },
        {
            id: 3,
            active: false,
            el: document.getElementById("carousel-item-4"),
            src: Doragon2,
        },
        {
            id: 4,
            active: false,
            el: document.getElementById("carousel-item-5"),
            src: Doragon65,
        },
        {
            id: 5,
            active: false,
            el: document.getElementById("carousel-item-6"),
            src: Doragon7,
        },
        {
            id: 6,
            active: false,
            el: document.getElementById("carousel-item-6"),
            src: Doragon8,
        },
        {
            id: 7,
            active: false,
            el: document.getElementById("carousel-item-6"),
            src: Doragon9,
        },
        {
            id: 8,
            active: false,
            el: document.getElementById("carousel-item-7"),
            src: Doragon10,
        },
        {
            id: 9,
            active: false,
            el: document.getElementById("carousel-item-7"),
            src: Doragon11,
        },

        {
            id: 10,
            active: false,
            el: document.getElementById("carousel-item-7"),
            src: Doragon12,
        },
    ]);
    const [ currentItem, setCurrentItem ] = useState(0);
    // const { scroll } = useLocomotiveScroll();
    const [ count, setCount ] = useState(0);

    const ref = useRef(null);
    const horizontalRef = useRef(null);
    const q = gsap.utils.selector(ref);
    const q2 = gsap.utils.selector(horizontalRef);

    const itemRefs = useRef(null);
    gsap.registerPlugin(ScrollTrigger, Draggable);

    useLayoutEffect(() => {
        const element = ref.current;
        const scrollingElement = horizontalRef.current;
        const pinWrapWidth = scrollingElement.offsetWidth;

        const item = itemRefs.current;
        const t1 = gsap.timeline();

        setTimeout(() => {
            // Draggable WIP - https://greensock.com/forums/topic/24275-how-to-trigger-draggable-event-with-scrolltrigger/#comment-147497

            // const handler = document.querySelector("#handler");
            // let barLength; let maxScroll;

            // // this ScrollTrigger will use the window/<body> by default, calling onRefresh when the page resizes, and onUpdate whenever any scroll happens.
            // const trigger = ScrollTrigger.create({
            //     onRefresh: onResize,
            //     onUpdate: updateHandler,
            // });

            // const draggable = Draggable.create(handler, {
            //     type: "x",
            //     bounds: ".bar",
            //     onDrag: function () {
            //         trigger.scroll(this.x / barLength * maxScroll); // when dragging, scroll the page to the corresponding ratio
            //     },
            // })[0];

            // function onResize() {
            //     if (trigger) {
            //         maxScroll = ScrollTrigger.maxScroll(window); // record the maximum scroll value for the page
            //         barLength = document.querySelector(".bar").offsetWidth - handler.offsetWidth;
            //         updateHandler();
            //     }
            // }

            // function updateHandler() {
            //     // move the handler to the corresponding ratio according to the page's scroll position.
            //     gsap.set(handler, { x: barLength * trigger.scroll() / maxScroll });
            // }
            const tl = gsap.timeline({ paused: true });
            const d = Draggable.create(".drag-el", {
                type: "x",
                bounds: { minX: -100, maxX: 100 },
                onDrag: () => {
                    tl.progress((this.maxX - this.x) / 200);
                },
                onClick: () => {
                    console.log("clicked");
                },
                onDragEnd: () => {
                    console.log("drag ended");
                    doSnap();
                },
            });
            const snapValues = gsap.utils.snap([ -100, -36, 38, 100 ]);
            const doSnap = () => {
                const _d = d[0];
                gsap.set(".drag-el", { x: snapValues(_d.x) });
                gsap.to(tl, {
                    duration: 0.2,
                    delay: 0.1,
                    progress: (100 - snapValues(_d.x)) / 200,
                    onComplete: _d.update,
                });
            };
            const playhead = { frame: 0 };
            const frameCount = 298;
            tl.to(playhead, {
                frame: frameCount,
                snap: "frame",
                ease: "none",
                // onUpdate: () => {
                //     updateImage(playhead.frame);
                // },
            });
            const sections = gsap.utils.toArray(".item");
            const dBoxes = gsap.utils.toArray(".down");
            const uBoxes = gsap.utils.toArray(".up");

            // t1.to(element, {
            //     lazy: false,
            //     scrollTrigger: {
            //         trigger: element,
            //         start: "top top",
            //         end: pinWrapWidth,
            //         scroller: ".App", // locomotive element
            //         scrub: true,
            //         pin: true,
            //         markers: true,
            //         // position: "fixed",
            //         anticipatePin: 1,
            //         // pinReparent: true,
            //         // toggleActions: "play none none reverse",
            //     },
            //     // we have to increase scrolling height of this section same as the scrolling element width
            //     height: `${scrollingElement.scrollWidth}px`,
            //     ease: "none,",
            // });
            // console.log("sections", sections.length);
            const scrollTween = gsap.to(sections, {
                xPercent: -100 * ((sections.length * 1.2) - 1),
                ease: "sine",
                scrollTrigger: {
                    trigger: element,
                    pin: true,
                    // end: `+=${window.innerHeight * 2}`,
                    scrub: 4,
                    invalidateOnRefresh: true,
                    ease: "none",
                    fastScrollEnd: true,
                    // preventOverlaps: true,
                    // position: "fixed",
                    // fastScrollEnd: true,
                    // snap: 1 / (sections.length - 1),
                    end: () => { return `+=${element.offsetWidth * 3}`; },
                },

            });

            const dBox = dBoxes.forEach((e) => {
                gsap.to(e, {
                    yPercent: 50,
                    opacity: 0,
                    lazy: false,
                    scrollTrigger: {
                        containerAnimation: scrollTween,
                        trigger: e,
                        start: "left center",
                        end: "left left",
                        scrub: true,
                    },
                });
            });

            const uBox = uBoxes.forEach((e) => {
                gsap.to(e, {
                    yPercent: -50,
                    opacity: 0,
                    scrollTrigger: {
                        containerAnimation: scrollTween,
                        trigger: e,
                        start: "left center",
                        end: "left left",
                        scrub: true,
                    },
                });
            });
            t1.add(scrollTween.paused()).add(dBox).add(uBox);
            // add(() => {

            //     dBox.play();
            //     uBox.play();
            // });
            // add(dBox.play()).add(uBox.play());

            // // Horizontal Scrolling
            // t1.to(scrollingElement, {
            //     lazy: false,
            //     scrollTrigger: {
            //         trigger: scrollingElement,
            //         start: "top top",
            //         end: pinWrapWidth,
            //         scroller: ".App", // locomotive element
            //         scrub: true,
            //         pin: true,
            //         markers: true,
            //         // position: "fixed",
            //         // anticipatePin: 1,
            //         // pinReparent: true,
            //         // toggleActions: "play none none reverse",
            //     },
            //     // we have to increase scrolling height of this section same as the scrolling element width
            //     x: -pinWrapWidth,
            //     ease: "none,",
            // });
            // ScrollTrigger.observe({
            //     trigger: scrollTween,
            //     start: "top top",
            //     end: "bottom bottom",
            //     onChange: (self) => {
            //         console.log("self", self);
            //     },
            // });
            // console.log("scrollingElement", scrollingElement, pinWrapWidth);
            // scrollTween.re
            ScrollTrigger.sort();
            ScrollTrigger.refresh();
        }, 500);
        ScrollTrigger.refresh();
        // t1.ScrollTrigger.refresh();
        return () => {
        // Let's clear instances
            t1.kill();
            ScrollTrigger.refresh();
            // t1.ScrollTrigger.kill();
            // ScrollTrigger.kill();
            // t1.scrollTrigger.kill();
            // window.removeEventListener("refresh", scroll.update());
        };
    }, [ q, q2 ]);

    return (
        <div className="row border-t-2  pb-20 border-theme-800 bg-theme-100 ">

            <div className="carousel-container overflow-hidden w-screen nightsky-container bg-theme-100 ">

                <section
                    ref={ref}
                    id="refShop"
                    className="nightsky "
                    style={{
                        // oveflowX: "hidden",
                        minHeight: "100vh",
                        height: "100vh",
                        width: "100%",
                        margin: "0 auto",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        position: "relative",

                    }}
                >
                    {/* Noface */}
                    <div className="wrapper">
                        <div className="body">
                            <div className="head">
                                <div className="eye" />
                                <div className="eye" />
                                <div className="mouth" />
                            </div>
                        </div>
                    </div>
                    {/* <div id="noface">
                    <div id="body">
                        <div id="face">
                            <div id="LtopCon" />
                            <div id="RtopCon" />
                            <div id="lEye">
                                <div id="LrefEye">
                                    <div id="Ltear">
                                        <div id="LinTear" />
                                    </div>
                                </div>
                                <div className="eyeBags" />
                            </div>

                            <div id="rEye">
                                <div id="RrefEye">
                                    <div id="Rtear">
                                        <div id="RinTear" />
                                    </div>
                                </div>
                                <div className="eyeBags" />
                            </div>

                            <div id="LbotCon" />
                            <div id="RbotCon" />

                            <div id="mouth" />
                        </div>
                    </div>
                </div> */}
                    {/* <p

                    -speed="-1"
                    className=" text-3xl text-theme-900 font-bold items-center justify-center z-50 w-full absolute top-0 left-0"
                >
                    New Doragons
                </p> */}
                    {/* <!-- Carousel wrapper --> */}
                    {/* <div className="pin-wrap  relative h-56 overflow-hidden rounded-lg sm:h-64 xl:h-80 2xl:h-96  bg-amber-600 ">
                <div className=" animation-wrap to-right flex flex-row m-auto justify-center relative"> */}

                    <div
                        className="horizontal"
                        ref={horizontalRef}
                        id="horizontal"
                        style={{
                            position: "absolute",
                            // top: "20%",
                            // left: "35%",
                            // paddingLeft: "30%",
                            minHeight: "100vh",
                            display: " flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            height: "100vh",
                            width: "100%",
                            // overflow: "hidden",
                            // zIndex: 50,

                        }}
                    >
                        {items.map((item, index) => {
                            // item.id === currentItem ? item.active = true : item.active = false;
                            return (
                                <div
                                    className={`z-50 drag-el pointer-events-none item ${index % 2 === 0 ? "down" : "up"} `}
                                    key={item.id}
                                    style={{
                                        width: "26rem",
                                        marginRight: "8rem",

                                        display: "flex",
                                        flexDirection: "column",
                                        justifContent: "center",
                                        alignItems: "center",
                                        borderRadius: "20px",

                                    // overflow: "hidden",
                                    // position: "relative",
                                    }}
                                >

                                    {/* <Transition
                                // show={tab === 1}
                                // show={item.id === currentItem ? setItems(!item.active) : ""}
                                show
                                appear
                                className="w-full"
                                enter="transition ease-in-out duration-700 transform order-first"
                                enterStart="opacity-0 translate-y-16"
                                enterEnd="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300 transform absolute"
                                leaveStart="opacity-100 translate-y-0"
                                leaveEnd="opacity-0 -translate-y-16"
                            > */}

                                    {/* <div className="player-wrapper m-auto  transform -translate-x-1/2 items-center justify-center  z-50 "> */}
                                    <ReactPlayer
                                        loop
                                        playing
                                        muted
                                        transition={{ duration: 0.5 }}
                                        url={item.src}
                                        className="react-player"
                                        style={{
                                            pointerEvents: "none",
                                            width: "250%", // Reset width
                                            // height: "auto",
                                            margin: "0 auto",
                                            transform: "translate( 50%)",
                                            // width: "20rem",
                                            marginRight: "6rem",
                                            borderRadius: "1rem",

                                            display: "flex",
                                            flexDirection: "column",
                                            justifContent: "center",
                                            alignItems: "center",
                                            zIndex: "100 !important",
                                        }}
                                    />
                                    {/* </div>
                                </div> */}
                                    {/* </Transition> */}
                                </div>
                            );
                        })}
                    </div>
                    <div className="clouds-container ">
                        <div
                            className="clouds-1"
                        />
                        <div
                            className="clouds-2 "
                        />
                        <div
                            className="clouds-3"
                        />

                        {/* <div
                        className="clouds-4"
                    />
                    <div
                        className="clouds-5 "
                    />
                    <div
                        className="clouds-6"
                    /> */}
                    </div>
                    {/* </div>
            </div> */}
                    <div className="nightstars z-20" />
                    <div className="nightstars1 z-20" />
                    <div className="nightstars2 z-20" />
                    {/* <div className="divider__herowave ">
                        <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"> */}
                    {/* <defs>
                            <linearGradient id="gradient" x1="50%" y1="100%" x2="50%" y2="0%">
                                <stop offset="5%" stopColor="#2d82b7" opacity="0.3" />
                                <stop offset="95%" stopColor="#42e2b8" opacity="0.3" />
                            </linearGradient>
                        </defs>
                        <path d="M 0,600 C 0,600 0,150 0,150 C 88.68899521531097,161.30143540669857 177.37799043062194,172.60287081339715 285,167 C 392.62200956937806,161.39712918660285 519.1770334928232,138.88995215311007 611,135 C 702.8229665071768,131.11004784688993 759.913875598086,145.83732057416267 838,144 C 916.086124401914,142.16267942583733 1015.1674641148327,123.76076555023924 1119,122 C 1222.8325358851673,120.23923444976076 1331.4162679425835,135.1196172248804 1440,150 C 1440,150 1440,600 1440,600 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" fillOpacity="0.4" className="transition-all duration-300 ease-in-out delay-150 path-0" /> */}
                    {/* <defs>
                                <linearGradient id="gradient1" x1="50%" y1="100%" x2="50%" y2="0%">
                                    <stop offset="55%" stopColor="#2d82b7" />
                                    <stop offset="25%" stopColor="#42e2b8" />

                                </linearGradient>
                            </defs>
                            <path d="M 0,600 C 0,600 0,300 0,300 C 89.24401913875596,278.31578947368416 178.48803827751192,256.6315789473684 283,272 C 387.5119617224881,287.3684210526316 507.2918660287081,339.7894736842106 598,355 C 688.7081339712919,370.2105263157894 750.3444976076555,348.2105263157895 830,330 C 909.6555023923445,311.7894736842105 1007.33014354067,297.36842105263156 1112,293 C 1216.66985645933,288.63157894736844 1328.334928229665,294.3157894736842 1440,300 C 1440,300 1440,600 1440,600 Z" stroke="none" strokeWidth="0" fill="url(#gradient1)" fillOpacity="0.53" className="transition-all duration-300 ease-in-out delay-150 path-1" /> */}
                    {/* <defs>
                                <linearGradient id="gradient2" x1="50%" y1="100%" x2="50%" y2="0%">
                                    <stop offset="25%" stopColor="#2d82b7" />
                                    <stop offset="95%" stopColor="#42e2b8" stopOpacity="0.2" opacity="0.5" />

                                </linearGradient>
                            </defs>
                            <path d="M 0,600 C 0,600 0,450 0,450 C 115.24401913875596,474.3732057416268 230.48803827751192,498.7464114832536 312,502 C 393.5119617224881,505.2535885167464 441.2918660287082,487.3875598086125 522,476 C 602.7081339712918,464.6124401913875 716.3444976076553,459.7033492822967 835,467 C 953.6555023923447,474.2966507177033 1077.33014354067,493.7990430622009 1179,493 C 1280.66985645933,492.2009569377991 1360.334928229665,471.10047846889955 1440,450 C 1440,450 1440,600 1440,600 Z" stroke="none" strokeWidth="0" fill="url(#gradient2)" fillOpacity="1" className="transition-all duration-300 ease-in-out delay-150 path-2" />

                        </svg> */}
                    {/* </div> */}
                    {/* <div className="row stupid-blue">
                        <div>
                            <svg id="" preserveAspectRatio="xMidYMax meet" className="svg-separator sep10" viewBox="0 0 1600 200" style={{ display: "block" }} data-height="200">
                                <path className="" style={{ opacity: " 1", fill: "#2574a9" }} d="M-16,129.803C28.268,129.803,43.874,86,74.839,86 c26.605,0,15.874,35.884-0.894,27.723c8.831,0,8.943-12.52,0.894-12.52c-12.967,0-9.167,38.455,26.829,38.455s1.409,0,1.409,0 v16.097H-16V129.803z" />
                                <path className="" style={{ opacity: " 1", fill: "#1e8bc3" }} d="M-114,123.448C-17.538,133.448,16.468,38,83.943,38 c57.974,0,34.59,78.192-1.949,60.41c19.244,0,19.487-27.282,1.949-27.282c-28.256,0-19.974,83.795,58.462,83.795s3.071,0,3.071,0 V180H-114V123.448z" />
                                <path className="" style={{ opacity: " 1", fill: "#1e8bc3" }} d="M158,172.749C238.596,172.749,267.01,93,323.386,93 c48.439,0,28.901,65.332-1.628,50.474c16.079,0,16.282-22.795,1.628-22.795c-23.609,0-16.689,70.013,48.846,70.013s2.566,0,2.566,0 L158,192V172.749z" />
                                <path className="" style={{ opacity: " 1", fill: "#2574a9" }} d="M156,160.626c53.515,0,72.381-52.953,109.815-52.953 c32.163,0,19.19,43.38-1.081,33.514c10.676,0,10.811-15.136,1.081-15.136c-15.676,0-11.081,46.488,32.433,46.488s1.704,0,1.704,0 V192H156V160.626z" />
                                <path className="" style={{ opacity: " 1", fill: "#2574a9" }} d="M-108.04,114.107c68.046,0,92.035-67.331,139.634-67.331 c40.897,0,24.4,55.159-1.375,42.615c13.575,0,13.747-19.245,1.375-19.245c-19.933,0-14.09,59.111,41.24,59.111s2.166,0,2.166,0V154 h-183.04V114.107z" />
                                <path className="" style={{ fill: " rgb(25, 181, 254)" }} d="M-4,142.333C121.654,142.333,165.952,18,253.846,18 c75.519,0,45.058,101.856-2.538,78.692c25.067,0,25.385-35.538,2.538-35.538c-36.808,0-26.019,109.154,76.154,109.154 s1276,0,1276,0V216H-4V142.333z" />

                            </svg>
                        </div>
                    </div> */}
                    <div className="custom-shape-divider-bottom-1665190033 ">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill" />
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill" />
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill" />
                        </svg>
                    </div>
                </section>
            </div>
        </div>

    );
};

export default Carousel;
