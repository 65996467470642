import React from "react";

const SeaOfClouds = ({ className, style }) => {
    return (
        <>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="1890"
                // height="1501"
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 1890 1501"
                style={{
                    zIndex: 10,
                    marginRight: "-2px",
                    // display: "block",
                    position: "absolute",
                    bottom: "25%",
                    backgroundRepeatY: "initial",
                    backgroundRepeatX: "initial",
                    height: "75%",
                    // backgroundColor: "rgb(221, 221, 221)",
                }}
            >
                <g transform="translate(945,750.5) scale(1,1) translate(-945,-750.5)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}>
                    <defs>
                        <linearGradient id="lg-0.9726608966325525" x1="0" x2="0" y1="0" y2="1">
                            {/* <stop stopColor="#f9bf23ff" offset="0" />

                            <stop stopColor="#f9bf23ff" stopOpacity="0.2" offset="1" /> */}
                            <stop stopColor="#2da6f1" offset="0" />

                            <stop stopColor="#e9f6ff" offset="1" />
                        </linearGradient>
                    </defs>

                    <rect x="0" y="0" width="1890" height="1501" fill="url(#lg-0.9726608966325525)" />
                    <circle cx="0" cy="541.117824742169" r="133.23514604819258" fill="#91cbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="133.23514604819258" cy="634.057020073517" r="64.9419550938517" fill="#91cbf0" transform="matrix(1,0,0,1,-0.005429744720458984,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542974, 0)" }} />
                    <circle cx="198.17710114204428" cy="561.1388364976777" r="147.47896245081165" fill="#91cbf0" transform="matrix(1,0,0,1,-0.022117653861641884,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221177, 0)" }} />
                    <circle cx="345.65606359285596" cy="635.160364065629" r="86.50911463146176" fill="#91cbf0" transform="matrix(1,0,0,1,-0.05069183558225632,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0506918, 0)" }} />
                    <circle cx="432.16517822431774" cy="649.4360037475259" r="79.18075240722446" fill="#91cbf0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="511.3459306315422" cy="625.8174421798897" r="136.52878749543527" fill="#91cbf0" transform="matrix(1,0,0,1,-0.14621886610984802,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
                    <circle cx="647.8747181269775" cy="658.9488012338261" r="111.45237732585112" fill="#91cbf0" transform="matrix(1,0,0,1,-0.21463720500469208,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="759.3270954528286" cy="684.9434014785651" r="68.10132779722626" fill="#91cbf0" transform="matrix(1,0,0,1,-0.2978716492652893,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.297872, 0)" }} />
                    <circle cx="827.428423250055" cy="686.4864362515492" r="97.21253867517193" fill="#91cbf0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="924.6409619252269" cy="697.6530868545327" r="129.46064015280794" fill="#91cbf0" transform="matrix(1,0,0,1,-0.5121519565582275,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
                    <circle cx="1054.1016020780348" cy="688.2981485127052" r="91.68250379210927" fill="#91cbf0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="1145.784105870144" cy="681.1302808770777" r="77.93148636686004" fill="#91cbf0" transform="matrix(1,0,0,1,-0.7960466146469116,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.796047, 0)" }} />
                    <circle cx="1223.715592237004" cy="665.4787568776474" r="100.36388253107138" fill="#91cbf0" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="1324.0794747680754" cy="639.1748399387056" r="128.43563629515066" fill="#91cbf0" transform="matrix(1,0,0,1,-1.156632423400879,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.15663, 0)" }} />
                    <circle cx="1452.5151110632262" cy="643.0367399784491" r="89.94031167139924" fill="#91cbf0" transform="matrix(1,0,0,1,-1.3677335977554321,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
                    <circle cx="1542.4554227346255" cy="606.6629772360415" r="124.34468346873686" fill="#91cbf0" transform="matrix(1,0,0,1,-1.600266933441162,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="1666.8001062033622" cy="567.8492957468043" r="145.27038538540907" fill="#91cbf0" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="1812.0704915887713" cy="562.8994976866354" r="125.42710959117012" fill="#91cbf0" transform="matrix(1,0,0,1,-2.131143569946289,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.13114, 0)" }} />
                    <circle cx="1937.4976011799415" cy="571.6579534193673" r="102.6268089324897" fill="#91cbf0" transform="matrix(1,0,0,1,-2.4294803142547607,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="0" cy="707.4013510333857" r="77.99887413884528" fill="#88c7f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="77.99887413884528" cy="682.7804016601718" r="107.37925351612958" fill="#88c7f0" transform="matrix(1,0,0,1,-0.022117653861641884,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221177, 0)" }} />
                    <circle cx="185.37812765497486" cy="750.1847251824429" r="52.680195733819346" fill="#88c7f0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="238.0583233887942" cy="747.98475803058" r="59.056502724593265" fill="#88c7f0" transform="matrix(1,0,0,1,-0.21463720500469208,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="297.1148261133875" cy="709.1802466185509" r="111.60628256720375" fill="#88c7f0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="408.7211086805912" cy="735.5611743497662" r="96.09379752533623" fill="#88c7f0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="504.81490620592746" cy="729.0806384380658" r="128.66518659652496" fill="#88c7f0" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="633.4800928024524" cy="744.4199256580279" r="143.03037306713023" fill="#88c7f0" transform="matrix(1,0,0,1,-1.3677335977554321,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
                    <circle cx="776.5104658695826" cy="779.6216780700386" r="99.91972858571343" fill="#88c7f0" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="876.430194455296" cy="790.4359767536913" r="121.32407610583633" fill="#88c7f0" transform="matrix(1,0,0,1,-2.4294803142547607,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="997.7542705611323" cy="791.1476604980779" r="147.07278245412857" fill="#88c7f0" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1144.8270530152608" cy="777.9434611948411" r="90.86369455529089" fill="#88c7f0" transform="matrix(1,0,0,1,-3.819972276687622,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.81997, 0)" }} />
                    <circle cx="1235.6907475705518" cy="763.1934653033921" r="102.42034300163856" fill="#88c7f0" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="1338.1110905721903" cy="770.4972596116907" r="61.10463080761757" fill="#88c7f0" transform="matrix(1,0,0,1,-5.399243354797363,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.39924, 0)" }} />
                    <circle cx="1399.2157213798077" cy="728.5948136938927" r="125.53304857623169" fill="#88c7f0" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1524.7487699560395" cy="734.1495826989442" r="90.80606351018798" fill="#88c7f0" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1615.5548334662276" cy="706.1785475479933" r="111.3583708284702" fill="#88c7f0" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="1726.9132042946978" cy="734.8801065766854" r="66.59232224864138" fill="#88c7f0" transform="matrix(1,0,0,1,-8.1453218460083,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.14532, 0)" }} />
                    <circle cx="1793.5055265433393" cy="728.5824548778015" r="67.21089609876383" fill="#88c7f0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1860.7164226421032" cy="725.5175841837579" r="64.91354854571935" fill="#88c7f0" transform="matrix(1,0,0,1,-9.033674240112305,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.03367, 0)" }} />
                    <circle cx="1925.6299711878225" cy="694.4923027530441" r="85.5315603707089" fill="#88c7f0" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="0" cy="794.883334933244" r="88.43055422229669" fill="#7fc3f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="88.43055422229669" cy="756.5014745961118" r="132.84689211888292" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.05069185048341751,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0506919, 0)" }} />
                    <circle cx="221.2774463411796" cy="844.5461328153605" r="61.428817138759406" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.21463724970817566,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="282.706263479939" cy="829.7708641148126" r="84.69496450973311" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.5121520161628723,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
                    <circle cx="367.4012279896721" cy="817.0405973674126" r="114.47051617343178" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.9663259983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="481.8717441631039" cy="832.1719304057441" r="117.03476115386347" fill="#7fc3f0" transform="matrix(1,0,0,1,-1.6002674102783203,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="598.9065053169674" cy="859.5170850575392" r="94.39008828266604" fill="#7fc3f0" transform="matrix(1,0,0,1,-2.429480791091919,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="693.2965935996334" cy="857.1089849346341" r="137.3210432797914" fill="#7fc3f0" transform="matrix(1,0,0,1,-3.4464619159698486,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44646, 0)" }} />
                    <circle cx="830.6176368794248" cy="881.6534668915623" r="133.19706296708" fill="#7fc3f0" transform="matrix(1,0,0,1,-4.600757122039795,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="963.8146998465048" cy="897.5205115618868" r="145.63597439069335" fill="#7fc3f0" transform="matrix(1,0,0,1,-5.794002532958984,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.794, 0)" }} />
                    <circle cx="1109.4506742371982" cy="875.069961858283" r="124.17039413988114" fill="#7fc3f0" transform="matrix(1,0,0,1,-6.911351680755615,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1233.6210683770794" cy="863.5931215004102" r="102.064332947242" fill="#7fc3f0" transform="matrix(1,0,0,1,-7.868857383728027,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.86886, 0)" }} />
                    <circle cx="1335.6854013243214" cy="851.6073357605245" r="99.56021637035117" fill="#7fc3f0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1435.2456176946725" cy="820.2914058849113" r="129.64525448387906" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.203954696655273,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.20395, 0)" }} />
                    <circle cx="1564.8908721785515" cy="818.1096422626223" r="105.30265833529326" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.603245735168457,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
                    <circle cx="1670.1935305138447" cy="784.5433239720893" r="126.46228698013573" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.853781700134277,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="1796.6558174939805" cy="755.6044651406806" r="134.44278938718486" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="1931.0986068811653" cy="769.6866217292722" r="104.86708394737643" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.994569778442383,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.99457, 0)" }} />
                    <circle cx="2035.9656908285417" cy="828.3670492348571" r="52.42919113625864" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.908178329467773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
                    <circle cx="0" cy="900.8258843877142" r="83.47842967690484" fill="#75bff0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="83.47842967690484" cy="845.538108287056" r="142.10467148028582" fill="#75bff0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="225.58310115719064" cy="937.8604043990806" r="69.1149062743774" fill="#75bff0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="294.69800743156804" cy="936.6350647347617" r="77.94438138068789" fill="#75bff0" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="372.64238881225594" cy="922.458990387816" r="108.0636368951973" fill="#75bff0" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="480.70602570745325" cy="932.7170307279115" r="115.81636049381083" fill="#75bff0" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="596.5223862012641" cy="976.7442587188742" r="54.81384026555749" fill="#75bff0" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="651.3362264668216" cy="977.9514918991177" r="61.80776032081492" fill="#75bff0" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="713.1439867876365" cy="957.5327818931474" r="147.63660335948992" fill="#75bff0" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="860.7805901471265" cy="989.1933361163743" r="110.39910900109469" fill="#75bff0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="971.1796991482212" cy="998.7549340158664" r="67.53284109551325" fill="#75bff0" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1038.7125402437343" cy="984.4428433689995" r="139.13571025820767" fill="#75bff0" transform="matrix(1,0,0,1,-9.785362243652344,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="1177.848250501942" cy="980.5020448528268" r="69.32471961288908" fill="#75bff0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="1247.172970114831" cy="971.7980528396797" r="76.10387315587192" fill="#75bff0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="1323.276843270703" cy="950.6747743827456" r="104.767489415753" fill="#75bff0" transform="matrix(1,0,0,1,-9.78536319732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="1428.044332686456" cy="925.1169000308054" r="123.7110906433709" fill="#75bff0" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1551.755423329827" cy="933.3487891202255" r="87.80362979773871" fill="#75bff0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1639.5590531275657" cy="900.9378968373367" r="113.45170131433704" fill="#75bff0" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="1753.0107544419027" cy="880.5587376580455" r="117.38395011809072" fill="#75bff0" transform="matrix(1,0,0,1,-6.180026531219482,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1870.3947045599934" cy="887.8741078318214" r="96.26880253739894" fill="#75bff0" transform="matrix(1,0,0,1,-4.600756645202637,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="0" cy="934.5008755581705" r="138.74927036819125" fill="#6bbbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="138.74927036819125" cy="957.9761260524319" r="139.69761092203328" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.14621886610984802,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
                    <circle cx="278.4468812902245" cy="993.1946991716375" r="127.36670494718736" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="405.81358623741187" cy="1031.0853207953453" r="102.112754454359" fill="#6bbbf0" transform="matrix(1,0,0,1,-1.600266933441162,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="507.92634069177086" cy="1063.7034853463056" r="67.19921153855573" fill="#6bbbf0" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="575.1255522303265" cy="1070.6698029486033" r="64.5760482293398" fill="#6bbbf0" transform="matrix(1,0,0,1,-5,0)" style={{ transform: "matrix(1, 0, 0, 1, -5, 0)" }} />
                    <circle cx="639.7016004596663" cy="1070.7925451293092" r="77.91842586297687" fill="#6bbbf0" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="717.6200263226432" cy="1085.6920442413787" r="53.01704879700557" fill="#6bbbf0" transform="matrix(1,0,0,1,-8.39973258972168,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39973, 0)" }} />
                    <circle cx="770.6370751196488" cy="1084.0754121280502" r="76.43891588940916" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="847.0759910090579" cy="1082.7961325380768" r="146.3945948903143" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.853780746459961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="993.4705858993723" cy="1095.397019973017" r="91.03143049290617" fill="#6bbbf0" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
                    <circle cx="1084.5020163922784" cy="1086.3912022028271" r="82.46782779772326" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.853780746459961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="1166.9698441900016" cy="1059.5600613855445" r="147.01975296675778" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1313.9895971567594" cy="1036.7610765847135" r="137.0991284831443" fill="#6bbbf0" transform="matrix(1,0,0,1,-8.399733543395996,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39973, 0)" }} />
                    <circle cx="1451.0887256399037" cy="1055.0258697067416" r="71.53810344256055" fill="#6bbbf0" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1522.6268290824642" cy="1052.554635470059" r="66.04735557025496" fill="#6bbbf0" transform="matrix(1,0,0,1,-5,0)" style={{ transform: "matrix(1, 0, 0, 1, -5, 0)" }} />
                    <circle cx="1588.674184652719" cy="1031.2473490216119" r="85.33853610288433" fill="#6bbbf0" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1674.0127207556034" cy="1026.5365612928745" r="80.4374962361186" fill="#6bbbf0" transform="matrix(1,0,0,1,-1.600266456604004,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="1754.450216991722" cy="984.3282743711577" r="113.50788721037918" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
                    <circle cx="1867.9581042021014" cy="975.1997452602602" r="107.33715881198013" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.14621925354003906,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
                    <circle cx="1975.2952630140815" cy="1006.4371175884163" r="72.27857156332898" fill="#6bbbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="0" cy="1025.8828054395256" r="145.93099546706202" fill="#61b7f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="145.93099546706202" cy="1136.7800718273886" r="63.29014534294441" fill="#61b7f0" transform="matrix(1,0,0,1,-0.2146373838186264,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="209.22114081000643" cy="1092.4505648698646" r="116.17985363032591" fill="#61b7f0" transform="matrix(1,0,0,1,-0.9663266539573669,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966327, 0)" }} />
                    <circle cx="325.40099444033234" cy="1132.3443884771668" r="87.26013694194752" fill="#61b7f0" transform="matrix(1,0,0,1,-2.4294822216033936,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="412.66113138227985" cy="1164.136798138829" r="54.53250396885616" fill="#61b7f0" transform="matrix(1,0,0,1,-4.600759983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="467.193635351136" cy="1123.5224693108294" r="127.69515002706203" fill="#61b7f0" transform="matrix(1,0,0,1,-6.911355018615723,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91136, 0)" }} />
                    <circle cx="594.888785378198" cy="1173.6729116464494" r="61.466417468710645" fill="#61b7f0" transform="matrix(1,0,0,1,-8.632269859313965,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="656.3552028469087" cy="1155.3147171595285" r="124.64163772122245" fill="#61b7f0" transform="matrix(1,0,0,1,-9.603246688842773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
                    <circle cx="780.9968405681311" cy="1174.1538713300074" r="128.90804299658546" fill="#61b7f0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="909.9048835647166" cy="1197.3693754852525" r="81.46765407192824" fill="#61b7f0" transform="matrix(1,0,0,1,-9.908177375793457,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
                    <circle cx="991.3725376366448" cy="1197.4377913994338" r="60.493546739377834" fill="#61b7f0" transform="matrix(1,0,0,1,-9.355046272277832,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1051.8660843760226" cy="1192.2386281998356" r="64.56286232358275" fill="#61b7f0" transform="matrix(1,0,0,1,-8.14531421661377,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.14531, 0)" }} />
                    <circle cx="1116.4289466996054" cy="1169.516111407684" r="144.62879661679546" fill="#61b7f0" transform="matrix(1,0,0,1,-6.180017471313477,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
                    <circle cx="1261.0577433164008" cy="1169.194751253644" r="79.24701709548569" fill="#61b7f0" transform="matrix(1,0,0,1,-3.8199596405029297,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.81996, 0)" }} />
                    <circle cx="1340.3047604118865" cy="1148.0891300670373" r="105.40553579165864" fill="#61b7f0" transform="matrix(1,0,0,1,-1.8546695709228516,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85467, 0)" }} />
                    <circle cx="1445.710296203545" cy="1113.2699874397936" r="137.97875820607766" fill="#61b7f0" transform="matrix(1,0,0,1,-0.6449451446533203,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644945, 0)" }} />
                    <circle cx="1583.6890544096227" cy="1136.41891631353" r="79.62811175792845" fill="#61b7f0" transform="matrix(1,0,0,1,-0.09181976318359375,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918198, 0)" }} />
                    <circle cx="1663.3171661675512" cy="1152.6810430760127" r="52.97267052749832" fill="#61b7f0" transform="matrix(1,0,0,1,-0.022118574008345604,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221186, 0)" }} />
                    <circle cx="1716.2898366950496" cy="1095.6958598653212" r="107.5173124430098" fill="#61b7f0" transform="matrix(1,0,0,1,-0.3967595100402832,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.39676, 0)" }} />
                    <circle cx="1823.8071491380595" cy="1141.4397156205162" r="53.372032754680035" fill="#61b7f0" transform="matrix(1,0,0,1,-1.367743968963623,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36774, 0)" }} />
                    <circle cx="1877.1791818927395" cy="1072.928564122859" r="108.19406581089433" fill="#61b7f0" transform="matrix(1,0,0,1,-3.0886662006378174,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08867, 0)" }} />
                    <circle cx="0" cy="1194.2122286503225" r="88.98980945806456" fill="#56b3f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="88.98980945806456" cy="1150.4084413549135" r="138.539066173881" fill="#56b3f0" transform="matrix(1,0,0,1,-0.297871857881546,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.297872, 0)" }} />
                    <circle cx="227.52887563194554" cy="1237.0909821530684" r="70.1468670237951" fill="#56b3f0" transform="matrix(1,0,0,1,-1.3677345514297485,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
                    <circle cx="297.67574265574063" cy="1193.6739774461448" r="130.56708720898743" fill="#56b3f0" transform="matrix(1,0,0,1,-3.4464633464813232,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44646, 0)" }} />
                    <circle cx="428.24282986472804" cy="1240.3430514839847" r="92.43673763415403" fill="#56b3f0" transform="matrix(1,0,0,1,-6.1800312995910645,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="520.6795674988821" cy="1233.6504658124586" r="124.9945892543112" fill="#56b3f0" transform="matrix(1,0,0,1,-8.399735450744629,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39974, 0)" }} />
                    <circle cx="645.6741567531933" cy="1254.1441853127335" r="123.2735325021624" fill="#56b3f0" transform="matrix(1,0,0,1,-9.603246688842773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
                    <circle cx="768.9476892553557" cy="1268.3914993316007" r="145.86116005947153" fill="#56b3f0" transform="matrix(1,0,0,1,-9.9945707321167,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.99457, 0)" }} />
                    <circle cx="914.8088493148273" cy="1298.3683013438529" r="68.64470696487123" fill="#56b3f0" transform="matrix(1,0,0,1,-9.785361289978027,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="983.4535562796985" cy="1296.2095753157232" r="98.10430565714867" fill="#56b3f0" transform="matrix(1,0,0,1,-8.843363761901855,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.84336, 0)" }} />
                    <circle cx="1081.5578619368473" cy="1286.688480053602" r="82.53147638618248" fill="#56b3f0" transform="matrix(1,0,0,1,-6.911342620849609,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91134, 0)" }} />
                    <circle cx="1164.0893383230298" cy="1272.679690136268" r="101.79520458821271" fill="#56b3f0" transform="matrix(1,0,0,1,-4.205990791320801,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.20599, 0)" }} />
                    <circle cx="1265.8845429112425" cy="1264.315893392702" r="90.02843730381184" fill="#56b3f0" transform="matrix(1,0,0,1,-1.8546714782714844,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85467, 0)" }} />
                    <circle cx="1355.9129802150544" cy="1261.1244925809078" r="76.4199808828153" fill="#56b3f0" transform="matrix(1,0,0,1,-0.5121488571166992,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512149, 0)" }} />
                    <circle cx="1432.3329610978697" cy="1225.9187057556244" r="121.32673949294312" fill="#56b3f0" transform="matrix(1,0,0,1,-0.022116661071777344,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221167, 0)" }} />
                    <circle cx="1553.6597005908127" cy="1191.8701215120216" r="141.19511974566942" fill="#56b3f0" transform="matrix(1,0,0,1,-0.14622078835964203,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146221, 0)" }} />
                    <circle cx="1694.8548203364821" cy="1179.970377192135" r="127.1057081668487" fill="#56b3f0" transform="matrix(1,0,0,1,-0.9663317203521729,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966332, 0)" }} />
                    <circle cx="1821.9605285033308" cy="1218.0444480935678" r="74.49308720633856" fill="#56b3f0" transform="matrix(1,0,0,1,-2.749058246612549,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.74906, 0)" }} />
                    <circle cx="1896.4536157096693" cy="1163.6256851927017" r="113.70209868828613" fill="#56b3f0" transform="matrix(1,0,0,1,-5.399258613586426,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.39926, 0)" }} />
                    <circle cx="0" cy="1252.2444528284998" r="123.96295597625024" fill="#4baef1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="123.96295597625024" cy="1293.0153153650022" r="103.57381529742646" fill="#4baef1" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="227.53677127367672" cy="1274.3026109262994" r="139.06523693578464" fill="#4baef1" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="366.60200820946136" cy="1330.9696559786057" r="95.34766147116842" fill="#4baef1" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="461.94966968062977" cy="1337.7841012008287" r="103.05866113668432" fill="#4baef1" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="565.0083308173141" cy="1366.9095188497747" r="70.6495854594532" fill="#4baef1" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="635.6579162767673" cy="1358.281453298066" r="108.74968941462014" fill="#4baef1" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="744.4076056913874" cy="1367.4698021945794" r="131.63525398248365" fill="#4baef1" transform="matrix(1,0,0,1,-9.78536319732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="876.042859673871" cy="1388.2287144702213" r="145.84983233258887" fill="#4baef1" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1021.8926920064599" cy="1388.8295001127892" r="124.64472775089223" fill="#4baef1" transform="matrix(1,0,0,1,-6.180026531219482,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1146.5374197573522" cy="1363.5213186446802" r="146.44655867307122" fill="#4baef1" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1292.9839784304233" cy="1358.6202493601709" r="95.90686841215681" fill="#4baef1" transform="matrix(1,0,0,1,-0.9663257598876953,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="1388.8908468425802" cy="1348.32267663536" r="93.45403818242164" fill="#4baef1" transform="matrix(1,0,0,1,-0.09182167053222656,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="1482.344885025002" cy="1299.2897041270132" r="149.06042698489668" fill="#4baef1" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="1631.4053120098986" cy="1310.6259690077986" r="103.68443856875678" fill="#4baef1" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="1735.0897505786554" cy="1282.4454704661641" r="118.16593233808992" fill="#4baef1" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1853.2556829167454" cy="1243.7892713535311" r="136.3090274442496" fill="#4baef1" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1989.564710360995" cy="1310.3114901692584" r="68.37029892291464" fill="#4baef1" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="0" cy="1325.4959911277895" r="146.25334072684208" fill="#3daaf1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="146.25334072684208" cy="1408.1527291067723" r="91.53994571313993" fill="#3daaf1" transform="matrix(1,0,0,1,-0.5121520161628723,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
                    <circle cx="237.793286439982" cy="1369.3434948188258" r="146.6042330229885" fill="#3daaf1" transform="matrix(1,0,0,1,-2.429480791091919,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="384.39751946297054" cy="1457.2144597309577" r="61.5072429377044" fill="#3daaf1" transform="matrix(1,0,0,1,-5.794002532958984,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.794, 0)" }} />
                    <circle cx="445.90476240067494" cy="1436.7370583286988" r="101.39761463077129" fill="#3daaf1" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="547.3023770314462" cy="1457.9230708805956" r="85.29867849930145" fill="#3daaf1" transform="matrix(1,0,0,1,-9.853781700134277,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="632.6010555307477" cy="1460.4995527735268" r="102.09414197936515" fill="#3daaf1" transform="matrix(1,0,0,1,-9.908178329467773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
                    <circle cx="734.6951975101128" cy="1487.2270601878377" r="51.573668188577756" fill="#3daaf1" transform="matrix(1,0,0,1,-8.843366622924805,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.84337, 0)" }} />
                    <circle cx="786.2688656986907" cy="1488.0919288570558" r="64.03977436318718" fill="#3daaf1" transform="matrix(1,0,0,1,-6.180024147033691,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
                    <circle cx="850.3086400618779" cy="1484.3565297574755" r="138.4152981280753" fill="#3daaf1" transform="matrix(1,0,0,1,-2.749044418334961,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.74904, 0)" }} />
                    <circle cx="988.7239381899532" cy="1493.9055909436809" r="127.77547867668926" fill="#3daaf1" transform="matrix(1,0,0,1,-0.6449489593505859,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644949, 0)" }} />
                    <circle cx="1116.4994168666424" cy="1472.3713373211772" r="131.45859193855995" fill="#3daaf1" transform="matrix(1,0,0,1,-0.005429267883300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542927, 0)" }} />
                    <circle cx="1247.9580088052023" cy="1474.0614125854206" r="70.02335958255395" fill="#3daaf1" transform="matrix(1,0,0,1,-0.3967560827732086,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396756, 0)" }} />
                    <circle cx="1317.9813683877562" cy="1439.2740310079241" r="130.32608248336152" fill="#3daaf1" transform="matrix(1,0,0,1,-2.1311471462249756,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.13115, 0)" }} />
                    <circle cx="1448.3074508711177" cy="1423.5260886389622" r="121.21955494841342" fill="#3daaf1" transform="matrix(1,0,0,1,-5.399249076843262,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.39925, 0)" }} />
                    <circle cx="1569.527005819531" cy="1442.0817752890243" r="74.29318752838836" fill="#3daaf1" transform="matrix(1,0,0,1,-8.399736404418945,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39974, 0)" }} />
                    <circle cx="1643.8201933479195" cy="1391.9973547864647" r="122.83500666232516" fill="#3daaf1" transform="matrix(1,0,0,1,-9.785364151000977,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="1766.6552000102447" cy="1446.1393499540186" r="52.580160036316585" fill="#3daaf1" transform="matrix(1,0,0,1,-9.949307441711426,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.94931, 0)" }} />
                    <circle cx="1819.2353600465613" cy="1343.159011282458" r="142.18113827887728" fill="#3daaf1" transform="matrix(1,0,0,1,-9.033671379089355,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.03367, 0)" }} />
                    <circle cx="1961.4164983254386" cy="1419.608752791719" r="63.06037660950972" fill="#3daaf1" transform="matrix(1,0,0,1,-6.553533554077148,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.55353, 0)" }} />
                    <circle cx="2024.4768749349482" cy="1426.0012481362146" r="54.713091557695726" fill="#3daaf1" transform="matrix(1,0,0,1,-3.0886430740356445,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08864, 0)" }} />
                    <circle cx="0" cy="1439.3218134314006" r="134.73182214049945" fill="#2da6f1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="134.73182214049945" cy="1486.029162824792" r="111.7402074392904" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="246.47202957978985" cy="1510.129026231991" r="102.44527760178097" fill="#2da6f1" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="348.91730718157083" cy="1560.3757784616826" r="53.6696918847968" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="402.58699906636764" cy="1528.1442424353202" r="105.77532061995227" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="508.3623196863199" cy="1545.81211981539" r="99.53436820697314" fill="#2da6f1" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
                    <circle cx="607.896687893293" cy="1546.9090690443986" r="126.36069299150815" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="734.2573808848011" cy="1561.1450462634648" r="148.92942015855365" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="883.1868010433548" cy="1594.3002266441076" r="85.35509578570316" fill="#2da6f1" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="968.5418968290579" cy="1597.1197758303801" r="129.7973802095636" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
                    <circle cx="1098.3392770386215" cy="1588.6180078617977" r="63.58983162792961" fill="#2da6f1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="1161.9291086665512" cy="1577.825600718904" r="84.12812621617967" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="1246.0572348827309" cy="1577.6124054536715" r="61.176841381698964" fill="#2da6f1" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1307.2340762644299" cy="1534.5430542609336" r="144.4779721146682" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1451.7120483790982" cy="1511.2227213298884" r="139.52620068709874" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1591.2382490661969" cy="1533.6853326385096" r="82.02903592254505" fill="#2da6f1" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
                    <circle cx="1673.267284988742" cy="1505.0965878241884" r="103.70359707922219" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1776.970882067964" cy="1527.8035572618508" r="69.2839135343485" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1846.2547956023127" cy="1536.789358571094" r="56.10608717090943" fill="#2da6f1" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1902.360882773222" cy="1444.1138293505983" r="129.05045694839583" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
                </g>

            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1890"
                height="1501"
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 1890 1501"
                style={{
                    zIndex: 10,
                    marginRight: "-2px",
                    // display: "block",
                    position: "absolute",
                    bottom: 0,
                    backgroundRepeatY: "initial",
                    backgroundRepeatX: "initial",
                    height: "85%",
                    // backgroundColor: "rgb(221, 221, 221)",
                }}
            >
                <g transform="translate(945,750.5) scale(1,1) translate(-945,-750.5)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}>
                    <defs>
                        <linearGradient id="lg-0.9726608966325525" x1="0" x2="0" y1="0" y2="1">
                            <stop stopColor="##2fb3ff" offset="0" />

                            <stop stopColor="#e9f6ff" offset="1" />
                        </linearGradient>
                    </defs>

                    <rect x="0" y="0" width="1890" height="1501" opacity="0.1" fill="url(#lg-0.9726608966325525)" />
                    <circle cx="0" cy="541.117824742169" r="133.23514604819258" fill="#91cbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="133.23514604819258" cy="634.057020073517" r="64.9419550938517" fill="#91cbf0" transform="matrix(1,0,0,1,-0.005429744720458984,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542974, 0)" }} />
                    <circle cx="198.17710114204428" cy="561.1388364976777" r="147.47896245081165" fill="#91cbf0" transform="matrix(1,0,0,1,-0.022117653861641884,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221177, 0)" }} />
                    <circle cx="345.65606359285596" cy="635.160364065629" r="86.50911463146176" fill="#91cbf0" transform="matrix(1,0,0,1,-0.05069183558225632,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0506918, 0)" }} />
                    <circle cx="432.16517822431774" cy="649.4360037475259" r="79.18075240722446" fill="#91cbf0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="511.3459306315422" cy="625.8174421798897" r="136.52878749543527" fill="#91cbf0" transform="matrix(1,0,0,1,-0.14621886610984802,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
                    <circle cx="647.8747181269775" cy="658.9488012338261" r="111.45237732585112" fill="#91cbf0" transform="matrix(1,0,0,1,-0.21463720500469208,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="759.3270954528286" cy="684.9434014785651" r="68.10132779722626" fill="#91cbf0" transform="matrix(1,0,0,1,-0.2978716492652893,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.297872, 0)" }} />
                    <circle cx="827.428423250055" cy="686.4864362515492" r="97.21253867517193" fill="#91cbf0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="924.6409619252269" cy="697.6530868545327" r="129.46064015280794" fill="#91cbf0" transform="matrix(1,0,0,1,-0.5121519565582275,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
                    <circle cx="1054.1016020780348" cy="688.2981485127052" r="91.68250379210927" fill="#91cbf0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="1145.784105870144" cy="681.1302808770777" r="77.93148636686004" fill="#91cbf0" transform="matrix(1,0,0,1,-0.7960466146469116,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.796047, 0)" }} />
                    <circle cx="1223.715592237004" cy="665.4787568776474" r="100.36388253107138" fill="#91cbf0" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="1324.0794747680754" cy="639.1748399387056" r="128.43563629515066" fill="#91cbf0" transform="matrix(1,0,0,1,-1.156632423400879,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.15663, 0)" }} />
                    <circle cx="1452.5151110632262" cy="643.0367399784491" r="89.94031167139924" fill="#91cbf0" transform="matrix(1,0,0,1,-1.3677335977554321,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
                    <circle cx="1542.4554227346255" cy="606.6629772360415" r="124.34468346873686" fill="#91cbf0" transform="matrix(1,0,0,1,-1.600266933441162,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="1666.8001062033622" cy="567.8492957468043" r="145.27038538540907" fill="#91cbf0" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="1812.0704915887713" cy="562.8994976866354" r="125.42710959117012" fill="#91cbf0" transform="matrix(1,0,0,1,-2.131143569946289,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.13114, 0)" }} />
                    <circle cx="1937.4976011799415" cy="571.6579534193673" r="102.6268089324897" fill="#91cbf0" transform="matrix(1,0,0,1,-2.4294803142547607,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="0" cy="707.4013510333857" r="77.99887413884528" fill="#88c7f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="77.99887413884528" cy="682.7804016601718" r="107.37925351612958" fill="#88c7f0" transform="matrix(1,0,0,1,-0.022117653861641884,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221177, 0)" }} />
                    <circle cx="185.37812765497486" cy="750.1847251824429" r="52.680195733819346" fill="#88c7f0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="238.0583233887942" cy="747.98475803058" r="59.056502724593265" fill="#88c7f0" transform="matrix(1,0,0,1,-0.21463720500469208,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="297.1148261133875" cy="709.1802466185509" r="111.60628256720375" fill="#88c7f0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="408.7211086805912" cy="735.5611743497662" r="96.09379752533623" fill="#88c7f0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="504.81490620592746" cy="729.0806384380658" r="128.66518659652496" fill="#88c7f0" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="633.4800928024524" cy="744.4199256580279" r="143.03037306713023" fill="#88c7f0" transform="matrix(1,0,0,1,-1.3677335977554321,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
                    <circle cx="776.5104658695826" cy="779.6216780700386" r="99.91972858571343" fill="#88c7f0" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="876.430194455296" cy="790.4359767536913" r="121.32407610583633" fill="#88c7f0" transform="matrix(1,0,0,1,-2.4294803142547607,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="997.7542705611323" cy="791.1476604980779" r="147.07278245412857" fill="#88c7f0" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1144.8270530152608" cy="777.9434611948411" r="90.86369455529089" fill="#88c7f0" transform="matrix(1,0,0,1,-3.819972276687622,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.81997, 0)" }} />
                    <circle cx="1235.6907475705518" cy="763.1934653033921" r="102.42034300163856" fill="#88c7f0" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="1338.1110905721903" cy="770.4972596116907" r="61.10463080761757" fill="#88c7f0" transform="matrix(1,0,0,1,-5.399243354797363,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.39924, 0)" }} />
                    <circle cx="1399.2157213798077" cy="728.5948136938927" r="125.53304857623169" fill="#88c7f0" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1524.7487699560395" cy="734.1495826989442" r="90.80606351018798" fill="#88c7f0" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1615.5548334662276" cy="706.1785475479933" r="111.3583708284702" fill="#88c7f0" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="1726.9132042946978" cy="734.8801065766854" r="66.59232224864138" fill="#88c7f0" transform="matrix(1,0,0,1,-8.1453218460083,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.14532, 0)" }} />
                    <circle cx="1793.5055265433393" cy="728.5824548778015" r="67.21089609876383" fill="#88c7f0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1860.7164226421032" cy="725.5175841837579" r="64.91354854571935" fill="#88c7f0" transform="matrix(1,0,0,1,-9.033674240112305,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.03367, 0)" }} />
                    <circle cx="1925.6299711878225" cy="694.4923027530441" r="85.5315603707089" fill="#88c7f0" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="0" cy="794.883334933244" r="88.43055422229669" fill="#7fc3f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="88.43055422229669" cy="756.5014745961118" r="132.84689211888292" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.05069185048341751,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0506919, 0)" }} />
                    <circle cx="221.2774463411796" cy="844.5461328153605" r="61.428817138759406" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.21463724970817566,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="282.706263479939" cy="829.7708641148126" r="84.69496450973311" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.5121520161628723,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
                    <circle cx="367.4012279896721" cy="817.0405973674126" r="114.47051617343178" fill="#7fc3f0" transform="matrix(1,0,0,1,-0.9663259983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="481.8717441631039" cy="832.1719304057441" r="117.03476115386347" fill="#7fc3f0" transform="matrix(1,0,0,1,-1.6002674102783203,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="598.9065053169674" cy="859.5170850575392" r="94.39008828266604" fill="#7fc3f0" transform="matrix(1,0,0,1,-2.429480791091919,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="693.2965935996334" cy="857.1089849346341" r="137.3210432797914" fill="#7fc3f0" transform="matrix(1,0,0,1,-3.4464619159698486,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44646, 0)" }} />
                    <circle cx="830.6176368794248" cy="881.6534668915623" r="133.19706296708" fill="#7fc3f0" transform="matrix(1,0,0,1,-4.600757122039795,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="963.8146998465048" cy="897.5205115618868" r="145.63597439069335" fill="#7fc3f0" transform="matrix(1,0,0,1,-5.794002532958984,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.794, 0)" }} />
                    <circle cx="1109.4506742371982" cy="875.069961858283" r="124.17039413988114" fill="#7fc3f0" transform="matrix(1,0,0,1,-6.911351680755615,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1233.6210683770794" cy="863.5931215004102" r="102.064332947242" fill="#7fc3f0" transform="matrix(1,0,0,1,-7.868857383728027,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.86886, 0)" }} />
                    <circle cx="1335.6854013243214" cy="851.6073357605245" r="99.56021637035117" fill="#7fc3f0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1435.2456176946725" cy="820.2914058849113" r="129.64525448387906" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.203954696655273,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.20395, 0)" }} />
                    <circle cx="1564.8908721785515" cy="818.1096422626223" r="105.30265833529326" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.603245735168457,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
                    <circle cx="1670.1935305138447" cy="784.5433239720893" r="126.46228698013573" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.853781700134277,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="1796.6558174939805" cy="755.6044651406806" r="134.44278938718486" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="1931.0986068811653" cy="769.6866217292722" r="104.86708394737643" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.994569778442383,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.99457, 0)" }} />
                    <circle cx="2035.9656908285417" cy="828.3670492348571" r="52.42919113625864" fill="#7fc3f0" transform="matrix(1,0,0,1,-9.908178329467773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
                    <circle cx="0" cy="900.8258843877142" r="83.47842967690484" fill="#75bff0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="83.47842967690484" cy="845.538108287056" r="142.10467148028582" fill="#75bff0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="225.58310115719064" cy="937.8604043990806" r="69.1149062743774" fill="#75bff0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="294.69800743156804" cy="936.6350647347617" r="77.94438138068789" fill="#75bff0" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="372.64238881225594" cy="922.458990387816" r="108.0636368951973" fill="#75bff0" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="480.70602570745325" cy="932.7170307279115" r="115.81636049381083" fill="#75bff0" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="596.5223862012641" cy="976.7442587188742" r="54.81384026555749" fill="#75bff0" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="651.3362264668216" cy="977.9514918991177" r="61.80776032081492" fill="#75bff0" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="713.1439867876365" cy="957.5327818931474" r="147.63660335948992" fill="#75bff0" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="860.7805901471265" cy="989.1933361163743" r="110.39910900109469" fill="#75bff0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="971.1796991482212" cy="998.7549340158664" r="67.53284109551325" fill="#75bff0" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1038.7125402437343" cy="984.4428433689995" r="139.13571025820767" fill="#75bff0" transform="matrix(1,0,0,1,-9.785362243652344,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="1177.848250501942" cy="980.5020448528268" r="69.32471961288908" fill="#75bff0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="1247.172970114831" cy="971.7980528396797" r="76.10387315587192" fill="#75bff0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="1323.276843270703" cy="950.6747743827456" r="104.767489415753" fill="#75bff0" transform="matrix(1,0,0,1,-9.78536319732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="1428.044332686456" cy="925.1169000308054" r="123.7110906433709" fill="#75bff0" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1551.755423329827" cy="933.3487891202255" r="87.80362979773871" fill="#75bff0" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1639.5590531275657" cy="900.9378968373367" r="113.45170131433704" fill="#75bff0" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="1753.0107544419027" cy="880.5587376580455" r="117.38395011809072" fill="#75bff0" transform="matrix(1,0,0,1,-6.180026531219482,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1870.3947045599934" cy="887.8741078318214" r="96.26880253739894" fill="#75bff0" transform="matrix(1,0,0,1,-4.600756645202637,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="0" cy="934.5008755581705" r="138.74927036819125" fill="#6bbbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="138.74927036819125" cy="957.9761260524319" r="139.69761092203328" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.14621886610984802,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
                    <circle cx="278.4468812902245" cy="993.1946991716375" r="127.36670494718736" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="405.81358623741187" cy="1031.0853207953453" r="102.112754454359" fill="#6bbbf0" transform="matrix(1,0,0,1,-1.600266933441162,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="507.92634069177086" cy="1063.7034853463056" r="67.19921153855573" fill="#6bbbf0" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="575.1255522303265" cy="1070.6698029486033" r="64.5760482293398" fill="#6bbbf0" transform="matrix(1,0,0,1,-5,0)" style={{ transform: "matrix(1, 0, 0, 1, -5, 0)" }} />
                    <circle cx="639.7016004596663" cy="1070.7925451293092" r="77.91842586297687" fill="#6bbbf0" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="717.6200263226432" cy="1085.6920442413787" r="53.01704879700557" fill="#6bbbf0" transform="matrix(1,0,0,1,-8.39973258972168,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39973, 0)" }} />
                    <circle cx="770.6370751196488" cy="1084.0754121280502" r="76.43891588940916" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="847.0759910090579" cy="1082.7961325380768" r="146.3945948903143" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.853780746459961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="993.4705858993723" cy="1095.397019973017" r="91.03143049290617" fill="#6bbbf0" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
                    <circle cx="1084.5020163922784" cy="1086.3912022028271" r="82.46782779772326" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.853780746459961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="1166.9698441900016" cy="1059.5600613855445" r="147.01975296675778" fill="#6bbbf0" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1313.9895971567594" cy="1036.7610765847135" r="137.0991284831443" fill="#6bbbf0" transform="matrix(1,0,0,1,-8.399733543395996,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39973, 0)" }} />
                    <circle cx="1451.0887256399037" cy="1055.0258697067416" r="71.53810344256055" fill="#6bbbf0" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1522.6268290824642" cy="1052.554635470059" r="66.04735557025496" fill="#6bbbf0" transform="matrix(1,0,0,1,-5,0)" style={{ transform: "matrix(1, 0, 0, 1, -5, 0)" }} />
                    <circle cx="1588.674184652719" cy="1031.2473490216119" r="85.33853610288433" fill="#6bbbf0" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1674.0127207556034" cy="1026.5365612928745" r="80.4374962361186" fill="#6bbbf0" transform="matrix(1,0,0,1,-1.600266456604004,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
                    <circle cx="1754.450216991722" cy="984.3282743711577" r="113.50788721037918" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
                    <circle cx="1867.9581042021014" cy="975.1997452602602" r="107.33715881198013" fill="#6bbbf0" transform="matrix(1,0,0,1,-0.14621925354003906,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
                    <circle cx="1975.2952630140815" cy="1006.4371175884163" r="72.27857156332898" fill="#6bbbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="0" cy="1025.8828054395256" r="145.93099546706202" fill="#61b7f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="145.93099546706202" cy="1136.7800718273886" r="63.29014534294441" fill="#61b7f0" transform="matrix(1,0,0,1,-0.2146373838186264,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
                    <circle cx="209.22114081000643" cy="1092.4505648698646" r="116.17985363032591" fill="#61b7f0" transform="matrix(1,0,0,1,-0.9663266539573669,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966327, 0)" }} />
                    <circle cx="325.40099444033234" cy="1132.3443884771668" r="87.26013694194752" fill="#61b7f0" transform="matrix(1,0,0,1,-2.4294822216033936,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="412.66113138227985" cy="1164.136798138829" r="54.53250396885616" fill="#61b7f0" transform="matrix(1,0,0,1,-4.600759983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="467.193635351136" cy="1123.5224693108294" r="127.69515002706203" fill="#61b7f0" transform="matrix(1,0,0,1,-6.911355018615723,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91136, 0)" }} />
                    <circle cx="594.888785378198" cy="1173.6729116464494" r="61.466417468710645" fill="#61b7f0" transform="matrix(1,0,0,1,-8.632269859313965,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="656.3552028469087" cy="1155.3147171595285" r="124.64163772122245" fill="#61b7f0" transform="matrix(1,0,0,1,-9.603246688842773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
                    <circle cx="780.9968405681311" cy="1174.1538713300074" r="128.90804299658546" fill="#61b7f0" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="909.9048835647166" cy="1197.3693754852525" r="81.46765407192824" fill="#61b7f0" transform="matrix(1,0,0,1,-9.908177375793457,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
                    <circle cx="991.3725376366448" cy="1197.4377913994338" r="60.493546739377834" fill="#61b7f0" transform="matrix(1,0,0,1,-9.355046272277832,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1051.8660843760226" cy="1192.2386281998356" r="64.56286232358275" fill="#61b7f0" transform="matrix(1,0,0,1,-8.14531421661377,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.14531, 0)" }} />
                    <circle cx="1116.4289466996054" cy="1169.516111407684" r="144.62879661679546" fill="#61b7f0" transform="matrix(1,0,0,1,-6.180017471313477,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
                    <circle cx="1261.0577433164008" cy="1169.194751253644" r="79.24701709548569" fill="#61b7f0" transform="matrix(1,0,0,1,-3.8199596405029297,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.81996, 0)" }} />
                    <circle cx="1340.3047604118865" cy="1148.0891300670373" r="105.40553579165864" fill="#61b7f0" transform="matrix(1,0,0,1,-1.8546695709228516,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85467, 0)" }} />
                    <circle cx="1445.710296203545" cy="1113.2699874397936" r="137.97875820607766" fill="#61b7f0" transform="matrix(1,0,0,1,-0.6449451446533203,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644945, 0)" }} />
                    <circle cx="1583.6890544096227" cy="1136.41891631353" r="79.62811175792845" fill="#61b7f0" transform="matrix(1,0,0,1,-0.09181976318359375,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918198, 0)" }} />
                    <circle cx="1663.3171661675512" cy="1152.6810430760127" r="52.97267052749832" fill="#61b7f0" transform="matrix(1,0,0,1,-0.022118574008345604,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221186, 0)" }} />
                    <circle cx="1716.2898366950496" cy="1095.6958598653212" r="107.5173124430098" fill="#61b7f0" transform="matrix(1,0,0,1,-0.3967595100402832,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.39676, 0)" }} />
                    <circle cx="1823.8071491380595" cy="1141.4397156205162" r="53.372032754680035" fill="#61b7f0" transform="matrix(1,0,0,1,-1.367743968963623,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36774, 0)" }} />
                    <circle cx="1877.1791818927395" cy="1072.928564122859" r="108.19406581089433" fill="#61b7f0" transform="matrix(1,0,0,1,-3.0886662006378174,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08867, 0)" }} />
                    <circle cx="0" cy="1194.2122286503225" r="88.98980945806456" fill="#56b3f0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="88.98980945806456" cy="1150.4084413549135" r="138.539066173881" fill="#56b3f0" transform="matrix(1,0,0,1,-0.297871857881546,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.297872, 0)" }} />
                    <circle cx="227.52887563194554" cy="1237.0909821530684" r="70.1468670237951" fill="#56b3f0" transform="matrix(1,0,0,1,-1.3677345514297485,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
                    <circle cx="297.67574265574063" cy="1193.6739774461448" r="130.56708720898743" fill="#56b3f0" transform="matrix(1,0,0,1,-3.4464633464813232,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44646, 0)" }} />
                    <circle cx="428.24282986472804" cy="1240.3430514839847" r="92.43673763415403" fill="#56b3f0" transform="matrix(1,0,0,1,-6.1800312995910645,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="520.6795674988821" cy="1233.6504658124586" r="124.9945892543112" fill="#56b3f0" transform="matrix(1,0,0,1,-8.399735450744629,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39974, 0)" }} />
                    <circle cx="645.6741567531933" cy="1254.1441853127335" r="123.2735325021624" fill="#56b3f0" transform="matrix(1,0,0,1,-9.603246688842773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
                    <circle cx="768.9476892553557" cy="1268.3914993316007" r="145.86116005947153" fill="#56b3f0" transform="matrix(1,0,0,1,-9.9945707321167,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.99457, 0)" }} />
                    <circle cx="914.8088493148273" cy="1298.3683013438529" r="68.64470696487123" fill="#56b3f0" transform="matrix(1,0,0,1,-9.785361289978027,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="983.4535562796985" cy="1296.2095753157232" r="98.10430565714867" fill="#56b3f0" transform="matrix(1,0,0,1,-8.843363761901855,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.84336, 0)" }} />
                    <circle cx="1081.5578619368473" cy="1286.688480053602" r="82.53147638618248" fill="#56b3f0" transform="matrix(1,0,0,1,-6.911342620849609,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91134, 0)" }} />
                    <circle cx="1164.0893383230298" cy="1272.679690136268" r="101.79520458821271" fill="#56b3f0" transform="matrix(1,0,0,1,-4.205990791320801,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.20599, 0)" }} />
                    <circle cx="1265.8845429112425" cy="1264.315893392702" r="90.02843730381184" fill="#56b3f0" transform="matrix(1,0,0,1,-1.8546714782714844,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85467, 0)" }} />
                    <circle cx="1355.9129802150544" cy="1261.1244925809078" r="76.4199808828153" fill="#56b3f0" transform="matrix(1,0,0,1,-0.5121488571166992,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512149, 0)" }} />
                    <circle cx="1432.3329610978697" cy="1225.9187057556244" r="121.32673949294312" fill="#56b3f0" transform="matrix(1,0,0,1,-0.022116661071777344,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221167, 0)" }} />
                    <circle cx="1553.6597005908127" cy="1191.8701215120216" r="141.19511974566942" fill="#56b3f0" transform="matrix(1,0,0,1,-0.14622078835964203,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146221, 0)" }} />
                    <circle cx="1694.8548203364821" cy="1179.970377192135" r="127.1057081668487" fill="#56b3f0" transform="matrix(1,0,0,1,-0.9663317203521729,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966332, 0)" }} />
                    <circle cx="1821.9605285033308" cy="1218.0444480935678" r="74.49308720633856" fill="#56b3f0" transform="matrix(1,0,0,1,-2.749058246612549,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.74906, 0)" }} />
                    <circle cx="1896.4536157096693" cy="1163.6256851927017" r="113.70209868828613" fill="#56b3f0" transform="matrix(1,0,0,1,-5.399258613586426,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.39926, 0)" }} />
                    <circle cx="0" cy="1252.2444528284998" r="123.96295597625024" fill="#4baef1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="123.96295597625024" cy="1293.0153153650022" r="103.57381529742646" fill="#4baef1" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
                    <circle cx="227.53677127367672" cy="1274.3026109262994" r="139.06523693578464" fill="#4baef1" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
                    <circle cx="366.60200820946136" cy="1330.9696559786057" r="95.34766147116842" fill="#4baef1" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
                    <circle cx="461.94966968062977" cy="1337.7841012008287" r="103.05866113668432" fill="#4baef1" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
                    <circle cx="565.0083308173141" cy="1366.9095188497747" r="70.6495854594532" fill="#4baef1" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="635.6579162767673" cy="1358.281453298066" r="108.74968941462014" fill="#4baef1" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
                    <circle cx="744.4076056913874" cy="1367.4698021945794" r="131.63525398248365" fill="#4baef1" transform="matrix(1,0,0,1,-9.78536319732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="876.042859673871" cy="1388.2287144702213" r="145.84983233258887" fill="#4baef1" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="1021.8926920064599" cy="1388.8295001127892" r="124.64472775089223" fill="#4baef1" transform="matrix(1,0,0,1,-6.180026531219482,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1146.5374197573522" cy="1363.5213186446802" r="146.44655867307122" fill="#4baef1" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1292.9839784304233" cy="1358.6202493601709" r="95.90686841215681" fill="#4baef1" transform="matrix(1,0,0,1,-0.9663257598876953,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="1388.8908468425802" cy="1348.32267663536" r="93.45403818242164" fill="#4baef1" transform="matrix(1,0,0,1,-0.09182167053222656,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="1482.344885025002" cy="1299.2897041270132" r="149.06042698489668" fill="#4baef1" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
                    <circle cx="1631.4053120098986" cy="1310.6259690077986" r="103.68443856875678" fill="#4baef1" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
                    <circle cx="1735.0897505786554" cy="1282.4454704661641" r="118.16593233808992" fill="#4baef1" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1853.2556829167454" cy="1243.7892713535311" r="136.3090274442496" fill="#4baef1" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
                    <circle cx="1989.564710360995" cy="1310.3114901692584" r="68.37029892291464" fill="#4baef1" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="0" cy="1325.4959911277895" r="146.25334072684208" fill="#3daaf1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="146.25334072684208" cy="1408.1527291067723" r="91.53994571313993" fill="#3daaf1" transform="matrix(1,0,0,1,-0.5121520161628723,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
                    <circle cx="237.793286439982" cy="1369.3434948188258" r="146.6042330229885" fill="#3daaf1" transform="matrix(1,0,0,1,-2.429480791091919,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
                    <circle cx="384.39751946297054" cy="1457.2144597309577" r="61.5072429377044" fill="#3daaf1" transform="matrix(1,0,0,1,-5.794002532958984,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.794, 0)" }} />
                    <circle cx="445.90476240067494" cy="1436.7370583286988" r="101.39761463077129" fill="#3daaf1" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
                    <circle cx="547.3023770314462" cy="1457.9230708805956" r="85.29867849930145" fill="#3daaf1" transform="matrix(1,0,0,1,-9.853781700134277,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
                    <circle cx="632.6010555307477" cy="1460.4995527735268" r="102.09414197936515" fill="#3daaf1" transform="matrix(1,0,0,1,-9.908178329467773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
                    <circle cx="734.6951975101128" cy="1487.2270601878377" r="51.573668188577756" fill="#3daaf1" transform="matrix(1,0,0,1,-8.843366622924805,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.84337, 0)" }} />
                    <circle cx="786.2688656986907" cy="1488.0919288570558" r="64.03977436318718" fill="#3daaf1" transform="matrix(1,0,0,1,-6.180024147033691,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
                    <circle cx="850.3086400618779" cy="1484.3565297574755" r="138.4152981280753" fill="#3daaf1" transform="matrix(1,0,0,1,-2.749044418334961,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.74904, 0)" }} />
                    <circle cx="988.7239381899532" cy="1493.9055909436809" r="127.77547867668926" fill="#3daaf1" transform="matrix(1,0,0,1,-0.6449489593505859,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644949, 0)" }} />
                    <circle cx="1116.4994168666424" cy="1472.3713373211772" r="131.45859193855995" fill="#3daaf1" transform="matrix(1,0,0,1,-0.005429267883300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542927, 0)" }} />
                    <circle cx="1247.9580088052023" cy="1474.0614125854206" r="70.02335958255395" fill="#3daaf1" transform="matrix(1,0,0,1,-0.3967560827732086,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396756, 0)" }} />
                    <circle cx="1317.9813683877562" cy="1439.2740310079241" r="130.32608248336152" fill="#3daaf1" transform="matrix(1,0,0,1,-2.1311471462249756,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.13115, 0)" }} />
                    <circle cx="1448.3074508711177" cy="1423.5260886389622" r="121.21955494841342" fill="#3daaf1" transform="matrix(1,0,0,1,-5.399249076843262,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.39925, 0)" }} />
                    <circle cx="1569.527005819531" cy="1442.0817752890243" r="74.29318752838836" fill="#3daaf1" transform="matrix(1,0,0,1,-8.399736404418945,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39974, 0)" }} />
                    <circle cx="1643.8201933479195" cy="1391.9973547864647" r="122.83500666232516" fill="#3daaf1" transform="matrix(1,0,0,1,-9.785364151000977,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
                    <circle cx="1766.6552000102447" cy="1446.1393499540186" r="52.580160036316585" fill="#3daaf1" transform="matrix(1,0,0,1,-9.949307441711426,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.94931, 0)" }} />
                    <circle cx="1819.2353600465613" cy="1343.159011282458" r="142.18113827887728" fill="#3daaf1" transform="matrix(1,0,0,1,-9.033671379089355,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.03367, 0)" }} />
                    <circle cx="1961.4164983254386" cy="1419.608752791719" r="63.06037660950972" fill="#3daaf1" transform="matrix(1,0,0,1,-6.553533554077148,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.55353, 0)" }} />
                    <circle cx="2024.4768749349482" cy="1426.0012481362146" r="54.713091557695726" fill="#3daaf1" transform="matrix(1,0,0,1,-3.0886430740356445,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08864, 0)" }} />
                    <circle cx="0" cy="1439.3218134314006" r="134.73182214049945" fill="#2da6f1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="134.73182214049945" cy="1486.029162824792" r="111.7402074392904" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="246.47202957978985" cy="1510.129026231991" r="102.44527760178097" fill="#2da6f1" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="348.91730718157083" cy="1560.3757784616826" r="53.6696918847968" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="402.58699906636764" cy="1528.1442424353202" r="105.77532061995227" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="508.3623196863199" cy="1545.81211981539" r="99.53436820697314" fill="#2da6f1" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
                    <circle cx="607.896687893293" cy="1546.9090690443986" r="126.36069299150815" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="734.2573808848011" cy="1561.1450462634648" r="148.92942015855365" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="883.1868010433548" cy="1594.3002266441076" r="85.35509578570316" fill="#2da6f1" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="968.5418968290579" cy="1597.1197758303801" r="129.7973802095636" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
                    <circle cx="1098.3392770386215" cy="1588.6180078617977" r="63.58983162792961" fill="#2da6f1" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                    <circle cx="1161.9291086665512" cy="1577.825600718904" r="84.12812621617967" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
                    <circle cx="1246.0572348827309" cy="1577.6124054536715" r="61.176841381698964" fill="#2da6f1" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1307.2340762644299" cy="1534.5430542609336" r="144.4779721146682" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1451.7120483790982" cy="1511.2227213298884" r="139.52620068709874" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1591.2382490661969" cy="1533.6853326385096" r="82.02903592254505" fill="#2da6f1" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
                    <circle cx="1673.267284988742" cy="1505.0965878241884" r="103.70359707922219" fill="#2da6f1" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
                    <circle cx="1776.970882067964" cy="1527.8035572618508" r="69.2839135343485" fill="#2da6f1" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
                    <circle cx="1846.2547956023127" cy="1536.789358571094" r="56.10608717090943" fill="#2da6f1" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
                    <circle cx="1902.360882773222" cy="1444.1138293505983" r="129.05045694839583" fill="#2da6f1" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
                </g>

            </svg>
        </>
    );
};

export default SeaOfClouds;

// const SeaOfClouds = ({ className, style }) => {
//     return (
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             // width="1890"
//             // height="2000"
//             preserveAspectRatio="xMidYMid"
//             viewBox="0 0 1890 2000"
// style={{
//     zIndex: 1,
//     marginRight: "-2px",
//     // display: "block",
//     position: "absolute",
//     bottom: 0,
//     backgroundRepeatY: "initial",
//     backgroundRepeatX: "initial",
//     height: "75%",
//     backgroundColor: "rgb(153, 204, 255)",
//             }}
//         >
//             <g transform="translate(945,1000) scale(1,1) translate(-945,-1000)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}>
//                 <defs>
//                     <linearGradient id="lg-0.3561950333557633" x1="0" x2="0" y1="0" y2="1">
//                         <stop stopColor="#3184ce" offset="0" />

//                         <stop stopColor="#e9f6ff" offset="1" />
//                     </linearGradient>
//                 </defs>

//                 <rect x="0" y="0" width="1890" height="2000" fill="url(#lg-0.3561950333557633)" />
//                 <circle cx="0" cy="-38.40829527727726" r="240.34024606439772" fill="#91cbf0" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="240.34024606439772" cy="108.59484381525368" r="158.02883572326797" fill="#91cbf0" transform="matrix(1,0,0,1,-0.005429744720458984,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542974, 0)" }} />
//                 <circle cx="398.36908178766566" cy="103.32491906339499" r="211.3064270409762" fill="#91cbf0" transform="matrix(1,0,0,1,-0.022117653861641884,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221177, 0)" }} />
//                 <circle cx="609.6755088286418" cy="194.60301952713723" r="130.09822804766748" fill="#91cbf0" transform="matrix(1,0,0,1,-0.05069183558225632,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0506918, 0)" }} />
//                 <circle cx="739.7737368763093" cy="214.95111258837272" r="134.49057843060388" fill="#91cbf0" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
//                 <circle cx="874.2643153069132" cy="234.24763247414384" r="175.37102355671544" fill="#91cbf0" transform="matrix(1,0,0,1,-0.14621886610984802,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
//                 <circle cx="1049.6353388636287" cy="228.0160480080153" r="165.45425648452445" fill="#91cbf0" transform="matrix(1,0,0,1,-0.21463720500469208,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
//                 <circle cx="1215.0895953481531" cy="193.86397515148428" r="163.67575919102654" fill="#91cbf0" transform="matrix(1,0,0,1,-0.2978716492652893,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.297872, 0)" }} />
//                 <circle cx="1378.7653545391797" cy="117.9368733745151" r="239.76030157608085" fill="#91cbf0" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
//                 <circle cx="1618.5256561152605" cy="49.879417024808504" r="233.98508677744277" fill="#91cbf0" transform="matrix(1,0,0,1,-0.5121519565582275,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
//                 <circle cx="1852.5107428927033" cy="127.66374224513592" r="106.1583058233239" fill="#91cbf0" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
//                 <circle cx="1958.6690487160272" cy="-41.97071043150186" r="226.82643290336887" fill="#91cbf0" transform="matrix(1,0,0,1,-0.7960466146469116,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.796047, 0)" }} />
//                 <circle cx="0" cy="114.8528259112216" r="258.45597840731534" fill="#a0cff3" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="258.45597840731534" cy="311.40271838294774" r="130.30170893615696" fill="#a0cff3" transform="matrix(1,0,0,1,-0.022117653861641884,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0221177, 0)" }} />
//                 <circle cx="388.7576873434723" cy="307.91227830750785" r="165.76693058906847" fill="#a0cff3" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
//                 <circle cx="554.5246179325408" cy="359.87369329984267" r="131.3449422978309" fill="#a0cff3" transform="matrix(1,0,0,1,-0.21463720500469208,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
//                 <circle cx="685.8695602303717" cy="363.88146791768725" r="185.73983071078223" fill="#a0cff3" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
//                 <circle cx="871.609390941154" cy="406.81856344518474" r="195.0914629341293" fill="#a0cff3" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
//                 <circle cx="1066.7008538752832" cy="393.23881864938414" r="205.51976027416987" fill="#a0cff3" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
//                 <circle cx="1272.220614149453" cy="371.5480790094722" r="128.63916868273813" fill="#a0cff3" transform="matrix(1,0,0,1,-1.3677335977554321,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
//                 <circle cx="1400.859782832191" cy="321.90939407197266" r="178.08952494983873" fill="#a0cff3" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
//                 <circle cx="1578.9493077820298" cy="291.79775585710235" r="165.46554428701808" fill="#a0cff3" transform="matrix(1,0,0,1,-2.4294803142547607,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
//                 <circle cx="1744.4148520690478" cy="237.699225570544" r="184.50915626778553" fill="#a0cff3" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
//                 <circle cx="1928.9240083368334" cy="186.43400866932464" r="190.94027240017485" fill="#a0cff3" transform="matrix(1,0,0,1,-3.819972276687622,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.81997, 0)" }} />
//                 <circle cx="0" cy="308.42852688535567" r="242.97622759553695" fill="#aed2f6" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="242.97622759553695" cy="508.67102515344334" r="102.44890631741538" fill="#aed2f6" transform="matrix(1,0,0,1,-0.05069185048341751,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0506919, 0)" }} />
//                 <circle cx="345.42513391295233" cy="432.6820509825852" r="219.76052083558235" fill="#aed2f6" transform="matrix(1,0,0,1,-0.21463724970817566,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
//                 <circle cx="565.1856547485347" cy="510.0125165357375" r="186.57837470880338" fill="#aed2f6" transform="matrix(1,0,0,1,-0.5121520161628723,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
//                 <circle cx="751.7640294573381" cy="552.9496540517707" r="191.74560166089964" fill="#aed2f6" transform="matrix(1,0,0,1,-0.9663259983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
//                 <circle cx="943.5096311182377" cy="599.7083373453734" r="154.1124102423008" fill="#aed2f6" transform="matrix(1,0,0,1,-1.6002674102783203,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
//                 <circle cx="1097.6220413605386" cy="565.2540771439468" r="179.2821928289097" fill="#aed2f6" transform="matrix(1,0,0,1,-2.429480791091919,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
//                 <circle cx="1276.9042341894483" cy="518.8962561916424" r="192.43261058437005" fill="#aed2f6" transform="matrix(1,0,0,1,-3.4464619159698486,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44646, 0)" }} />
//                 <circle cx="1469.3368447738183" cy="471.63463204600544" r="192.79157715376013" fill="#aed2f6" transform="matrix(1,0,0,1,-4.600757122039795,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
//                 <circle cx="1662.1284219275783" cy="391.78621239041695" r="228.6457386555871" fill="#aed2f6" transform="matrix(1,0,0,1,-5.794002532958984,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.794, 0)" }} />
//                 <circle cx="1890.7741605831654" cy="361.99054483132295" r="198.1788610398936" fill="#aed2f6" transform="matrix(1,0,0,1,-6.911351680755615,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
//                 <circle cx="0" cy="468.5412960552526" r="255.3822532872895" fill="#bbd6f8" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="255.3822532872895" cy="579.1266763776453" r="223.6749896415642" fill="#bbd6f8" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
//                 <circle cx="479.0572429288537" cy="712.2062645731356" r="106.12905962846635" fill="#bbd6f8" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
//                 <circle cx="585.1863025573201" cy="665.480033094321" r="239.69897352771514" fill="#bbd6f8" transform="matrix(1,0,0,1,-0.9663257002830505,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
//                 <circle cx="824.8852760850352" cy="743.9244612812157" r="203.7384422443292" fill="#bbd6f8" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
//                 <circle cx="1028.6237183293645" cy="747.9778672083904" r="254.47241522528816" fill="#bbd6f8" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
//                 <circle cx="1283.0961335546526" cy="678.8321674461149" r="223.9959603795426" fill="#bbd6f8" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
//                 <circle cx="1507.0920939341952" cy="665.7364123921868" r="153.08003113672373" fill="#bbd6f8" transform="matrix(1,0,0,1,-6.180027961730957,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
//                 <circle cx="1660.1721250709188" cy="666.6773935185433" r="119.27765304847156" fill="#bbd6f8" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
//                 <circle cx="1779.4497781193904" cy="571.623744851874" r="191.93342143382324" fill="#bbd6f8" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
//                 <circle cx="1971.3831995532137" cy="565.6237622385829" r="160.6454463682669" fill="#bbd6f8" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="2132.0286459214803" cy="615.4611553414509" r="105.84145597520656" fill="#bbd6f8" transform="matrix(1,0,0,1,-9.785362243652344,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
//                 <circle cx="0" cy="789.1159168354396" r="134.07006930380032" fill="#c7daf9" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="134.07006930380032" cy="731.803133611731" r="211.89257638232974" fill="#c7daf9" transform="matrix(1,0,0,1,-0.14621886610984802,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.146219, 0)" }} />
//                 <circle cx="345.96264568613003" cy="857.775186640525" r="121.23958547421918" fill="#c7daf9" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
//                 <circle cx="467.20223116034924" cy="827.2429962413715" r="202.32647945319897" fill="#c7daf9" transform="matrix(1,0,0,1,-1.600266933441162,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.60027, 0)" }} />
//                 <circle cx="669.5287106135482" cy="890.5193572712238" r="170.03952119017066" fill="#c7daf9" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
//                 <circle cx="839.5682318037188" cy="933.67514989517" r="121.93496967270892" fill="#c7daf9" transform="matrix(1,0,0,1,-5,0)" style={{ transform: "matrix(1, 0, 0, 1, -5, 0)" }} />
//                 <circle cx="961.5032014764278" cy="946.673538243224" r="158.7321488623151" fill="#c7daf9" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
//                 <circle cx="1120.2353503387428" cy="917.7900790389693" r="144.74997600529" fill="#c7daf9" transform="matrix(1,0,0,1,-8.39973258972168,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39973, 0)" }} />
//                 <circle cx="1264.9853263440327" cy="866.6349400374586" r="205.16561015650342" fill="#c7daf9" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="1470.150936500536" cy="813.46881184922" r="204.73792046380464" fill="#c7daf9" transform="matrix(1,0,0,1,-9.853780746459961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
//                 <circle cx="1674.8888569643407" cy="800.8770036763406" r="160.89348191079338" fill="#c7daf9" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
//                 <circle cx="1835.782338875134" cy="705.8467328489608" r="215.84475745695616" fill="#c7daf9" transform="matrix(1,0,0,1,-9.853780746459961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
//                 <circle cx="2051.6270963320903" cy="826.2519992304938" r="88.06177883135955" fill="#c7daf9" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="0" cy="919.8550394611652" r="170.95413378236228" fill="#d2dffa" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="170.95413378236228" cy="1018.8209765404592" r="108.02458177701597" fill="#d2dffa" transform="matrix(1,0,0,1,-0.2146373838186264,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.214637, 0)" }} />
//                 <circle cx="278.97871555937826" cy="936.9606582255894" r="222.33671071295973" fill="#d2dffa" transform="matrix(1,0,0,1,-0.9663266539573669,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966327, 0)" }} />
//                 <circle cx="501.31542627233796" cy="1003.1271157526113" r="216.3133496810031" fill="#d2dffa" transform="matrix(1,0,0,1,-2.4294822216033936,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
//                 <circle cx="717.6287759533411" cy="1095.0931931913462" r="103.58219453918997" fill="#d2dffa" transform="matrix(1,0,0,1,-4.600759983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
//                 <circle cx="821.210970492531" cy="1097.8092576885576" r="172.97744117921994" fill="#d2dffa" transform="matrix(1,0,0,1,-6.911355018615723,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91136, 0)" }} />
//                 <circle cx="994.1884116717509" cy="1113.5068139192435" r="184.0043971940981" fill="#d2dffa" transform="matrix(1,0,0,1,-8.632269859313965,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
//                 <circle cx="1178.192808865849" cy="1084.635223522296" r="136.31321493485027" fill="#d2dffa" transform="matrix(1,0,0,1,-9.603246688842773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
//                 <circle cx="1314.5060238006993" cy="1064.3127753844274" r="129.3380521735165" fill="#d2dffa" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
//                 <circle cx="1443.8440759742157" cy="1008.7388241547208" r="183.53565851083644" fill="#d2dffa" transform="matrix(1,0,0,1,-9.908177375793457,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
//                 <circle cx="1627.379734485052" cy="1001.6378300269613" r="142.3660521323645" fill="#d2dffa" transform="matrix(1,0,0,1,-9.355046272277832,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="1769.7457866174166" cy="990.3368568423266" r="128.58171203469396" fill="#d2dffa" transform="matrix(1,0,0,1,-8.14531421661377,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.14531, 0)" }} />
//                 <circle cx="1898.3274986521105" cy="833.9818798674914" r="240.39668417031783" fill="#d2dffa" transform="matrix(1,0,0,1,-6.180017471313477,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
//                 <circle cx="0" cy="1048.838785518278" r="209.30101206810164" fill="#dce3fb" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="209.30101206810164" cy="1125.4122410676018" r="186.88058895629538" fill="#dce3fb" transform="matrix(1,0,0,1,-0.297871857881546,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.297872, 0)" }} />
//                 <circle cx="396.181601024397" cy="1154.7266044033033" r="208.4529222160504" fill="#dce3fb" transform="matrix(1,0,0,1,-1.3677345514297485,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.36773, 0)" }} />
//                 <circle cx="604.6345232404474" cy="1202.9306747579678" r="224.5882701026753" fill="#dce3fb" transform="matrix(1,0,0,1,-3.4464633464813232,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44646, 0)" }} />
//                 <circle cx="829.2227933431227" cy="1278.1432944668086" r="148.66618485967658" fill="#dce3fb" transform="matrix(1,0,0,1,-6.1800312995910645,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
//                 <circle cx="977.8889782027993" cy="1294.9069388354214" r="121.94923303407057" fill="#dce3fb" transform="matrix(1,0,0,1,-8.399735450744629,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.39974, 0)" }} />
//                 <circle cx="1099.83821123687" cy="1260.5342092415228" r="200.7211913263194" fill="#dce3fb" transform="matrix(1,0,0,1,-9.603246688842773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.60325, 0)" }} />
//                 <circle cx="1300.5594025631895" cy="1221.5393910219664" r="173.77610920926386" fill="#dce3fb" transform="matrix(1,0,0,1,-9.9945707321167,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.99457, 0)" }} />
//                 <circle cx="1474.3355117724534" cy="1228.2703819284131" r="106.71317713453703" fill="#dce3fb" transform="matrix(1,0,0,1,-9.785361289978027,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
//                 <circle cx="1581.0486889069905" cy="1156.6307200249528" r="177.50733544371715" fill="#dce3fb" transform="matrix(1,0,0,1,-8.843363761901855,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.84336, 0)" }} />
//                 <circle cx="1758.5560243507077" cy="1196.8079961257388" r="99.8870398825166" fill="#dce3fb" transform="matrix(1,0,0,1,-6.911342620849609,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91134, 0)" }} />
//                 <circle cx="1858.4430642332243" cy="1161.0231086506476" r="119.81513268096938" fill="#dce3fb" transform="matrix(1,0,0,1,-4.205990791320801,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.20599, 0)" }} />
//                 <circle cx="1978.2581969141936" cy="1075.6674185760437" r="170.97556873873643" fill="#dce3fb" transform="matrix(1,0,0,1,-1.8546714782714844,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85467, 0)" }} />
//                 <circle cx="0" cy="1284.603623789197" r="158.6636468423359" fill="#e5e8fc" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="158.6636468423359" cy="1219.1844924207328" r="256.1940719257073" fill="#e5e8fc" transform="matrix(1,0,0,1,-0.3967548906803131,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.396755, 0)" }} />
//                 <circle cx="414.85771876804324" cy="1363.7832539280275" r="165.20694657319248" fill="#e5e8fc" transform="matrix(1,0,0,1,-1.8546782732009888,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85468, 0)" }} />
//                 <circle cx="580.0646653412357" cy="1420.7470447473572" r="117.07335027288" fill="#e5e8fc" transform="matrix(1,0,0,1,-4.60075569152832,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
//                 <circle cx="697.1380156141157" cy="1442.9564045324619" r="101.80799404639693" fill="#e5e8fc" transform="matrix(1,0,0,1,-7.57051944732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57052, 0)" }} />
//                 <circle cx="798.9460096605127" cy="1441.1831180540419" r="182.33527526732806" fill="#e5e8fc" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="981.2812849278407" cy="1470.6606569539952" r="94.18720024732443" fill="#e5e8fc" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
//                 <circle cx="1075.4684851751651" cy="1456.7835052538758" r="109.95367650136212" fill="#e5e8fc" transform="matrix(1,0,0,1,-9.78536319732666,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78536, 0)" }} />
//                 <circle cx="1185.4221616765274" cy="1418.2839036270216" r="185.7729153679808" fill="#e5e8fc" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
//                 <circle cx="1371.1950770445083" cy="1342.4705782389922" r="244.88063156556473" fill="#e5e8fc" transform="matrix(1,0,0,1,-6.180026531219482,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18003, 0)" }} />
//                 <circle cx="1616.075708610073" cy="1377.1095617346746" r="114.87335802037803" fill="#e5e8fc" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
//                 <circle cx="1730.949066630451" cy="1339.810057583668" r="135.45671618300872" fill="#e5e8fc" transform="matrix(1,0,0,1,-0.9663257598876953,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966326, 0)" }} />
//                 <circle cx="1866.4057828134596" cy="1273.2873646114444" r="172.39820210749295" fill="#e5e8fc" transform="matrix(1,0,0,1,-0.09182167053222656,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
//                 <circle cx="2038.8039849209526" cy="1331.5979544746401" r="103.2443768792652" fill="#e5e8fc" transform="matrix(1,0,0,1,-0.09182170033454895,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918217, 0)" }} />
//                 <circle cx="0" cy="1413.8532975796743" r="196.78891868360478" fill="#ededfc" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="196.78891868360478" cy="1457.6357431904312" r="202.46539515428037" fill="#ededfc" transform="matrix(1,0,0,1,-0.5121520161628723,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.512152, 0)" }} />
//                 <circle cx="399.25431383788515" cy="1567.6924610053522" r="118.76811599575528" fill="#ededfc" transform="matrix(1,0,0,1,-2.429480791091919,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.42948, 0)" }} />
//                 <circle cx="518.0224298336404" cy="1512.8043293358087" r="253.03809426325472" fill="#ededfc" transform="matrix(1,0,0,1,-5.794002532958984,0)" style={{ transform: "matrix(1, 0, 0, 1, -5.794, 0)" }} />
//                 <circle cx="771.0605240968952" cy="1622.9882581466436" r="122.29395655629044" fill="#ededfc" transform="matrix(1,0,0,1,-8.632266998291016,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
//                 <circle cx="893.3544806531856" cy="1641.2468783150432" r="133.46914532148276" fill="#ededfc" transform="matrix(1,0,0,1,-9.853781700134277,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
//                 <circle cx="1026.8236259746684" cy="1626.3606344346983" r="227.51375487121865" fill="#ededfc" transform="matrix(1,0,0,1,-9.908178329467773,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.90818, 0)" }} />
//                 <circle cx="1254.337380845887" cy="1557.9951693455857" r="234.2225952202265" fill="#ededfc" transform="matrix(1,0,0,1,-8.843366622924805,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.84337, 0)" }} />
//                 <circle cx="1488.5599760661137" cy="1541.7901659543102" r="156.77247785553652" fill="#ededfc" transform="matrix(1,0,0,1,-6.180024147033691,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
//                 <circle cx="1645.33245392165" cy="1554.6080623901648" r="107.26498600356084" fill="#ededfc" transform="matrix(1,0,0,1,-2.749044418334961,0)" style={{ transform: "matrix(1, 0, 0, 1, -2.74904, 0)" }} />
//                 <circle cx="1752.597439925211" cy="1450.1036579942877" r="194.92182806334364" fill="#ededfc" transform="matrix(1,0,0,1,-0.6449489593505859,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644949, 0)" }} />
//                 <circle cx="1947.5192679885545" cy="1413.787263086485" r="185.55007994271975" fill="#ededfc" transform="matrix(1,0,0,1,-0.005429267883300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542927, 0)" }} />
//                 <circle cx="0" cy="1616.1695856371343" r="174.025345302388" fill="#f4f3fd" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="174.025345302388" cy="1651.9087598086728" r="176.8013394735172" fill="#f4f3fd" transform="matrix(1,0,0,1,-0.6449503302574158,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.64495, 0)" }} />
//                 <circle cx="350.82668477590516" cy="1678.2408031604427" r="194.5103634746076" fill="#f4f3fd" transform="matrix(1,0,0,1,-3.0886495113372803,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
//                 <circle cx="545.3370482505128" cy="1693.517434568152" r="259.07460229759226" fill="#f4f3fd" transform="matrix(1,0,0,1,-6.911351203918457,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
//                 <circle cx="804.4116505481051" cy="1785.5090726477815" r="221.20684545424018" fill="#f4f3fd" transform="matrix(1,0,0,1,-9.355050086975098,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="1025.6184960023452" cy="1805.227536183653" r="193.1419714766237" fill="#f4f3fd" transform="matrix(1,0,0,1,-10,0)" style={{ transform: "matrix(1, 0, 0, 1, -10, 0)" }} />
//                 <circle cx="1218.7604674789688" cy="1776.5291981330963" r="139.43122183307563" fill="#f4f3fd" transform="matrix(1,0,0,1,-9.355049133300781,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="1358.1916893120444" cy="1703.1857085939898" r="232.16525637762055" fill="#f4f3fd" transform="matrix(1,0,0,1,-6.911350727081299,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.91135, 0)" }} />
//                 <circle cx="1590.356945689665" cy="1705.1578216258001" r="146.23800998814255" fill="#f4f3fd" transform="matrix(1,0,0,1,-3.088648796081543,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08865, 0)" }} />
//                 <circle cx="1736.5949556778075" cy="1607.681408311554" r="216.19439301266524" fill="#f4f3fd" transform="matrix(1,0,0,1,-0.6449508666992188,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644951, 0)" }} />
//                 <circle cx="1952.7893486904727" cy="1696.658842012742" r="100.28748770394816" fill="#f4f3fd" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="0" cy="1818.1632719066465" r="151.53060674446132" fill="#faf9fe" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="151.53060674446132" cy="1812.4003307283895" r="186.188327876454" fill="#faf9fe" transform="matrix(1,0,0,1,-0.7960484027862549,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.796048, 0)" }} />
//                 <circle cx="337.7189346209153" cy="1827.0911559715173" r="224.22190059133675" fill="#faf9fe" transform="matrix(1,0,0,1,-3.8199806213378906,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.81998, 0)" }} />
//                 <circle cx="561.9408352122521" cy="1928.9818922922036" r="146.00031786441787" fill="#faf9fe" transform="matrix(1,0,0,1,-7.868866443634033,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.86887, 0)" }} />
//                 <circle cx="707.9411530766699" cy="1969.336497609616" r="101.86292747908838" fill="#faf9fe" transform="matrix(1,0,0,1,-9.78536605834961,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78537, 0)" }} />
//                 <circle cx="809.8040805557583" cy="1977.587120051049" r="130.5523349547425" fill="#faf9fe" transform="matrix(1,0,0,1,-9.853777885437012,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.85378, 0)" }} />
//                 <circle cx="940.3564155105008" cy="1999.0041332488072" r="168.8878641785739" fill="#faf9fe" transform="matrix(1,0,0,1,-8.145303726196289,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.1453, 0)" }} />
//                 <circle cx="1109.2442796890748" cy="1973.3151565598007" r="127.94548613162351" fill="#faf9fe" transform="matrix(1,0,0,1,-4.20596981048584,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.20597, 0)" }} />
//                 <circle cx="1237.1897658206983" cy="1913.6722353397502" r="232.66767909887884" fill="#faf9fe" transform="matrix(1,0,0,1,-0.9663095474243164,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.96631, 0)" }} />
//                 <circle cx="1469.8574449195771" cy="1839.0104166599026" r="241.54996391401647" fill="#faf9fe" transform="matrix(1,0,0,1,-0.005428314208984375,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.00542831, 0)" }} />
//                 <circle cx="1711.4074088335935" cy="1768.2195758656044" r="238.15934175744354" fill="#faf9fe" transform="matrix(1,0,0,1,-0.6449658274650574,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.644966, 0)" }} />
//                 <circle cx="1949.5667505910371" cy="1819.1865282044807" r="141.7433027274652" fill="#faf9fe" transform="matrix(1,0,0,1,-3.446505308151245,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.44651, 0)" }} />
//                 <circle cx="2091.3100533185025" cy="1828.7786721201078" r="117.62681075252748" fill="#faf9fe" transform="matrix(1,0,0,1,-7.570561408996582,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57056, 0)" }} />
//                 <circle cx="0" cy="2054.4926601414086" r="100.42278321549288" fill="#ffffff" transform="matrix(1,0,0,1,0,0)" style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
//                 <circle cx="100.42278321549288" cy="1982.8011492450562" r="179.20989574614188" fill="#ffffff" transform="matrix(1,0,0,1,-0.9663266539573669,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.966327, 0)" }} />
//                 <circle cx="279.6326789616348" cy="1992.9626568992107" r="215.4515305442926" fill="#ffffff" transform="matrix(1,0,0,1,-4.600759983062744,0)" style={{ transform: "matrix(1, 0, 0, 1, -4.60076, 0)" }} />
//                 <circle cx="495.0842095059274" cy="2091.006092442256" r="147.01684981779874" fill="#ffffff" transform="matrix(1,0,0,1,-8.632269859313965,0)" style={{ transform: "matrix(1, 0, 0, 1, -8.63227, 0)" }} />
//                 <circle cx="642.1010593237261" cy="2132.0703461197977" r="111.61182127338941" fill="#ffffff" transform="matrix(1,0,0,1,-9.977882385253906,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.97788, 0)" }} />
//                 <circle cx="753.7128805971155" cy="2153.0576018285356" r="90.33351860788036" fill="#ffffff" transform="matrix(1,0,0,1,-9.355046272277832,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.35505, 0)" }} />
//                 <circle cx="844.0463992049959" cy="2142.8072823664056" r="251.12294100271686" fill="#ffffff" transform="matrix(1,0,0,1,-6.180017471313477,0)" style={{ transform: "matrix(1, 0, 0, 1, -6.18002, 0)" }} />
//                 <circle cx="1095.1693402077126" cy="2148.8373390049005" r="137.19908141797066" fill="#ffffff" transform="matrix(1,0,0,1,-1.8546695709228516,0)" style={{ transform: "matrix(1, 0, 0, 1, -1.85467, 0)" }} />
//                 <circle cx="1232.3684216256834" cy="2117.0781763590185" r="158.72807408424177" fill="#ffffff" transform="matrix(1,0,0,1,-0.09181976318359375,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.0918198, 0)" }} />
//                 <circle cx="1391.0964957099252" cy="2084.6254661881176" r="159.53935092809076" fill="#ffffff" transform="matrix(1,0,0,1,-0.3967595100402832,0)" style={{ transform: "matrix(1, 0, 0, 1, -0.39676, 0)" }} />
//                 <circle cx="1550.635846638016" cy="2019.0689664259814" r="202.7549882015713" fill="#ffffff" transform="matrix(1,0,0,1,-3.0886662006378174,0)" style={{ transform: "matrix(1, 0, 0, 1, -3.08867, 0)" }} />
//                 <circle cx="1753.3908348395871" cy="1921.721939298738" r="246.7327240811963" fill="#ffffff" transform="matrix(1,0,0,1,-7.570536136627197,0)" style={{ transform: "matrix(1, 0, 0, 1, -7.57054, 0)" }} />
//                 <circle cx="2000.1235589207834" cy="1918.8615676013121" r="191.17099017320928" fill="#ffffff" transform="matrix(1,0,0,1,-9.785367012023926,0)" style={{ transform: "matrix(1, 0, 0, 1, -9.78537, 0)" }} />
//             </g>

//         </svg>
//     );
// };

// export default SeaOfClouds;
