/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect, } from "react";
import ReactPlayer from "react-player";

import gsap from "gsap/dist/gsap";
import { ScrollTrigger, } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin, } from "gsap/dist/ScrollToPlugin";

import Stars from "../components/stars";

// import Doragon1 from "../images/outlinespin50000-0120.mp4";
// import Doragon2 from "../images/lightning-strike60001-0240.mp4";
import Doragon1 from "../videos/lightning-strike60001-0240.mp4";
import Doragon2 from "../videos/ryujin.mp4";
import Creation from "../videos/creation.mp4";
import DoragonEgg from "../videos/doragonegg.mp4";

function Features() {
    const [ tab, setTab ] = useState(1);
    const [ tabClicked, setTabClicked ] = useState(false);
    const tabs = useRef(null);

    const heightFix = () => {
        if (tabs.current.children[tab]) {
            tabs.current.style.height = `${tabs.current.children[tab - 1].offsetHeight}px`;
        }
    };

    useEffect(() => {
        const text = document.getElementById("text-te5");
        const content = text.textContent;
        text.textContent = "";

        [ ...content ].map((letter) => {
            const newSpan = document.createElement("span");
            newSpan.setAttribute("aria-hidden", "true");
            newSpan.innerHTML = `${letter === " " ? "&nbsp;" : letter}`; // Keep space between words
            return text.appendChild(newSpan);
        });
    }, []);

    useEffect(() => {
        heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ tab ]);

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const elementRef = useRef(null);
    const videoElementRef = useRef(null);
    useEffect(() => {
        const element = elementRef.current;
        const listItems = gsap.utils.toArray(".list__item");
        const bannerSlide = gsap.utils.toArray(".banner__slide");
        const dBoxes = gsap.utils.toArray(".down");
        const uBoxes = gsap.utils.toArray(".up");

        const videoElement = videoElementRef.current;
        const videoItems = gsap.utils.toArray(".reactPlayer");
        console.log("bannerSlide", bannerSlide);
        // t1.to(element, {
        //     lazy: false,
        //     scrollTrigger: {
        //         trigger: element,
        //         start: "top top",
        //         end: pinWrapWidth,
        //         scroller: ".App", // locomotive element
        //         scrub: true,
        //         pin: true,
        //         markers: true,
        //         // position: "fixed",
        //         anticipatePin: 1,
        //         // pinReparent: true,
        //         // toggleActions: "play none none reverse",
        //     },
        //     // we have to increase scrolling height of this section same as the scrolling element width
        //     height: `${scrollingElement.scrollWidth}px`,
        //     ease: "none,",
        // });
        // console.log("sections", sections.length);

        // const scrollTween = gsap.to(listItems, {
        //     xPercent: -100 * (listItems.length - 1),
        //     ease: "sine",
        //     scrollTrigger: {
        //         trigger: element,
        //         pin: true,
        //         scrub: 2,
        //         invalidateOnRefresh: true,
        //         ease: "none",

        //         // position: "fixed",
        //         // fastScrollEnd: true,
        //         // snap: 1 / (sections.length - 1),
        //         end: () => { return `+=${element.offsetWidth}`; },
        //     },

        // });

        listItems.forEach((item, index) => {
            gsap.set(item, { autoAlpha: 0, x: index % 2 ? 100 : -100, zIndex: 100 });
            gsap.to(item, {
                x: 0,
                zIndex: 100,
                autoAlpha: 1,
                // ease: "power2.inOut",
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "bottom",
                    toggleActions: "play reverse play reverse",
                },

            });
            gsap.from(item, {
                // duration: 4,
                // y: 20,
                // autoAlpha: 0,
                // stagger: 10,
                x: 0,
                autoAlpha: 1,
                ease: "back.out(2)",
            });
        });

        gsap.set(".section__item", { autoAlpha: 0, x: 100 });
        gsap.to(".section__item", {
            x: 0,
            autoAlpha: 1,
            // ease: "power2.inOut",
            scrollTrigger: {
                trigger: ".section__item",
                start: "top 90%",
                end: "bottom",
                toggleActions: "play reverse play reverse",
                // markers: true,

            },
        });
        // gsap.set(".section__item", { autoAlpha: 1, y: 0 });

        videoItems.forEach((item, index) => {
            gsap.set(item, { autoAlpha: 0, x: 300 });
            gsap.to(item, {
                x: 0,
                autoAlpha: 1,
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "bottom",
                    toggleActions: "play reverse play reverse",

                },
            });
        });

        // const dBox = dBoxes.forEach((e) => {
        //     gsap.to(e, {
        //         yPercent: 50,
        //         autoAlpha: 0,
        //         lazy: false,
        //         scrollTrigger: {
        //             containerAnimation: scrollTween,
        //             trigger: e,
        //             start: "left 50%",
        //             end: "left 30%",
        //             scrub: true,
        //         },
        // });
        // });
        ScrollTrigger.refresh();
        // return () => {
        //     // Let's clear instances
        //     // tl.kill();
        //     ScrollTrigger.refresh();
        // };
    }, [ ]);

    return (
        <div
            id="features"
            className="bg-gradient-container overflow-hidden relative "
            style={{
                // background: "linear-gradient(0deg, rgba(11,62,95,1) 3%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%)",
                // background: "radial-gradient(circle, rgba(11,62,95,1) 0%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%)",
                // background: "radial-gradient(circle, rgba(11,63,96,1) 1%, rgba(23,106,144,1) 35%, rgba(12,65,98,1) 84%, rgba(11,62,95,1) 100%)",
                // background: "radial-gradient(circle, rgba(11,62,95,1) 0%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%)",
            }}
        >

            <div className="container mx-auto px-4  absolute right-0 top-0">
                <Stars amount={6} />
            </div>
            <div className="container mx-auto px-4  absolute -left-20 top-0">
                <Stars amount={3} />
            </div>
            <section
                className="section__item z-10 first-paragraph relative bg-theme-100 scroll-mt-6 snap-y setion contain"
            >

                {/* Section background (needs .relative class on parent and next sibling elements) */}
                {/* <div className="absolute inset-0 bg-theme-100 pointer-events-none " aria-hidden="true" />
                <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-theme-800 transform -translate-y-1/2" /> */}
                {/* <div className="nightsky-container clouds-container">
                    <div className="nightsky"> */}

                <div
                    className="relative max-w-6xl mx-auto px-4 sm:px-6 overflow-hidden "
                    style={{
                        background: "linear-gradient(0deg, rgba(11,62,95,1) 3%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%) no-repeat border-box, radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%)",
                        // background:

                        // background: "radial-gradient(circle, rgba(11,62,95,1) 0%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%)",
                        // background: "radial-gradient(circle, rgba(11,63,96,1) 1%, rgba(23,106,144,1) 35%, rgba(12,65,98,1) 84%, rgba(11,62,95,1) 100%)",
                    }}
                >

                    <div className="custom-shape-divider-top-1665222444">
                        <svg
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                            style={{
                                "--scroll-progress": "10",
                            }}
                        >
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill" />
                            <path
                                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                                opacity=".4"
                                className="shape-fill"
                            />
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill" />
                        </svg>
                    </div>

                    {/* <div className="custom-shape-divider-top-1664753026 opacity-70">
                        <defs>
                            <linearGradient gradientTransform="rotate(150, 2.5, 0.5)" x1="20%" y1="0%" x2="50%" y2="100%" id="hero-gradient1">
                                <stop stopColor="#2D82B7" stopOpacity="1" offset="0%" />
                                <stop stopColor="rgb(23, 106, 144)" stopOpacity="0.2" offset="70%" />
                                <stop stopColor="#E6FFFA" stopOpacity="0.1" offset="100%" />
                            </linearGradient>
                        </defs>
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path fill="url(#hero-gradient1)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" />
                        </svg>
                    </div> */}

                    {/* <div className="custom-shape-divider-top-1664702577">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill" />
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill" />
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill" />
                        </svg>
                    </div> */}
                    <div className="clouds-container">
                        <div
                            className="clouds-1"
                        />
                        {/* <div
                                    className="clouds-2 "
                                    // style={{
                                    //     background: "linear-gradient(0deg, rgba(11,62,95,1) 3%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%) no-repeat border-box, radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%)",
                                    // }}
                                /> */}
                        <div
                            className="clouds-3"
                        />
                    </div>
                    <div className="nightstars" />
                    <div className="nightstars1" />
                    <div className="nightstars2" />
                    <div className="nightshooting-stars" />
                    <div className="overflow-hidden w-screen">
                        <div
                            className="night  absolute top-0 h-screen w-full"
                            style={{
                                // background: "radial-gradient(circle, rgba(11,62,95,1) 0%, rgba(12,65,98,1) 81%, rgba(23,106,144,1) 94%)",
                                height: "150%",
                            }}
                        >
                            <div className="shooting_star" />
                            <div className="shooting_star" />
                            <div className="shooting_star" />
                            <div className="shooting_star" />
                            <div className="shooting_star" />
                            <div className="shooting_star" />
                            <div className="shooting_star" />
                            {/* <div className="container mx-auto px-4  absolute right-0 bottom-0">
                                <Stars amount={6} />
                            </div>
                            <div className="container mx-auto px-4  absolute -left-20 bottom-0">
                                <Stars amount={6} />
                            </div> */}
                        </div>
                    </div>

                    <div className="pt-12 md:pt-20">
                        {/* Section header */}
                        <h3
                            // className="h3  sm:text-left text-center sm:text-4xl text-xl mb-3 text-theme-800 tracking-widest relative z-50 font-bold leading-loose"
                            id="text-te5"
                            // list__item
                            className="relative uppercase text-theme-800 tracking-tighter select-none text-4xl sm:text-7xl font-black transition-all duration-300 ease-out shadow-text5 hover:shadow-h-text5"
                            style={{
                                width: "100%",
                                // textShadow: " 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #dfdddc, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2;",
                            }}
                        >
                            WELCOME TO THE LANDING
                            <br />

                        </h3>

                        {/* Section content */}
                        <div className="md:grid md:grid-cols-12 md:gap-6 snap-start mt-20">
                            {/* <div className="night z-50 absolute top-0 h-screen w-full">
                                <div className="shooting_star" />
                                <div className="shooting_star" />
                                <div className="shooting_star" />
                                <div className="shooting_star" />
                                <div className="shooting_star" />
                                <div className="shooting_star" />
                                <div className="shooting_star" />
                            </div> */}
                            {/* Content */}
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6 snap-start ">

                                <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                                    <h3
                                        className="list__item h3 w-full text-left sm:text-3xl text-xl mb-3 text-theme-800 tracking-widest relative z-50 font-bold leading-loose"
                                    >
                                        . . .Your journey begins here!
                                        {/* <div className="night z-50">
                                            <div className="shooting_star" />
                                            <div className="shooting_star" />
                                            <div className="shooting_star" />
                                        </div> */}
                                        <Stars amount={3} />
                                        <div className="container bg-black z-40 mx-auto px-4  absolute right-0 bottom-0">

                                            <Stars amount={6} />
                                        </div>
                                        <div className="container mx-auto px-4  absolute -left-20 bottom-0">
                                            <Stars amount={3} />
                                        </div>
                                    </h3>
                                    <br />

                                    <p className="text-xl text-theme-1100 font-press-start">
                                        Doragons are WILD animals! But, not to worry, each Doragon can be diligently trained!
                                        {" "}
                                        <br />
                                        <br />
                                        <span className="text-theme-200">What secrets are waiting to be uncovered in Doragons Landing?</span>
                                        <br />
                                        <br />
                                        <span className="text-theme-1000">Explore the features of $DORAGON:</span>
                                    </p>
                                </div>

                                {/* Tabs buttons */}
                                <div className="mb-8 md:mb-0 space-y-5 relative z-50 " style={{ zIndex: "100" }} ref={elementRef}>
                                    <div className="list__item  relative" style={{ zIndex: "100" }}>
                                        <a
                                            className={`bg-theme-150 list__item-featured list__container hover:bg-theme-300 hover:bg-opacity-50 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? " shadow-md border-theme-700 hover:border-theme-1000" : "border-theme-900 border-transparent"}`}
                                            href="#0"
                                            onClick={(e) => { e.preventDefault(); setTab(1); }}
                                            // style={{
                                            //     position: "relative",
                                            //     zIndex: "100",
                                            //     // boxShadow: "rgb(66 226 184 ) 0px 20px 30px -10px, rgba(198, 237, 254, 0.4) 0px -50px 36px -28px inset",
                                            //     // boxShadow: "rgba(198, 237, 254, 0.17) 0px -23px 25px 0px inset, rgba(198, 237, 254, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset, rgba(198, 237, 254, 0.06) 0px 2px 1px, rgba(198, 237, 254, 0.09) 0px 4px 2px, rgba(198, 237, 254, 0.09) 0px 8px 4px, rgba(198, 237, 254, 0.09) 0px 16px 8px, rgba(198, 237, 254, 0.09) 0px 32px 16px",
                                            //     boxShadow: "rgba(66, 226, 184, 0.4) -5px 5px, rgba(66, 226, 184, 0.3) -10px 10px, rgba(66, 226, 184, 0.2) -15px 15px, rgba(66, 226, 184, 0.1) -20px 20px, rgba(66, 226, 184, 0.05) -25px 25px",
                                            //     // boxShadow: "rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px",
                                            // }}

                                            // boxShadow: "rgba(243, 223, 191, 0.17) 0px -23px 25px 0px inset, rgba(243, 223, 191, 0.15) 0px -36px 30px 0px inset, rgba(243, 223, 191, 0.1) 0px -79px 40px 0px inset",
                                            style={tab === 1 && tabClicked ? {
                                                // boxShadow: "rgba(66, 226, 184, 0.4) -5px 5px, rgba(66, 226, 184, 0.3) -10px 10px, rgba(66, 226, 184, 0.2) -15px 15px, rgba(66, 226, 184, 0.1) -20px 20px, rgba(66, 226, 184, 0.05) -25px 25px",
                                                boxShadow: "0 0 0 2px #f9bf23ff, rgba(66, 226, 184, 0.4) -5px 5px, rgba(66, 226, 184, 0.3) -10px 10px, rgba(66, 226, 184, 0.2) -15px 15px, rgba(66, 226, 184, 0.1) -20px 20px, rgba(66, 226, 184, 0.05) -25px 25px, rgba(198, 237, 254, 0.17) 0px -23px 25px 0px inset, rgba(198, 237, 254, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset",
                                            } : {

                                                zIndex: "100",
                                            }}
                                        >
                                            <div className="flex flex-col justify-between w-full">

                                                <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-800 text-2xl">A New Birth</div>
                                                <div className="text-theme-1100 hover:text-theme-1000  leading-snug tracking-tight mt-2 font-press-start">
                                                    Since ancient times, when a Doragon egg was discovered within the digital ledger, it became a momentous event. The hatching of a Doragon is synchronized with the mining of a new $DORAGON token, creating a bond between the creature and its corresponding token. This bond is sacred, with the Doragon acting as a protector and guide for its owner in the Doragon universe.
                                                    {" "}
                                                    <span className="text-theme-200">What wonders await here?</span>
                                                </div>

                                            </div>
                                            <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 1 ? "ease-in duration-300 bg-theme-900 hover:bg-theme-1000 hover:shadow-lg" : "bg-theme-800 bg-transparent"}`}>
                                                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="list__item list__item-featured" style={{ zIndex: "100" }}>
                                        <a
                                            className={`bg-theme-150 hover:bg-theme-300 hover:bg-opacity-50 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? "border-theme-700 hover:border-theme-1000 hover:shadow-lg" : "border-theme-900 border-transparent"}`}
                                            href="#0"
                                            onClick={(e) => { e.preventDefault(); setTab(2); }}
                                            // style={{
                                            //     position: "relative",
                                            //     zIndex: "100",
                                            //     // boxShadow: "rgb(66 226 184 ) 0px 20px 30px -10px, rgba(198, 237, 254, 0.4) 0px -50px 36px -28px inset",
                                            //     // boxShadow: "rgba(198, 237, 254, 0.17) 0px -23px 25px 0px inset, rgba(198, 237, 254, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset, rgba(198, 237, 254, 0.06) 0px 2px 1px, rgba(198, 237, 254, 0.09) 0px 4px 2px, rgba(198, 237, 254, 0.09) 0px 8px 4px, rgba(198, 237, 254, 0.09) 0px 16px 8px, rgba(198, 237, 254, 0.09) 0px 32px 16px",
                                            //     boxShadow: "rgba(66, 226, 184, 0.4) -5px 5px, rgba(66, 226, 184, 0.3) -10px 10px, rgba(66, 226, 184, 0.2) -15px 15px, rgba(66, 226, 184, 0.1) -20px 20px, rgba(66, 226, 184, 0.05) -25px 25px",
                                            //     // boxShadow: "rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px",
                                            // }}
                                        >
                                            <div className="flex flex-col justify-between w-full">

                                                <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-800 text-2xl">Selective Evolution</div>
                                                <div className="text-theme-1100 hover:text-theme-1000 leading-snug tracking-tight mt-2 font-press-start text-base">
                                                    Scattered across strange worlds, distant plants and vast, sprawling civilizations across the stars - Doragons can be found among the elements they possess.
                                                    <br />
                                                    Some Doragon are common, some are
                                                    {" "}
                                                    <span className="text-theme-200">extremely rare!</span>
                                                    {" "}
                                                    Each Doragon has the innate ability to tap into the elemental blockchain, allowing them to evolve and grow in power as the $DORAGON network expands and diversifies.
                                                </div>

                                            </div>
                                            <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 2 ? "bg-theme-900 hover:bg-theme-1000 hover:shadow-lg" : "bg-theme-800 bg-transparent"}`}>
                                                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fillRule="nonzero" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="list__item list__item-featured" style={{ zIndex: "100" }}>
                                        <a
                                            className={` bg-theme-150 hover:bg-theme-300 hover:bg-opacity-50 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? "border-theme-700 hover:border-theme-1000 hover:shadow-lg" : "border-theme-900 border-transparent"}`}
                                            href="#0"
                                            onClick={(e) => { e.preventDefault(); setTab(3); }}
                                        >
                                            <div className="flex flex-col justify-between w-full">

                                                <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-800 snap-start text-2xl"> Doragon Selection Process</div>
                                                <div className="text-theme-1100 hover:text-theme-1000 leading-snug tracking-tight mt-2 font-press-start text-base">
                                                    Doragons have unimaginable powers, but their Will must be focused and trained to reach its fullest potential. . When a user acquires a $DORAGON token, they are not just investing in a currency but in a companion. The bond grows as they journey through the Doragon universe, unlocking hidden abilities and secrets.
                                                </div>

                                            </div>
                                            <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 3 ? "bg-theme-900 hover:bg-theme-1000 hover:shadow-lg" : "bg-theme-800 bg-transparent"}`}>
                                                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fillRule="nonzero" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="list__item list__item-featured" style={{ zIndex: "100" }}>
                                        <a
                                            className={` bg-theme-150 hover:bg-theme-300 hover:bg-opacity-50 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 4 ? "border-theme-700 hover:border-theme-1000 hover:shadow-lg" : "border-theme-900 border-transparent"}`}
                                            href="#0"
                                            onClick={(e) => { e.preventDefault(); setTab(4); }}
                                        >
                                            <div className="flex flex-col justify-between w-full">

                                                <div className="font-bold mb-1 leading-tighter text-theme-800 text-2xl">Birth of a new Doragon</div>
                                                <div className="text-theme-1100 hover:text-theme-1000 leading-snug tracking-tight mt-2 font-press-start text-base">
                                                    The emergence of a Doragon from its egg symbolizes the activation of a new $DORAGON token. This event is celebrated across the digital realm, as it signifies the expansion and strengthening of the Doragon universe. Each new Doragon brings with it untold potential and mysteries, waiting to be discovered by its Master.
                                                </div>

                                            </div>
                                            <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 4 ? "bg-theme-900 hover:bg-theme-1000 hover:shadow-lg" : "bg-theme-800 bg-transparent"}`}>
                                                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/* Tabs items */}
                            <div
                                className=" sm:flex flex-row  relative rounded-lg hidden"
                                ref={tabs}
                            >
                                <div
                                    className=" md:flex flex-col text-center m-auto  lg:text-right rounded-lg sticky right-0 transition-all ease-in-out duration-700 transform
                                    "
                                    ref={videoElementRef}
                                >
                                    {/* Item 1 */}
                                    {/* <Transition
                                        show={tab === 1}
                                        appear
                                        className="w-full"
                                        enter="transition ease-in-out duration-700 transform order-first"
                                        enterStart="opacity-0 translate-y-16"
                                        enterEnd="opacity-100 translate-y-0"
                                        leave="transition ease-in-out duration-300 transform absolute"
                                        leaveStart="opacity-100 translate-y-0"
                                        leaveEnd="opacity-0 -translate-y-16"
                                    > */}
                                    {tab === 1 && (

                                        <div
                                            className="absolute inline-flex flex-col overflow-x-hidden reactPlayer  "
                                            style={{
                                                transform: "translate(-55px, -98%)",
                                            }}
                                        >
                                            {/* <div className=" player-wrapper m-auto  transform -translate-x-1/2 items-center justify-center  z-50 "> */}
                                            <div>
                                                <ReactPlayer
                                                    loop
                                                    playing
                                                    muted
                                                    url={Doragon1}
                                                    className="react-player reactPlayer transition-all ease-in-out duration-700 transform "
                                                    style={{
                                                        width: "auto", // Reset width
                                                        // height: "auto",
                                                        margin: "0 auto",
                                                        position: "sticky",
                                                        top: "0",

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* </Transition> */}
                                    {/* Item 2 */}
                                    {tab === 2 && (

                                        <div className="absolute inline-flex flex-col overflow-x-hidden  ">
                                            <div className="player-wrapper m-auto  transform -translate-x-1/2 right-1/2 z-50  ">
                                                <ReactPlayer
                                                    loop
                                                    playing
                                                    muted
                                                    url={Doragon2}
                                                    className="react-player transition-all ease-in-out duration-700 transform"
                                                    style={{
                                                        width: "auto", // Reset width
                                                        // height: "auto",
                                                        margin: "0 auto",
                                                        transform: "translate( 30%)",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* Item 3 */}
                                    {tab === 3 && (

                                    <div
                                        className="absolute inline-flex flex-col overflow-x-hidden reactPlayer transition-all ease-in-out duration-700 transform  "
                                        style={{
                                            transform: "translate(-55px, 100%)",
                                        }}
                                    >
                                        <ReactPlayer
                                            loop
                                            playing
                                            muted
                                            url={Creation}
                                            className="react-player transition-all ease-in-out duration-700"
                                            style={{
                                                width: "auto", // Reset width
                                                // height: "auto",
                                                margin: "0 auto",
                                                // transform: "translate( 30%)",
                                            }}
                                        />
                                    </div>
                                    )}
                                    {tab === 4 && (

                                    <div
                                        className="absolute inline-flex flex-col overflow-x-hidden reactPlayer  "
                                        style={{
                                            transform: "translate(-55px, 160%)",
                                        }}
                                    >
                                        <ReactPlayer
                                            loop
                                            playing
                                            muted
                                            url={DoragonEgg}
                                            className="react-player"
                                            style={{
                                                width: "auto", // Reset width
                                                // height: "auto",
                                                margin: "0 auto",
                                                // transform: "translate( 30%)",
                                            }}
                                        />
                                    </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </section>
        </div>
    );
}

export default Features;
