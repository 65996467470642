import React, {
    useRef, Suspense, lazy, useState, useEffect,
} from "react";

import {
    // BrowserRouter,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";

// import { LocomotiveScrollProvider, } from "react-locomotive-scroll";
// import { AnimatePresence, motion, } from "framer-motion/dist/framer-motion";
import "./css/style.scss";
// import "locomotive-scroll/dist/locomotive-scroll.css";
// import AOS from "aos";

import Loader from "./components/loader";
import Header from "./partials/Header";

// const HeroHome = React.lazy(() => { return import("./partials/HeroHome"); });
// const Footer = React.lazy(() => { return import("./partials/Footer"); });
// const Roadmap = React.lazy(() => { return import("./partials/Roadmap"); });
// const Features = React.lazy(() => { return import("./partials/Features"); });
// const FeaturesFAQ = React.lazy(() => { return import("./partials/FeaturesFAQ"); });
// const Carousel = React.lazy(() => { return import("./partials/Carousel"); });

// import HeroHome from "./partials/HeroHome";
// import DoragonDen from "./pages/DoragonDen";
import Roadmap from "./partials/Roadmap";
import Features from "./partials/Features";
import FeaturesFAQ from "./partials/FeaturesFAQ";
import Carousel from "./partials/Carousel";
import Footer from "./partials/Footer";

import Banner from "./components/banner/banner";
import Animeload from "./components/animeload";
import LoadingScreen from "./components/loadingScreen";
import MusicPlayer from "./components/music-player";
// import ScrollMap from "./partials/ScrollMap";
// import SmoothScroll from "./utils/SmoothScroll";

// import Book from "./partials/Book";

// import Mask from "./partials/Mask";

function App() {
     useEffect(() => {
        // Check if it's the first visit
        if (!localStorage.getItem('firstVisit')) {
            // Set the flag in localStorage
            localStorage.setItem('firstVisit', 'true');

            // Reload the page
            window.location.reload();
        } else {
            // Remove the flag for subsequent visits
            localStorage.removeItem('firstVisit');
        }
    }, []);
    const containerRef = useRef(null);

    const [ loaded, setLoaded ] = useState(false);
    const [ clicked, setClicked ] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
            // Change to 5000 for production
        }, 1000);
        // clicked ? setClicked(true) : "";
        clicked ? () => { setClicked(true); } : "";
    }, [ clicked ]);
    const location = useLocation();
    if (window.location.host.split(".")[0] == "app") {
        return (
            <>
                <Loader loaded={loaded} setLoaded={setLoaded} />
                {/* <DoragonDen loaded={loaded} setLoaded={setLoaded} /> */}
            </>
        );
    }
    return (

        <main className="App" id="app" ref={containerRef}>
            {loaded ? (
                <Suspense fallback={<LoadingScreen />}>
                    <>

                        {/*  Site header */}
                        <Header location={location} />
                        <Routes>
                            <Route
                                path="/"
                                element={(
                                    <>
                                        {/* <Mask /> */}
                                        <Loader loaded={loaded} setLoaded={setLoaded} />
                                        {/* <Book /> */}
                                        <Features />
                                        <Carousel />
                                        <Roadmap />
                                        <FeaturesFAQ />
                                    </>
                                    )}
                            />
                        </Routes>
                        <Footer />
                        <Banner />
                        <MusicPlayer />
                    </>
                </Suspense>
            ) : (
                <LoadingScreen />
            )}
        </main>

    );
}

export default App;
