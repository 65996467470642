import React from "react";

const Stars = ({ amount, type }) => {
    const stars = [];
    if (type === "stretch") {
        for (let i = 0; i < amount; i++) {
            stars.push(
                <>
                    <div className="container mx-auto px-4  absolute right-0 top-0">
                        {/* <Stars amount={6} /> */}
                        <div className="shooting_star hover:bg-theme-300 hover:bg-opacity-40" key={i} />
                    </div>
                    <div className="container mx-auto px-4  absolute -left-20 top-0">
                        {/* <Stars amount={6} /> */}
                        <div className="shooting_star hover:bg-theme-300 hover:bg-opacity-40" key={i} />
                    </div>
                </>,
            );
        }
    } else {
        for (let i = 0; i < amount; i++) {
            stars.push(<div className="shooting_star hover:bg-theme-300 hover:bg-opacity-40" key={i} />);
        }
    }
    // if (type === "stretch") {
    //     return (
    //         <div className="shooting_stars">
    //             {stars}
    //         </div>
    //          <div className="container mx-auto px-4  absolute right-0 top-0">
    //             <Stars amount={6} />
    //         </div>
    //         <div className="container mx-auto px-4  absolute -left-20 top-0">
    //             <Stars amount={6} />
    //         </div>
    //     );
    // }
    return (
        <div className="night ">
            {stars}
        </div>
    );
};

export default Stars;
