import React from "react";
// import bgclouds from "../images/bgclouds.jpg";
// import { Link, } from "react-router-dom";
/* eslint-disable max-len */
function Footer() {
    return (

        <footer className="bg-theme-700 overflow-hidden relative z-50"
        style={{
            zIndex: "100",
        }}
        >
            <div className="max-w-6xl mx-auto px-4 sm:px-6 main ">

                {/* Bottom area */}
                <div className=" footer md:flex md:items-center md:justify-between py-4 md:py-4 border-t border-theme-900">
                    <div className=" justify-center justify-items-center items-center">
                        {/* Social links */}
                        <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0 justify-center justify-items-center items-center"
                        style={{
                            zIndex: "100",
                            position: "relative",
                        }}
                        >
                            <li className="justify-center justify-items-center items-center">
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/AkachanDoragan" className="flex justify-center items-center text-theme-700 hover:text-theme-1100 bg-theme-900 hover:bg-theme-1000 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                    <svg className="w-8 h-8 fill-theme-300" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                    </svg>
                                </a>
                            </li>
                            <li className="ml-4  bg-none group flex relative z-40">
                                {/* <a to="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=sol&fixed=in" href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=sol&fixed=in" className="flex justify-center items-center text-theme-700 hover:text-theme-1100 bg-theme-900 hover:bg-theme-1000 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github"> */}
                                      {/* <div className=" bg-none group flex relative"> */}
                                           <img src="../images/Raydium-RAY-coin.png" alt="Raydium Logo" width={30} height={30} />
                                           {/* <span class="group-hover:opacity-100 transition-opacity bg-theme-150 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Soon...</span> */}
                                        {/* </div>  */}
                                {/* </a> */}
                            </li>

                              <li className="ml-4  bg-none group flex relative z-40">
                                <a to="https://birdeye.so/token/CYF7DokmWdSHPzHaFw8CaVJfTzCMNT3MtMtMeFww2Av?chain=solana" 
                                target="_blank" rel="noreferrer"
                                href="https://birdeye.so/token/CYF7DokmWdSHPzHaFw8CaVJfTzCMNT3MtMtMeFww2Av?chain=solana" className="flex justify-center items-center text-theme-700 hover:text-theme-1100 bg-theme-900 hover:bg-theme-1000 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github">
                                      {/* <div className=" bg-none group flex relative"> */}
                                           <img src="../images/birdeye.jpg" alt="Birdeye Logo" width={30} height={30} className="rounded-full" />
                                           {/* <span class="group-hover:opacity-100 transition-opacity bg-theme-150 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Soon...</span> */}
                                        {/* </div>  */}
                                </a>
                            </li>

                        </ul>
                    </div>
                    {/* Copyrights note */}
                    <div className="text-sm text-theme-900 mr-4">Made by Akachan Doragon. All rights reserved. C 2022</div>

                </div>

            </div>
        </footer>
    );
}

export default Footer;
