import React from "react";
import Animeload from "./animeload";

const LoadingScreen = () => {
    return (
        <div className="overflow-hidden h-screen">
            <Animeload />
            <div style={{
                // absolute center
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

            }}
            >
                <p
                    className="text-4xl"
                    style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "8.5rem",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    Loading
                </p>
            </div>
            <img
                src="/images/loading.gif"
                alt="loading"
                className=" opacity-50 overflow-hidden"
                style={{
                    // absolute center
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "50%",
                    // height: "70vh",
                }}
            />
        </div>
    );
};

export default LoadingScreen;
