/* eslint-disable max-len */
import React, { useState, } from "react";

                            
                            
import { ReactComponent as DoragonSticker, } from "../images/doragonsticker6.svg";

function FeaturesFAQ() {
    // const { scroll } = usis a token on the Solana blockchain. It is a community driven project with a focus on utility and innovation. The project is inspired by the Japanese culture and the Dragon, a symbol of power, strength and wisdom.eLocomotiveScroll();
    const [ faq, setFaq ] = useState([ {
        id: 1,
        title: "What is A $Doragon?",
        // Doragon 
        content: "Akachan Doragon is a token on the Solana blockchain with planned features. It is a community driven project with a focus on utility and innovation. The project is inspired by the Japanese culture and the Dragon, a symbol of power, strength and wisdom. ",
        clicked: false,
    },
    {
        id: 2,
        clicked: false,
        title: "Who's behind the magic?",
        content: `First off, we are not a big company, we're not a company at all! 
        We're a small team of developers, artists, and degens who are energized about the future of a distributed, decentralized, and open-source ecosystem.`,

        content2: `We are a small, but mighty team of experienced developers, and artists. Every part of the build process is curated by the team. 
        We're aiming to build a decentralized ecosystem, that is our community of developers, designers, and artists who are building the future of the decentralized web togther.
       `,
        // content3: `We're choosing to build on Solana because of its
        // speed, scalability and low fees. We're also excited about the community and the
        // opportunities it provides to collaborate with other projects.
        // `,
    },
    {
        id: 3,
        clicked: false,
        title: "Why should I buy $DORAGON?",
        content: " This project is a developing innovation. NFA you should not expect any return on investment. But you'll be supporting the development of the Doragon ecosystem. There will be access to exclusive content, events, and giveaways planned.",
    },
    {
        id: 4,
        clicked: false,
        title: "Wen moon?",
        content: "We are not financial advisors, we only believe that the Solana ecosystem is the future of defi. The Tokens do not constitute shares or equities or securities or financial instruments or investments in any form in any jurisdiction.",
    },
    {
        id: 5,
        clicked: false,
        title: "Wen CEX?",
        content: "Post launch we will be looking to list on a CEX ",
    },
    {
        id: 6,
        clicked: false,
        title: "Wen Supply?",
        content: "100 B supply. See Tokenomics for more details.",
    },

    ]);
    return (
        <section
            className="relative bg-theme-300 z-50 overflow-hidden"
            id="faq"
            style={{
                background:
                        "linear-gradient(0deg, rgba(45,166,241,.3) 29%, rgba(66,226,184,0.54) 50%, rgba(45,166,241,1) 67%, rgba(40,149,216,1) 94%)",
            }}
        >

            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0  w-4/5 m-auto rounded-3xl pointer-events-none  " aria-hidden="true" />
            <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-theme-300 transform translate-y-1/2" />

            <div
                className="relative max-w-6xl mx-auto px-4 sm:px-6 "

            >
                <div className="py-12 md:py-20 border-t-2 border-theme-800 ">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 ">
                        <div
                            className="absolute top-2 -left-1/4 w-5/6 z-50 text-center items-center justify-center  "

                        >
                            <DoragonSticker
                                className="transform w-full z-20 opacity-40 linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8))"
                            />
                        </div>
                        <h2 className="roadmap__item h2 mb-4 text-4xl   tracking-widest text-theme-800 hover:text-theme-1000">Doragon FAQ</h2>
                        <p className="text-xl text-white roadmap__item ">
                            Commonly asked questions, answers, thoughts etc
                            <br />
                            {" "}
                            Our vision is a collobarative community effort
                            {" "}
                        </p>
                    </div>

                    {/* Items */}

                    <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

                        {/* 1st item */}
                        {faq.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    aria-hidden="true"
                                    role="button"
                                    tabIndex={0}
                                    className="relative textbg select-none flex flex-col items-center p-6 border border-theme-500 rounded shadow-xl bg-opacity z-50 bg-opacity-25 hover:bg-opacity-60"
                                    onClick={() => {
                                        return setFaq(faq.map((i) => {
                                            if (i.id === item.id) {
                                                // eslint-disable-next-line no-param-reassign
                                                i.clicked = !i.clicked;
                                            }
                                            return i;
                                        }));
                                    }}

                                >
                                    <h4 className="select-none feature-text text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-800 hover:text-theme-1000">{item.title}</h4>
                                    {item.clicked && (
                                        <>
                                            <p className="bg-none mt-3 text-theme-1000  hover:text-theme-1000 text-left font-press-start text-sm ">
                                                {item.content}
                                            </p>

                                            <p className="bg-none mt-3 text-theme-1000  hover:text-theme-1000 text-left font-press-start text-sm ">
                                                {item.content2}
                                            </p>
                                            <p className="bg-none mt-3 text-theme-1000  hover:text-theme-1000 text-left font-press-start text-sm ">
                                                {item.content3}
                                            </p>
                                            <p className="bg-none mt-3 text-theme-1000  hover:text-theme-1000 text-left font-press-start text-sm ">
                                                {item.content4}
                                            </p>
                                        </>
                                    )}
                                </div>
                            );
                        })}

                    </div>

                </div>
            </div>
        </section>
    );
}

export default FeaturesFAQ;

// title: "Gen 1 Aptos?",
// content: "We're choosing to pivot Generation 1 Doragons to launch on Aptos, because we are increasingly convinced of its improvements over Solana. Its speed, scalability and low fees make it a strong contender as a Layer 1 competitor.",

// content2: `Most importantly is Aptos improvements to the developer experience. Further Generations could be launched on Solana, or other Layer 1s, but we are remaining blockchain agnostic and will continue to evaluate the best options for the Doragon ecosystem.
