import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);
// serviceWorker.register();

// eslint-disable-next-line max-len
// "Damage","Die","Eat","Fire","Fly_Down","Fly","Fly_Fire","Fly_L","Fly_R","Fly_Up","Idle","Jump","No","Rest","Roar","Run","Run_L","Run_R","Sick","Walk","Walk_L","Walk_R","Yes",

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>,
// );

// reportWebVitals();
