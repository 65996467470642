/* eslint-disable max-len */
import React, {
    useEffect, useState, useRef, useLayoutEffect,
} from "react";
import gsap from "gsap/dist/gsap";
import { ScrollTrigger, } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin, } from "gsap/dist/ScrollToPlugin";

import { ReactComponent as DoragonStickerHorn, } from "../images/doragonsticker4-1.svg";
import { ReactComponent as DoragonStickerRed, } from "../images/doragonsticker5.svg";
import { ReactComponent as DoragonStickerYellow, } from "../images/doragonsticker7.svg";
import SeaOfClouds from "../components/seaOfClouds";

// import sunset from "../images/sunset.gif";
// import animeClouds from "../images/anime-clouds5.GIF";

const Roadmap = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const roadMapElementRef = useRef(null);
    const roadMapElementRef2 = useRef(null);
    const roadMapElementRef3 = useRef(null);

    const phase1Ref = useRef(null);
    const phase2Ref = useRef(null);

    const doragonStickerHornRef = useRef(null);
    const doragonStickerRedRef = useRef(null);
    const doragonStickerYellowRef = useRef(null);
    useEffect(() => {
        const roadMapElement = roadMapElementRef.current;
        const roadMapElement2 = roadMapElementRef2.current;
        const roadMapElement3 = roadMapElementRef3.current;

        const phase1 = phase1Ref.current;
        const phase2 = phase2Ref.current;

        const doragonStickerHorn = doragonStickerHornRef.current;
        const doragonStickerRed = doragonStickerRedRef.current;
        const doragonStickerYellow = doragonStickerYellowRef.current;
        // const element = elementRef.current;
        const listItems = gsap.utils.toArray(".roadmap__item");
        const dBoxes = gsap.utils.toArray(".down");
        const uBoxes = gsap.utils.toArray(".up");
        setTimeout(() => {
            // scrollTo requires the ScrollTo plugin (not to be confused w/ ScrollTrigger)
            // ScrollTrigger.refresh();

            listItems.forEach((item, index) => {
                gsap.set(item, { autoAlpha: 0, x: index % 2 ? 100 : -100, zIndex: 100 });
                gsap.to(item, {
                    x: 0,
                    zIndex: 100,
                    autoAlpha: 1,
                    // ease: "power2.inOut",
                    scrollTrigger: {
                        trigger: item,
                        start: "top 90%",
                        end: "bottom",
                        toggleActions: "play reverse play reverse",
                    },

                });
                gsap.from(item, {
                    // duration: 4,
                    // y: 20,
                    // autoAlpha: 0,
                    // stagger: 10,
                    x: 0,
                    autoAlpha: 1,
                    ease: "back.out(2)",
                });
            });

            gsap.set(".section__item", { opacity: 0, x: 100 });
            gsap.to(".section__item", {
                x: 0,
                opacity: 1,
                // ease: "power2.inOut",
                scrollTrigger: {
                    trigger: ".section__item",
                    start: "top 90%",
                    end: "bottom",
                    toggleActions: "play reverse play reverse",
                    // markers: true,

                },
            });
            // gsap.set(".section__item", { opacity: 1, y: 0 });

            // gsap.set(phase1, { x: 0, zIndex: 100, opacity: 0 });
            // gsap.to(phase1, {
            //     x: -100,
            //     zIndex: 100,
            //     opacity: 1,
            //     // ease: "power2.inOut",
            //     // scrollTrigger: {
            //     //     trigger: phase1,
            //     //     start: "top 90%",
            //     //     // end: "bottom 60%",
            //     //     toggleActions: "play reverse play reverse",
            //     //     markers: true,
            //     //     // scrub: 1,
            //     // },
            //     scrollTrigger: {
            //         trigger: roadMapElement,
            //         start: "top 10%",
            //         end: "bottom bottom",
            //         toggleActions: "play reverse play reverse",
            //         markers: true,
            //     },

            // });

            // gsap.set(phase2, { x: 0, zIndex: 100 });
            // gsap.to(phase2, {
            //     x: "125%",
            //     zIndex: 100,
            //     // ease: "power2.inOut",
            //     scrollTrigger: {
            //         trigger: roadMapElement,
            //         start: "top top",
            //         // end: "bottom 60%",
            //         toggleActions: "play reverse play reverse",
            //         markers: true,
            //         // scrub: 1,
            //     },

            // });

            // gsap.set(".section__item", { opacity: 0, x: 100 });
            // gsap.to(".section__item", {
            //     x: 0,
            //     opacity: 1,
            //     // ease: "power2.inOut",
            //     scrollTrigger: {
            //         trigger: ".section__item",
            //         start: "top 90%",
            //         end: "bottom",
            //         toggleActions: "play reverse play reverse",
            //         // markers: true,

            //     },
            // });
            // listItems.forEach((item, index) => {
            gsap.set(doragonStickerRed, { y: 250, opacity: 0.5 });
            gsap.to(doragonStickerRed, {
                y: -250,
                zIndex: 40,
                opacity: 0.6,
                // ease: "power2.inOut",
                scrollTrigger: {
                    trigger: roadMapElement,
                    start: "top 40%",
                    end: "bottom 70%",
                    toggleActions: "play reverse play reverse",
                    // markers: true,
                    scrub: 1,
                },

            });

            gsap.set(doragonStickerHorn, { x: 250, opacity: 0.3, zIndex: 20 });
            gsap.to(doragonStickerHorn, {
                x: -850,
                zIndex: 20,
                opacity: 0.6,
                // ease: "power2.inOut",
                scrollTrigger: {
                    trigger: roadMapElement,
                    // start: "top bottom",
                    // end: "bottom 60%",
                    start: "15% 55%",
                    end: "bottom 90%",
                    toggleActions: "play reverse play reverse",
                    // markers: { startColor: "blue", endColor: "blue" },
                    scrub: 1,
                },

            });

            gsap.set(doragonStickerYellow, { x: -250, opacity: 0, zIndex: 1 });
            gsap.to(doragonStickerYellow, {
                x: 350,
                zIndex: 1,
                opacity: 0.7,
                // ease: "power2.inOut",
                scrollTrigger: {
                    autoAlpha: 0,
                    trigger: roadMapElement,
                    // start: "bottom 15%",
                    // end: "bottom 90%",
                    start: "75% 55%",
                    end: "bottom 90%",
                    toggleActions: "play reverse play reverse",
                    // markers: true,
                    scrub: 1,
                },

            });
            // gsap.set(".section__item", { opacity: 1, y: 0 });

            // videoItems.forEach((item, index) => {
            //     gsap.set(item, { opacity: 0, x: 300 });
            //     gsap.to(item, {
            //         x: 0,
            //         opacity: 1,
            //         scrollTrigger: {
            //             trigger: item,
            //             start: "top 90%",
            //             end: "bottom",
            //             toggleActions: "play reverse play reverse",

            //         },
            //     });
            // });

        // const dBox = dBoxes.forEach((e) => {
        //     gsap.to(e, {
        //         yPercent: 50,
        //         opacity: 0,
        //         lazy: false,
        //         scrollTrigger: {
        //             containerAnimation: scrollTween,
        //             trigger: e,
        //             start: "left 50%",
        //             end: "left 30%",
        //             scrub: true,
        //         },
        // });
        // });
        }, 500);
        ScrollTrigger.refresh();
        // tl.kill();
        return () => {
            // Let's clear instances
            // tl.kill();
            ScrollTrigger.refresh();
        // t1.ScrollTrigger.kill();
        // ScrollTrigger.kill();
        // t1.scrollTrigger.kill();
        // window.removeEventListener("refresh", scroll.update());
        };
    }, [ ]);

    const [ tab, setTab ] = useState(1);
    return (
        <>
            {/* <div className="row dark-blue">
                <div>
                    <svg id="" preserveAspectRatio="xMidYMax meet" className="svg-separator sep11" viewBox="0 0 1600 100" style={{ display: "block" }} data-height="100">
                        <polygon className="" style={{ fill: " rgb(34, 49, 63)" }} points="-40,63.667 19.833,3.833 80,64 140,4 200,64 260,4 320,64 380,4 440,64 500,4 560,64 620,4 680,64 740,4 800,64 860,4 920,64 980,4 1040,64 1100,4 1160,64 1220,4 1280.333,64.333 1340.333,4.333 1400,64 1460,4 1520,64 1578,6 1636,64 1636,104 -40,104 " />
                        <polygon className="" style={{ opacity: " 1", fill: "#f89406" }} points="-40,86 20,26 80,86 140,26 200,76 260,4 200,64 140,4 80,64 19.833,3.833 -40,63.667 " />
                        <polygon className="" style={{ opacity: " 1", fill: "#eb9532" }} points="1159,69 1220,8 1281,73 1340,14 1399,73 1460,12 1521,73 1578,16 1634,72 1636,73.333 1636,64 1578,6 1520,64 1460,4 1400,64 1340.333,4.333 1280.333,64.333 1220,4 1160,64 1100,4 1040,64 1100,10 " />

                    </svg>
                </div>
            </div>
            <div className="row">
                <div>
                    <svg id="" preserveAspectRatio="xMidYMax meet" className="svg-separator sep12" viewBox="0 0 1600 200" style={{ display: " block" }} data-height="200">
                        <polygon className="" style={{ fill: " rgb(255, 255, 255)" }} points="-4,24 800,198 1604,24 1604,204 -4,204 " />
                        <polygon className="" style={{ opacity: " 1", fill: "#95a5a6" }} points="-4,0 800,198 1604,0 1604,11.833 800,198 -4,12 " />
                        <polygon className="" style={{ opacity: " 1", fill: "#d2d7d3" }} points="-4,12 -4,24 800,198 1604,24 1604,11.833 800,198 " />

                    </svg>
                </div>
            </div> */}
            {/* <div className="row stupid-blue">
                <div>
                    <svg id="" preserveAspectRatio="xMidYMax meet" className="svg-separator sep10" viewBox="0 0 1600 200" style={{ display: "block" }} data-height="200">
                        <path className="" style={{ opacity: " 1", fill: "#2574a9" }} d="M-16,129.803C28.268,129.803,43.874,86,74.839,86 c26.605,0,15.874,35.884-0.894,27.723c8.831,0,8.943-12.52,0.894-12.52c-12.967,0-9.167,38.455,26.829,38.455s1.409,0,1.409,0 v16.097H-16V129.803z" />
                        <path className="" style={{ opacity: " 1", fill: "#1e8bc3" }} d="M-114,123.448C-17.538,133.448,16.468,38,83.943,38 c57.974,0,34.59,78.192-1.949,60.41c19.244,0,19.487-27.282,1.949-27.282c-28.256,0-19.974,83.795,58.462,83.795s3.071,0,3.071,0 V180H-114V123.448z" />
                        <path className="" style={{ opacity: " 1", fill: "#1e8bc3" }} d="M158,172.749C238.596,172.749,267.01,93,323.386,93 c48.439,0,28.901,65.332-1.628,50.474c16.079,0,16.282-22.795,1.628-22.795c-23.609,0-16.689,70.013,48.846,70.013s2.566,0,2.566,0 L158,192V172.749z" />
                        <path className="" style={{ opacity: " 1", fill: "#2574a9" }} d="M156,160.626c53.515,0,72.381-52.953,109.815-52.953 c32.163,0,19.19,43.38-1.081,33.514c10.676,0,10.811-15.136,1.081-15.136c-15.676,0-11.081,46.488,32.433,46.488s1.704,0,1.704,0 V192H156V160.626z" />
                        <path className="" style={{ opacity: " 1", fill: "#2574a9" }} d="M-108.04,114.107c68.046,0,92.035-67.331,139.634-67.331 c40.897,0,24.4,55.159-1.375,42.615c13.575,0,13.747-19.245,1.375-19.245c-19.933,0-14.09,59.111,41.24,59.111s2.166,0,2.166,0V154 h-183.04V114.107z" />
                        <path className="" style={{ fill: " rgb(25, 181, 254)" }} d="M-4,142.333C121.654,142.333,165.952,18,253.846,18 c75.519,0,45.058,101.856-2.538,78.692c25.067,0,25.385-35.538,2.538-35.538c-36.808,0-26.019,109.154,76.154,109.154 s1276,0,1276,0V216H-4V142.333z" />

                    </svg>
                </div>
            </div> */}

            <div className=" max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start mt-10 hidden relative">
                <h1 className="absolute bottom-0 h2 sm:text-4xl text-3xl mb-4 text-theme-1000 tracking-widest">
                    赤ちゃんドラゴン
                </h1>
            </div>

            <div className=" -mb-32 pb-96 relative overflow-hidden" id="roadmap">
                {/* <div className="custom-shape-divider-top-1665191897">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill" />
                    </svg>
                </div> */}
                <div
                    className="absolute -top-10 w-full overflow-hidden sm:overflow-visible"

                >
                    {/* uncomment this */}
                    <DoragonStickerRed
                        ref={doragonStickerRedRef}
                        className=" absolute z-10 -top-3/4 opacity-60 -right-3/4 "
                        style={{
                            WebkitTransform: "scale(1)",
                            width: "250%",

                        }}
                    />
                </div>
                {/*  <div
                className="absolute -top-10 w-full"

                -speed="-16"
                // -position="bottom"
                -direction="vertical"
                lerp="1"
            >
                <DoragonStickerRed
                    className=" w-full z-30 opacity-40 linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8))"
                />
            </div> */}
                <div className="bg-theme-100 z-10 -mt-10" ref={roadMapElementRef}>
                    <div
                        className=" absolute border-theme-800  h-full border-2 pb-80 "
                        // data-aos="zoom-y-out"
                        // data-aos-delay="800"
                        style={{
                            left: "50%",
                            top: -20,
                            zIndex: -1,
                        }}
                    />

                    <div
                        className=" py-40 bg-theme-100 "
                        // style={{
                        //     background:
                        //     "linear-gradient(0deg, rgba(45,166,241,1) 29%, rgba(66,226,184,0.8169642857142857) 50%, rgba(45,166,241,1) 67%, rgba(40,149,216,1) 94%)",
                        // }}
                    >

                        <div className="container mx-auto w-full  relative">

                            <div className="relative wrap p-20 pt-64 ">
                                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start mt-10 sm:flex hidden">
                                    <h1 className="h2 text-sm mb-4 text-theme-1000 tracking-widest absolute">
                                        Powered by the community
                                    </h1>
                                </div>
                                <div
                                    className="max-w-3xl z-50 mx-auto text-center pb-12 md:pb-20 pt-20 border-b-2 border-t-2 border-theme-800 bg-theme-100  rounded-lg "

                                >
                                    <div
                                        className="w-full relative border-theme-800 bg-theme-100 hover:bg-theme-300 bg-opacity-10 hover:bg-opacity-10 z-50  rounded-lg items-center justify-center flex flex-col"
                                        style={{
                                            // boxShadow: "0 0 0 2px #f9bf23ff, rgba(66, 226, 184, 0.4) 0 0 0 5px, rgba(66, 226, 184, 0.3) 0 0 0 10px, rgba(66, 226, 184, 0.2) -15px 15px, rgba(66, 226, 184, 0.1) -20px 20px, rgba(66, 226, 184, 0.05) -25px 25px, rgba(198, 237, 254, 0.17) 0px -23px 25px 0px inset, rgba(198, 237, 254, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset",
                                        }}
                                    >

                                        <h2 className="h2 mb-4 sm:text-4xl text-2xl tracking-widest text-theme-800 hover:text-theme-900 rounded-lg bg-opacity-10 roadmap__item ">Doragon Tokenomics</h2>

                                        {/*
                                        <p className="pt-5 cursor-pointer  text-theme-600 hover:text-theme-1000 sm:text-xl text-sm relative " style={{ zIndex: "100" }}>
                                            <a href="https://discord.com/channels/958093662900867102/958093663198675020/959815820018589796">
                                                Why?
                                            </a>

                                        </p> */}
                                        {/* <p className="pt-5 cursor-pointer relative" style={{ zIndex: "100" }}> */}
                                        <p className=" roadmap__item sm:text-md p-3 text-sm text-white  font-press-start">
                                            {/* <a href="https://akachandoragon.gitbook.io/doragon-litepaper/overview/doragon-universe" target="_blank" rel="noreferrer"> */}
                                               The Art and Science of Token Distribution and Utilization
                                            {/* </a> */}
                                        </p>
                                        <p className="roadmap__item sm:text-md p-3 text-sm text-white w-9/12 text-center  font-press-start" style={{ fontSize: "8px" }}>
                                            Disclaimer:
                                           The Tokens are functional utility tokens designed for use only on the Company’s business platform that is yet to be developed. The Tokens are not securities. In the event that you purchase Tokens, your purchase cannot be refunded or exchanged. The Company does not recommend purchasing Tokens for speculative investment purposes. Tokens do not entitle you to any equity, governance, voting or similar right or entitlement in the Company or in any of its affiliated companies. Tokens are sold as digital products, similar to downloadable software, digital music and the like. The Company does not recommend that you purchase Tokens even if you have prior experience with cryptographic tokens, blockchain-based software and distributed ledger technology and you have taken independent professional advice.

 

                                        </p>
                                    </div>
                                </div>
                                <div
                                    className=" absolute border-theme-800 h-full border-2 "
                                    style={{
                                        left: "50%",
                                        opacity: 0.5,
                                        // zIndex: 10,
                                    }}
                                />

                                <div className="flex flex-col">
                                    {/* right timeline */}
                                    <div
                                        className="absolute right-44 w-5/6 overflow-hidden sm:overflow-visible"
                                    >
                                        <div
                                            className=" absolute max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start mt-10"
                                            style={{
                                                top: "22%",
                                                right: "20%",
                                                width: "53%",
                                            }}
                                        >
                                            <h1 className="h2  text-3xl mb-4 text-theme-700 tracking-widest absolute">
                                                強さのドラゴン

                                            </h1>
                                        </div>
                                        {/* <DoragonStickerHorn
                                            ref={doragonStickerHornRef}
                                            className="DoragonStickerHorn absolute z-10 linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8))"
                                            style={{
                                                WebkitTransform: "scale(1.5)",
                                                width: "120%",
                                                position: "absolute",
                                                top: "30%",
                                                // left: "-5%",
                                                left: "60%",
                                            }}
                                        /> */}
                                        <div
                                            className="absolute max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start mt-10"
                                            style={{
                                                top: " 69%",
                                                right: "-92%",
                                                width: " 100%",
                                            }}
                                        >

                                            <h1 className="h2 text-3xl mb-4 text-theme-1000 tracking-widest absolute">
                                                強力なドラゴン

                                            </h1>
                                        </div>
                                    </div>
                                    {/* <SeaOfClouds
                                        className="absolute w-full z-10 top-0"
                                        style={{
                                            height: "400%",
                                            top: "0",
                                            left: "0",
                                            zIndex: "10",
                                        }}
                                    /> */}

                                    <div className=" hover:shadow-lg">
                                        <div className=" mb-56 pt-56 flex justify-between items-center w-full right-timeline" data-aos="zoom-y-out" data-aos-delay="300">
                                            {/* <div className="order-1 w-3/12 sm:w-5/12" /> */}
                                            {/* <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800">
                                                <p className="mx-auto font-semibold text-lg text-black">1</p>
                                            </div> */}
                                            <div
                                                className=" order-1 bg-theme-100 sm:bg-opacity-50 bg-opacity-100 relative z-100 hover:bg-theme-200 hover:bg-opacity-40 transition duration-300 ease-in-out mb-3 border border-theme-700 z-50 hover:border-theme-1000 rounded-lg shadow-xl  sm:px-6 sm:py-4 w-full sm:p-20 p-5 "
                                                ref={phase1Ref}
                                                style={{
                                                    zIndex: "60",
                                                    // boxShadow: "0.3rem 0.5rem 0.4rem 0.1rem #E85D04",
                                                    boxShadow: "0 0 0 2px #f9bf23ff, rgba(243, 223, 191, 0.4) 0 0 0 5px, rgba(243, 223, 191, 0.3) 0 0 0 10px, rgba(243, 223, 191, 0.2)-15px 15px, rgba(243, 223, 191, 0.1) -20px 20px, rgba(243, 223, 191, 0.05) -25px 25px, rgba(243, 223, 191, 0.17) 0px -23px 25px 0px inset, rgba(243, 223, 191, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset",
                                                }}
                                            >
                                                <p
                                                    className="mb-3 font-bold text-theme-800 hover:text-theme-900 sm:text-3xl text-xl leading-tighter tracking-widest"
                                                >
                                                    Tokenomics Outline
                                                    {" "}

                                                </p>
                                                <div className=" text-sm leading-snug font-mono tracking-wide  hover:text-theme-900 ">
                                                    {/* <p className="hover:text-theme-900  roadmap__item font-bold  text-theme-800 sm:text-lg text-sm tracking-wider font-poppins">⊙ Begin Generation 1 Doragons</p> */}
                                                    <br />
                                                    <p className=" roadmap__item  hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">
                                                        In the realm of cryptocurrencies, crafting a well-defined tokenomics structure is crucial for ensuring the smooth operation and long-term success of your project. This involves carefully considering how the tokens will utilized within the ecosystem. Let's delve into a detailed breakdown based on a hypothetical scenario
                                                    </p>
                                                    <br />
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins text-theme-800 sm:text-lg text-sm">The Overall Picture</p>
                                                    <br />
                                                    <p
                                                        className="roadmap__item list__item-roadmap  hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400"
                                                    >
                                                        
Imagine a project with a total token supply of 100 billion. To ensure a thriving in-platform economy, 100% of the tokens (100 billion) are allocated to the liquidity pool (LP), providing ample liquidity for seamless trading.
                                                    </p>
                                                    <br />
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins text-theme-800 sm:text-lg text-sm">
                                                        ⊙ Diving into the Tokenomics Details: Liquidity Pool (LP)

                                                    </p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">

                                                    - <strong>Purpose:</strong> This vast pool of tokens ensures smooth and uninterrupted trading on exchanges.
<br />
- <strong>Management:</strong> To prevent rug pulls and bolster investor confidence, the entire LP is burned, permanently removing it from circulation.
<br />
- <strong>100% Burn Policy:</strong> Committing to burn the entire LP token supply.


                                                    </p>
                                                    <br />
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins text-theme-800 sm:text-lg text-sm">⊙ Community-Centric Approach</p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">
                                                        - <strong>Commitment to the Community:</strong> At the core of our project lies a deep commitment to our community. We believe that the true value of cryptocurrency is not just in its market potential but in the strength and engagement of its user base.
 <br />
- <strong>No Team Allocation:</strong>  To demonstrate this commitment, we will not allocate ANY tokens to our team. This decision underscores our focus on community interests over individual gain. By not reserving any tokens for ourselves, we aim to foster a sense of trust and equality within the community..
 <br />
- <strong>Empowering Users:</strong>  By refraining from team allocation, we empower our users, giving them full ownership and control over the project's direction. This is our way of putting the community in the driver's seat, making them the primary beneficiaries of the project's success.
                                                        
                                                        </p>
                                                    <br />
                                                    {/*   <p className="hover:text-theme-900  roadmap__item font-bold font-poppins text-theme-800 sm:text-lg text-sm">⊙ Connect Doragons to the Doragon Universe</p>
                                                <br />
                                                                                          <br />
                                                <p classNam1100hover:text-theme-900 font-press-start  sm:text-sm text-xs text-theme-800">⊙ Matrica holder verification - begin the verification process for our holders to establish our DAO</p>
                                                <br />
                                                <br /> */}
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins text-theme-800 sm:text-lg text-sm">⊙ Additional Considerations:</p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">
- <strong>Non-Security Nature:</strong> These tokens do not represent shares, equity, or similar interests. They are not securities and should not be treated as such. 
 <br />
- <strong>Compliance and Regulation:</strong>  Adhering to evolving regulatory standards and ensuring the tokenomics model complies with international cryptocurrency regulations. This includes transparent reporting to build trust and legitimacy in the wider financial community and a clear communication strategy to ensure there is no expectation of profit from the holding or trading of these tokens, aligning with certain regulatory frameworks like those designed to avoid the classification of the tokens as securities. We explicitly state that the purchase, holding, or trading of these tokens does not guarantee any profit. They are not designed as investment vehicles.


                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* left timeline */}
                                        {/* <div className="mb-56 flex justify-between flex-row-reverse items-center w-full left-timeline" data-aos="zoom-y-out" data-aos-delay="300">
                                            <div className="order-1 w-3/12 sm:w-5/12" />
                                            <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800">
                                                <p className="mx-auto text-black font-semibold sm:text-lg text-base">2</p>
                                            </div>
                                            <div
                                                className=" order-1 bg-theme-100 sm:bg-opacity-50 bg-opacity-100 relative z-100 hover:bg-theme-500 hover:bg-opacity-40 transition duration-300 ease-in-out mb-3 border border-theme-700 z-50 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full sm:p-8 p-5 "
                                                ref={phase2Ref}
                                                style={{
                                                    zIndex: "60",
                                                    // boxShadow: "0 0 0 2px #f9bf23ff, rgba(66, 226, 184, 0.4) 0 0 0 5px, rgba(66, 226, 184, 0.3) 0 0 0 10px, rgba(66, 226, 184, 0.2) -15px 15px, rgba(66, 226, 184, 0.1) -20px 20px, rgba(66, 226, 184, 0.05) -25px 25px, rgba(198, 237, 254, 0.17) 0px -23px 25px 0px inset, rgba(198, 237, 254, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset",
                                                    boxShadow: "0 0 0 2px #f9bf23ff, rgba(243, 223, 191, 0.4) 0 0 0 5px, rgba(243, 223, 191, 0.3) 0 0 0 10px, rgba(243, 223, 191, 0.2)-15px 15px, rgba(243, 223, 191, 0.1) -20px 20px, rgba(243, 223, 191, 0.05) -25px 25px, rgba(243, 223, 191, 0.17) 0px -23px 25px 0px inset, rgba(243, 223, 191, 0.15) 0px -36px 30px 0px inset, rgba(198, 237, 254, 0.1) 0px -79px 40px 0px inset",
                                                    // boxShadow: "-0.3rem 0.3rem 0.4rem 0.1rem #E85D04",
                                                    // boxShadow: "0.3rem 0.5rem 0.4rem 0.1rem #E85D04",
                                                }}
                                            > */}

                                                {/* <p
                                                    className="mb-3 font-bold text-theme-800 hover:text-theme-900 sm:text-3xl text-xl leading-tighter tracking-widest"

                                                >
                                                    Phase 2
                                                    {" "}

                                                </p>
                                                <div className=" sm:text-sm text-xs leading-snug font-mono tracking-wide text-white hover:text-theme-900 ">
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins text-theme-800 sm:text-lg text-sm "> ⊙ Expand Doragon Utility. BUIDL real utility</p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400"> We are a team of builders, and we will provide a whole suite of tools which provide real value to the community, and other features which provide incentive to invest long term. Real utility aids to generate revenue beyond the `&lsquo;`hype&rsquo; and this innovation here will sustain us byeond the bear into the future!</p>
                                                    <br />
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins  text-theme-800 sm:text-lg text-sm">⊙ Live Gameplay! Alpha Testing</p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">We will be alpha testing our game mechanics, with paid testers from our community.  Perhaps the mysterious power of Kami will be revealed here. But we will show off our main functional MVP which will user in the Destiny of Ryujin and how it will be implemented in the Doragon Universe</p>
                                                    <br />

                                                    <p
                                                        className="hover:text-theme-900 font-bold font-poppins  text-theme-800 sm:text-lg text-sm"
                                                        ref={roadMapElementRef3}
                                                    >
                                                        ⊙ Tokenomics
                                                        {" "}

                                                    </p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">We will be working on our tokenomics to ensure that we are providing the best value to our holders. We will outline deflationary pressure / customized bonding curve that aims to balance our player-owned ecosystem. We will be tighening up our econmics model, which we expect to finish over the coming quarter</p>

                                                    <br />
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins  text-theme-800 sm:text-lg text-sm">⊙ Staking</p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">An old but true meta. If the DAO accepts our staking propasl, we complete our staking system which will allow holders to stake their tokens and earn rewards. Staking system is dependant on votes from the DAO</p>
                                                    <br />
                                                    <p className="hover:text-theme-900  roadmap__item font-bold font-poppins  text-theme-800 sm:text-lg text-sm">⊙ Marketing</p>
                                                    <br />
                                                    <p className="list__item list__item-roadmap roadmap__item hover:text-theme-450 hover:border hover:border-theme-800  hover:bg-theme-200 rounded-lg hover:bg-opacity-90 bg-opacity-70 w-full px-10 py-2 leading-loose font-press-start sm:text-sm text-xs  text-theme-400">
                                                        Without an active social presence, we&apos;re DOA, so, we need to expose our brand with a targeted B2C brand awareness campaign. Expect loyalty incentives for you, the early investors! (Airdrops, events, raffles, etc)

                                                    </p>
                                                    <br />
                                                </div>
                                            */}
                                            {/* </div>
                                        </div>  */}
                                    </div>

                                    {/* right timeline */}
                                    {/* <div className="mb-56 flex justify-between items-center w-full right-timeline" data-aos="zoom-y-out" data-aos-delay="300">
                                        <div className="order-1 w-3/12 sm:w-5/12" />
                                        <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800 ">
                                            <p className="mx-auto font-semibold text-lg text-black">3</p>
                                        </div>
                                        <div className="order-1 bg-black hover:bg-theme-300  md:bg-opacity-20 opacity-100 hover:bg-opacity-70  transition duration-300 ease-in-out mb-3 border  z-50 border-theme-700 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full p-8 ">
                                            <h3 className="mb-3 font-bold text-theme-900 text-xl leading-tighter tracking-widest hover:opacity-80">Phase 3</h3>
                                            <p className="text-sm leading-snug tracking-wide text-white hover:text-theme-1000  text-opacity-100">
                                                <a href="https://akachandoragon.gitbook.io/doragon-litepaper/overview/doragon-universe" target="_blank" rel="noreferrer" className="hover:text-theme-1000 font-bold font-poppins text-theme-800 sm:text-lg text-sm">
                                                    Click here to read our litepaper to stay up to date with our latest developments
                                                </a>
                                            </p>
                                        </div>
                                    </div> */}

                                    <div
                                        className="absolute -left-2/4 w-full z-50 text-center items-center justify-center"
                                        style={{
                                            bottom: "-15%",
                                        }}
                                    >
                                        <div
                                            className=" absolute max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start mt-10"
                                            style={{
                                                top: "22%",
                                                right: "-53%",
                                                width: "53%",
                                            }}
                                        >
                                            <h1 className="h2 text-4xl mb-4 text-theme-900 tracking-widest absolute">
                                                強さのドラゴン

                                            </h1>
                                        </div>

                                        <div
                                            className=" absolute max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start mt-10"
                                            style={{
                                                top: "65%",
                                                right: "5%",
                                                width: "53%",
                                            }}
                                        >
                                            <h1 className="h2 text-3xl mb-4 text-theme-700 tracking-widest absolute">
                                                強力なドラゴン

                                            </h1>
                                        </div>
                                        <DoragonStickerYellow
                                            ref={doragonStickerYellowRef}
                                            className="DoragonStickerYellow relative w-5/6 z-10 opacity-60 linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8))"
                                            style={{
                                                // filter: "blur(1.5px)",
                                                // WebkitFilter: "blur(1.5px)",
                                                // MozFilter: "blur(1.5px)",
                                                // OFilter: "blur(1.5px)",
                                                // MsFilter: "blur(1.5px)",
                                                zIndex: "5",
                                                WebkitTransform: "scale(1.3)",
                                                
                                            }}
                                        />

                                    </div>

                                    {/* left timeline
                                <div className="mb-56 pb-56 flex justify-between flex-row-reverse items-center w-full left-timeline " data-aos="zoom-y-out" data-aos-delay="300">
                                    <div className="order-1 w-3/12 sm:w-5/12" />
                                    <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800">
                                        <p className="mx-auto text-black font-semibold text-lg">4</p>
                                    </div>
                                    <div className="order-1 bg-black hover:bg-theme-300  md:bg-opacity-20 opacity-100 hover:bg-opacity-70  transition duration-300 ease-in-out mb-3 border  z-50 border-theme-700 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full p-8 ">
                                        <h3 className="mb-3 font-bold text-theme-900 text-xl leading-tighter tracking-widest">Phase 4</h3>
                                        <p className="text-sm font-medium leading-snug tracking-wide text-white hover:text-theme-1000  text-opacity-100">
                                            Locked
                                        </p>
                                    </div>
                                </div>
                                */}
                                </div>
                            </div>
                        </div>
                        {/* <div
                            className="absolute -bottom-10 w-full z-10 bg-gradient-to-t from-theme-1000"
                            style={{
                                height: "80%",
                                // width: "200%",
                            }}
                        > */}
                            <SeaOfClouds
                                className="absolute w-full z-0"
                                style={{
                                    height: "100%",
                                    bottom: "0",
                                    left: "0",
                                    width: "100%",
                                }}
                            />
                        {/* </div> */}
                    </div>
                </div>

            </div>
        </>
    );
};

export default Roadmap;
