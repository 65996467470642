import React from "react";

const Animeload = () => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
                margin: "auto",
                background: "transparent",
                display: "block",
                zIndex: 1,
                position: "relative",
                shapeRendering: "auto",
                width: "100%",
                height: "100%",
            }}
            // width="1920"
            // height="979"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 1920 979"
        >
            <g transform="translate(960,489.5) scale(1,1) translate(-960,-489.5)">
                <g transform="translate(960,489.5)">
                    {" "}
                    <g>
                        <g style={{ transform: "rotate(267.47843453987804deg)" }}>
                            <path className="ld ld-speed-dash" d="M294.6592960861292 0 L2335.4586658218946 13.10173846871265L2335.4586658218946 -13.10173846871265z" fill="#000000" style={{ animationDelay: "-0.7748891608920148s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(217.8969659263641deg)" }}>
                            <path className="ld ld-speed-dash" d="M294.6466721919615 0 L3737.7641377446994 21.940171235720424L3737.7641377446994 -21.940171235720424z" fill="#000000" style={{ animationDelay: "-0.8324089961989913s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(324.62523231892465deg)" }}>
                            <path className="ld ld-speed-dash" d="M369.9573932127406 0 L3862.507869109379 11.400061772402221L3862.507869109379 -11.400061772402221z" fill="#d97171" style={{ animationDelay: "-0.9864754383582661s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(77.44736142960338deg)" }}>
                            <path className="ld ld-speed-dash" d="M146.16863624858405 0 L2513.1958569625754 22.142056696323937L2513.1958569625754 -22.142056696323937z" fill="#000000" style={{ animationDelay: "-1.1349911878131946s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(148.51069978551115deg)" }}>
                            <path className="ld ld-speed-dash" d="M254.8612981889127 0 L3817.7136952406913 20.84266860318872L3817.7136952406913 -20.84266860318872z" fill="#000000" style={{ animationDelay: "-1.6553386284101461s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(179.14273205300827deg)" }}>
                            <path className="ld ld-speed-dash" d="M280.7355842395303 0 L4120.524930376195 11.786197000911521L4120.524930376195 -11.786197000911521z" fill="#d97171" style={{ animationDelay: "-1.7062506597200415s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(205.40550291569429deg)" }}>
                            <path className="ld ld-speed-dash" d="M228.30401739090965 0 L3886.3034986331004 13.208982094871281L3886.3034986331004 -13.208982094871281z" fill="#d97171" style={{ animationDelay: "-0.831844892981072s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(252.35216474879195deg)" }}>
                            <path className="ld ld-speed-dash" d="M317.47435799533184 0 L2846.0319938798502 18.876006635964643L2846.0319938798502 -18.876006635964643z" fill="#000000" style={{ animationDelay: "-0.8091847518369923s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(173.06460891939486deg)" }}>
                            <path className="ld ld-speed-dash" d="M202.0901679141464 0 L4028.31947062031 23.158320973536416L4028.31947062031 -23.158320973536416z" fill="#000000" style={{ animationDelay: "-1.651983138346527s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(153.09805850005338deg)" }}>
                            <path className="ld ld-speed-dash" d="M432.23568376894013 0 L4068.5697805682876 12.839333943657794L4068.5697805682876 -12.839333943657794z" fill="#000000" style={{ animationDelay: "-0.1661428225649586s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(344.7673397742064deg)" }}>
                            <path className="ld ld-speed-dash" d="M451.3208383337389 0 L4225.200311011055 29.96868782288623L4225.200311011055 -29.96868782288623z" fill="#000000" style={{ animationDelay: "-0.9718716608839792s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(157.83515955569413deg)" }}>
                            <path className="ld ld-speed-dash" d="M390.1509143384703 0 L4091.075391131457 10.227131834646283L4091.075391131457 -10.227131834646283z" fill="#d97171" style={{ animationDelay: "-1.4489961176269115s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(91.54761133050285deg)" }}>
                            <path className="ld ld-speed-dash" d="M259.2825935167761 0 L2268.1109511042573 10.142029622977088L2268.1109511042573 -10.142029622977088z" fill="#d97171" style={{ animationDelay: "-0.5055581434115597s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(326.53993166308754deg)" }}>
                            <path className="ld ld-speed-dash" d="M408.0404848698325 0 L3936.1371629818937 11.757055133130514L3936.1371629818937 -11.757055133130514z" fill="#000000" style={{ animationDelay: "-1.3080344433077427s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(135.55336009246804deg)" }}>
                            <path className="ld ld-speed-dash" d="M420.96147003581376 0 L3722.5267336018865 21.360234509818877L3722.5267336018865 -21.360234509818877z" fill="#000000" style={{ animationDelay: "-0.20815284330864392s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(185.46782018292285deg)" }}>
                            <path className="ld ld-speed-dash" d="M242.82200124214592 0 L4074.258661357734 24.72964790966453L4074.258661357734 -24.72964790966453z" fill="#000000" style={{ animationDelay: "-1.6736567696136615s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(96.87203432608767deg)" }}>
                            <path className="ld ld-speed-dash" d="M297.11374247932633 0 L2480.299308121767 14.089011371277312L2480.299308121767 -14.089011371277312z" fill="#d97171" style={{ animationDelay: "-0.7537785845647176s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(322.08505203076254deg)" }}>
                            <path className="ld ld-speed-dash" d="M507.4483597556558 0 L3950.202944449382 16.881008784367232L3950.202944449382 -16.881008784367232z" fill="#000000" style={{ animationDelay: "-0.5067004874475964s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(30.240887683833265deg)" }}>
                            <path className="ld ld-speed-dash" d="M356.44992218323006 0 L3940.339108582014 24.4304390592831L3940.339108582014 -24.4304390592831z" fill="#000000" style={{ animationDelay: "-0.9550477825965529s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(177.45652187133516deg)" }}>
                            <path className="ld ld-speed-dash" d="M342.6385314474004 0 L4180.784448959219 14.359071677973123L4180.784448959219 -14.359071677973123z" fill="#d97171" style={{ animationDelay: "-1.9437288451545922s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(204.31013692529433deg)" }}>
                            <path className="ld ld-speed-dash" d="M537.3525315309312 0 L4210.4764499694265 11.697836988035295L4210.4764499694265 -11.697836988035295z" fill="#d97171" style={{ animationDelay: "-1.9942336347689196s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(26.21638032546029deg)" }}>
                            <path className="ld ld-speed-dash" d="M209.66447872904678 0 L3856.0671109340656 24.082075858828556L3856.0671109340656 -24.082075858828556z" fill="#d97171" style={{ animationDelay: "-1.0138364198777556s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(288.60282122577223deg)" }}>
                            <path className="ld ld-speed-dash" d="M282.3965404992509 0 L2840.765788692586 21.328241291097395L2840.765788692586 -21.328241291097395z" fill="#000000" style={{ animationDelay: "-1.7381617631177972s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(155.45269817229263deg)" }}>
                            <path className="ld ld-speed-dash" d="M462.8149506815324 0 L4132.717158555611 16.820867016124595L4132.717158555611 -16.820867016124595z" fill="#000000" style={{ animationDelay: "-0.7559807201222406s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(14.75930021359595deg)" }}>
                            <path className="ld ld-speed-dash" d="M553.332418815026 0 L4331.23507479575 26.33851332295521L4331.23507479575 -26.33851332295521z" fill="#d97171" style={{ animationDelay: "-1.7381608427302577s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(116.75822730041689deg)" }}>
                            <path className="ld ld-speed-dash" d="M312.92911013449265 0 L3118.255688679965 26.248013986553346L3118.255688679965 -26.248013986553346z" fill="#000000" style={{ animationDelay: "-1.207203336921535s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(146.86581355759685deg)" }}>
                            <path className="ld ld-speed-dash" d="M471.12566167245546 0 L4005.0987739995094 15.494563536955148L4005.0987739995094 -15.494563536955148z" fill="#000000" style={{ animationDelay: "-1.5570679496264948s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(162.44858281161405deg)" }}>
                            <path className="ld ld-speed-dash" d="M372.5903067881278 0 L4124.977021793629 13.024104042007947L4124.977021793629 -13.024104042007947z" fill="#d97171" style={{ animationDelay: "-0.24526721972803633s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(205.92119178958296deg)" }}>
                            <path className="ld ld-speed-dash" d="M516.2905534978262 0 L4166.954124884054 14.734659837522969L4166.954124884054 -14.734659837522969z" fill="#d97171" style={{ animationDelay: "-1.830822047580956s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(64.62182418963798deg)" }}>
                            <path className="ld ld-speed-dash" d="M293.15032473617606 0 L3057.758621175586 20.000832402244825L3057.758621175586 -20.000832402244825z" fill="#d97171" style={{ animationDelay: "-1.4074060277310019s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(131.67880541726205deg)" }}>
                            <path className="ld ld-speed-dash" d="M287.9849628725513 0 L3497.4286097395066 13.40155619766045L3497.4286097395066 -13.40155619766045z" fill="#000000" style={{ animationDelay: "-0.7797477673198019s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(140.193567833434deg)" }}>
                            <path className="ld ld-speed-dash" d="M380.53415483287046 0 L3784.3084960303095 10.957486496910317L3784.3084960303095 -10.957486496910317z" fill="#000000" style={{ animationDelay: "-1.3516717547940766s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(257.8423699111363deg)" }}>
                            <path className="ld ld-speed-dash" d="M158.92091691621602 0 L2513.2737776082813 14.028946534013977L2513.2737776082813 -14.028946534013977z" fill="#000000" style={{ animationDelay: "-1.1177696141401658s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(284.19947280908906deg)" }}>
                            <path className="ld ld-speed-dash" d="M183.76703029942283 0 L2603.418742918476 17.723033229682184L2603.418742918476 -17.723033229682184z" fill="#d97171" style={{ animationDelay: "-0.4943657218854769s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(108.75204541050742deg)" }}>
                            <path className="ld ld-speed-dash" d="M283.05699138526114 0 L2846.0696849551678 10.207459528882508L2846.0696849551678 -10.207459528882508z" fill="#000000" style={{ animationDelay: "-0.32637660377209166s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(273.9644916474781deg)" }}>
                            <path className="ld ld-speed-dash" d="M250.01582302289802 0 L2338.1339763511096 18.433223028097103L2338.1339763511096 -18.433223028097103z" fill="#000000" style={{ animationDelay: "-0.46498534359407717s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(285.91430943129006deg)" }}>
                            <path className="ld ld-speed-dash" d="M314.4726054132787 0 L2788.5158797040904 29.986712801162298L2788.5158797040904 -29.986712801162298z" fill="#000000" style={{ animationDelay: "-1.9749228418519973s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(127.5261359538176deg)" }}>
                            <path className="ld ld-speed-dash" d="M448.7389180347041 0 L3553.108894273707 24.47789447334121L3553.108894273707 -24.47789447334121z" fill="#000000" style={{ animationDelay: "-0.677270201897147s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(32.46317989108583deg)" }}>
                            <path className="ld ld-speed-dash" d="M290.7271105275296 0 L3836.6393119899235 28.596951151100455L3836.6393119899235 -28.596951151100455z" fill="#000000" style={{ animationDelay: "-0.596133220594206s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(8.795710753414108deg)" }}>
                            <path className="ld ld-speed-dash" d="M418.22625993127684 0 L4236.093622669024 22.669768892306887L4236.093622669024 -22.669768892306887z" fill="#000000" style={{ animationDelay: "-1.7762705917687742s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(304.56395463753364deg)" }}>
                            <path className="ld ld-speed-dash" d="M188.67456546457248 0 L3214.3817007125203 29.812663147896814L3214.3817007125203 -29.812663147896814z" fill="#d97171" style={{ animationDelay: "-0.27630275618079736s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(83.49691865593635deg)" }}>
                            <path className="ld ld-speed-dash" d="M254.58142656805921 0 L2425.7304371638998 14.58854837308817L2425.7304371638998 -14.58854837308817z" fill="#000000" style={{ animationDelay: "-0.7619628823060025s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(323.6493470164761deg)" }}>
                            <path className="ld ld-speed-dash" d="M368.28917937469964 0 L3842.060620410266 19.99066135549632L3842.060620410266 -19.99066135549632z" fill="#000000" style={{ animationDelay: "-1.6882479568625568s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(158.635584018671deg)" }}>
                            <path className="ld ld-speed-dash" d="M220.15811782742327 0 L3930.831304871366 13.569100597033326L3930.831304871366 -13.569100597033326z" fill="#d97171" style={{ animationDelay: "-1.9259120941001084s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(280.8754837857208deg)" }}>
                            <path className="ld ld-speed-dash" d="M146.7219079322771 0 L2459.80874103233 28.09849979766302L2459.80874103233 -28.09849979766302z" fill="#000000" style={{ animationDelay: "-1.6766193092934296s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(347.83971221105423deg)" }}>
                            <path className="ld ld-speed-dash" d="M203.5412996120495 0 L4001.313229369008 19.493728949919884L4001.313229369008 -19.493728949919884z" fill="#000000" style={{ animationDelay: "-1.74304835467399s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(248.95545796543615deg)" }}>
                            <path className="ld ld-speed-dash" d="M314.12271047405187 0 L2947.9368871672295 21.348027150106073L2947.9368871672295 -21.348027150106073z" fill="#d97171" style={{ animationDelay: "-0.7906504321311743s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(151.56812151343226deg)" }}>
                            <path className="ld ld-speed-dash" d="M334.3802315738545 0 L3947.380555904691 25.113956391005566L3947.380555904691 -25.113956391005566z" fill="#000000" style={{ animationDelay: "-0.6720678765501442s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(13.629859526683106deg)" }}>
                            <path className="ld ld-speed-dash" d="M377.5429443173979 0 L4164.542671920612 28.092121044846884L4164.542671920612 -28.092121044846884z" fill="#000000" style={{ animationDelay: "-0.6973111051000824s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(353.72605260602074deg)" }}>
                            <path className="ld ld-speed-dash" d="M563.2577009507154 0 L4391.98593113931 13.816078191631869L4391.98593113931 -13.816078191631869z" fill="#000000" style={{ animationDelay: "-1.6905522496698486s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(256.785918891375deg)" }}>
                            <path className="ld ld-speed-dash" d="M265.4025059328202 0 L2653.6091336530853 16.239601124325283L2653.6091336530853 -16.239601124325283z" fill="#000000" style={{ animationDelay: "-1.1251516972225795s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(149.93055942578607deg)" }}>
                            <path className="ld ld-speed-dash" d="M326.8885921837933 0 L3913.606744743033 17.764151996781376L3913.606744743033 -17.764151996781376z" fill="#000000" style={{ animationDelay: "-1.980479947936478s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(337.83952605063337deg)" }}>
                            <path className="ld ld-speed-dash" d="M526.8745863452801 0 L4227.853169027158 25.759317483325724L4227.853169027158 -25.759317483325724z" fill="#000000" style={{ animationDelay: "-1.5298036754048132s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(336.3961141457096deg)" }}>
                            <path className="ld ld-speed-dash" d="M205.02226844414196 0 L3887.5658213514653 22.472368460898583L3887.5658213514653 -22.472368460898583z" fill="#d97171" style={{ animationDelay: "-1.5764354103413938s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(336.49020848812256deg)" }}>
                            <path className="ld ld-speed-dash" d="M533.0861046631297 0 L4216.86489268775 26.838893394151633L4216.86489268775 -26.838893394151633z" fill="#d97171" style={{ animationDelay: "-1.6089975610823428s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(282.6037748246581deg)" }}>
                            <path className="ld ld-speed-dash" d="M215.07259735662424 0 L2583.7391826741523 26.64055455926899L2583.7391826741523 -26.64055455926899z" fill="#d97171" style={{ animationDelay: "-0.4414247312126154s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(56.15220339873753deg)" }}>
                            <path className="ld ld-speed-dash" d="M269.539839125747 0 L3275.7924517027295 26.358279167169016L3275.7924517027295 -26.358279167169016z" fill="#d97171" style={{ animationDelay: "-0.3606959831038905s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(270.8019055051196deg)" }}>
                            <path className="ld ld-speed-dash" d="M123.59981849483343 0 L2107.939226088096 10.80248554822251L2107.939226088096 -10.80248554822251z" fill="#000000" style={{ animationDelay: "-0.40231231442778315s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(244.65789349558247deg)" }}>
                            <path className="ld ld-speed-dash" d="M176.2948002535448 0 L2939.8324970998883 14.274100586807208L2939.8324970998883 -14.274100586807208z" fill="#000000" style={{ animationDelay: "-0.6661756641664041s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(36.35035974789612deg)" }}>
                            <path className="ld ld-speed-dash" d="M509.614846093269 0 L3983.3919962042232 27.311985974719477L3983.3919962042232 -27.311985974719477z" fill="#d97171" style={{ animationDelay: "-0.8485692204179016s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(140.45596363827383deg)" }}>
                            <path className="ld ld-speed-dash" d="M413.75291241551014 0 L3823.029883608826 25.647373469803796L3823.029883608826 -25.647373469803796z" fill="#000000" style={{ animationDelay: "-0.49362093376299887s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(191.7307570613577deg)" }}>
                            <path className="ld ld-speed-dash" d="M461.8009677565113 0 L4262.49315984348 17.384711223295184L4262.49315984348 -17.384711223295184z" fill="#d97171" style={{ animationDelay: "-1.7735762694497614s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(53.05549440817873deg)" }}>
                            <path className="ld ld-speed-dash" d="M455.7612448258599 0 L3544.9208104551844 20.85805020241546L3544.9208104551844 -20.85805020241546z" fill="#000000" style={{ animationDelay: "-0.9038767823707037s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(241.10703721986576deg)" }}>
                            <path className="ld ld-speed-dash" d="M266.2994561782833 0 L3133.6345290177214 22.469611998300504L3133.6345290177214 -22.469611998300504z" fill="#000000" style={{ animationDelay: "-0.32232471845211386s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(306.7365754741998deg)" }}>
                            <path className="ld ld-speed-dash" d="M336.0723921872979 0 L3419.765941323043 17.567587310822923L3419.765941323043 -17.567587310822923z" fill="#d97171" style={{ animationDelay: "-1.279312929029686s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(258.8669905359271deg)" }}>
                            <path className="ld ld-speed-dash" d="M335.3563154019032 0 L2656.7465775552664 14.562445286300974L2656.7465775552664 -14.562445286300974z" fill="#000000" style={{ animationDelay: "-1.6773396855062135s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(155.7086284353603deg)" }}>
                            <path className="ld ld-speed-dash" d="M495.0023753929544 0 L4168.379953578661 22.902598561737356L4168.379953578661 -22.902598561737356z" fill="#000000" style={{ animationDelay: "-0.43807393852933485s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(64.42858715406474deg)" }}>
                            <path className="ld ld-speed-dash" d="M221.20977769510864 0 L2991.5482304984953 15.947077699442286L2991.5482304984953 -15.947077699442286z" fill="#000000" style={{ animationDelay: "-1.8394880099263062s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(244.98475965671622deg)" }}>
                            <path className="ld ld-speed-dash" d="M158.74961548524337 0 L2912.5708545474695 26.255281993235823L2912.5708545474695 -26.255281993235823z" fill="#000000" style={{ animationDelay: "-1.154519632713078s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(6.108919305043656deg)" }}>
                            <path className="ld ld-speed-dash" d="M359.65468366167016 0 L4188.967539312993 27.74585387719993L4188.967539312993 -27.74585387719993z" fill="#d97171" style={{ animationDelay: "-0.29024522607247594s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(71.47102104449434deg)" }}>
                            <path className="ld ld-speed-dash" d="M247.65971725904856 0 L2803.729689345028 13.287640519426564L2803.729689345028 -13.287640519426564z" fill="#000000" style={{ animationDelay: "-0.7973897941932635s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(95.89935273871843deg)" }}>
                            <path className="ld ld-speed-dash" d="M123.50155737502652 0 L2274.9359635640612 26.13753460619971L2274.9359635640612 -26.13753460619971z" fill="#000000" style={{ animationDelay: "-1.0330694543697607s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(336.7508197303212deg)" }}>
                            <path className="ld ld-speed-dash" d="M523.1328688138422 0 L4210.308554915823 24.110640742581726L4210.308554915823 -24.110640742581726z" fill="#000000" style={{ animationDelay: "-1.6398383512223083s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(95.4530446367921deg)" }}>
                            <path className="ld ld-speed-dash" d="M124.98860532469787 0 L2261.8350044791164 18.73662286939816L2261.8350044791164 -18.73662286939816z" fill="#000000" style={{ animationDelay: "-0.026636951608884463s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(121.00996557349264deg)" }}>
                            <path className="ld ld-speed-dash" d="M294.55090795428947 0 L3222.133135548334 11.462542462554861L3222.133135548334 -11.462542462554861z" fill="#d97171" style={{ animationDelay: "-0.8358061256104148s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(77.49691908251356deg)" }}>
                            <path className="ld ld-speed-dash" d="M119.05081156832125 0 L2484.4889647799637 26.474556672179542L2484.4889647799637 -26.474556672179542z" fill="#d97171" style={{ animationDelay: "-1.343827126587497s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(347.56129047673903deg)" }}>
                            <path className="ld ld-speed-dash" d="M389.20537576038566 0 L4185.029149118291 22.413809148102217L4185.029149118291 -22.413809148102217z" fill="#d97171" style={{ animationDelay: "-0.8666812092688625s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(8.859321474951223deg)" }}>
                            <path className="ld ld-speed-dash" d="M470.35956850235266 0 L4287.906286849733 14.003247154017235L4287.906286849733 -14.003247154017235z" fill="#000000" style={{ animationDelay: "-0.5686499482208283s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(356.29606914604756deg)" }}>
                            <path className="ld ld-speed-dash" d="M213.4494519266294 0 L4049.518308867954 29.724958846894243L4049.518308867954 -29.724958846894243z" fill="#000000" style={{ animationDelay: "-1.0544882909806383s" }} />
                            /&gt;
                        </g>
                        <g style={{ transform: "rotate(327.09811657356977deg)" }}>
                            <path className="ld ld-speed-dash" d="M425.0837123765035 0 L3963.2146930404433 13.684881815047042L3963.2146930404433 -13.684881815047042z" fill="#d97171" style={{ animationDelay: "-1.2326936735315113s" }} />
                            /&gt;
                        </g>
                    </g>
                    {" "}

                </g>
            </g>
        </svg>
    );
};

export default Animeload;
