import React, { useRef, } from "react";
import { Link, } from "react-router-dom";

const Banner = () => {
    const bannerSlideRef = useRef(null);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {window.location.pathname === "/den" ? "" : (
                <div className="fixed h-24 bottom-0 left-0 w-8/12" style={{ zIndex: "90" }}>
                    <div className="grid grid-cols-1 h-24 w-full relative ">
                        {/* <img alt="Explore the Doragon Den" ref={bannerSlideRef} className="banner-slide lg:w-1/6 w-28 rounded-md lg:block absolute z-100 lg:bottom-2 lg:-right-20 bottom-3 -right-3  " src="/doragonlogo.png" /> */}
                        <a href="https://app.akachandoragon.com">
                            <div
                                className="fixed bg-theme-200 border border-theme-800 h-20 lg:bottom-8 lg:right-8 bottom-4 lg:px-0 px-4 xl:w-full lg:w-6/12 xl:max-w-2xl w-full"
                                style={{ opacity: "1", transform: "translate3d(0%, 0%, 0px)", fontSize: "10px !important" }}
                            >
                                <div
                                    className=" w-full text-white textbg rounded relative float-right lg:pr-30 pr-3 h-20 flex items-center shadow-me"
                                    style={{ fontSize: "10px !important" }}
                                >
                                    <img alt="Explore the Doragon Den" className="lg:w-2/6 w-28 rounded-md lg:block absolute z-100 lg:bottom-2 lg:-left-20 bottom-3 -left-3  " src="/doragonlogo.png" />
                                    <div className=" lg:block hidden lg:mr-4 mr-auto lg:ml-[9rem] ml-44 ">
                                        <p className=" text-3xs tracking-widest font-400 uppercase opacity-50">
                                            Explore the Den

                                        </p>
                                        <p className=" uppercase tabular-nums font-700 lg:text-lg text-sm">
                                            Doragon Den
                                            {" "}
                                            <span className="opacity-20">//</span>

                                        </p>
                                    </div>
                                    <div className=" lg:hidden block mr-4 mr-auto ml-[9rem] ml-28 ">
                                        <p className=" text-md tracking-widest font-400 uppercase opacity-50">
                                            Explore the Den
                                        </p>
                                        <p className=" uppercase tabular-nums font-700 text-sm">
                                            Doragon Den
                                            <span className="opacity-20">//</span>
                                        </p>
                                    </div>
                                    <div className="ml-auto">
                                        <a className="bg-theme-400 border-theme-800 lg:px-6 px-4 group flex shadow-me items-center justify-center lg:text-base text-2xs uppercase lg:h-12 h-12 duration-300 cursor-pointer font-600 transform border border-none text-gray-800 hover:text-black bg-white" href="https://app.akachandoragon.com">
                                            <span className="block lg:hidden text-theme-800 ">view</span>
                                            {" "}
                                            <span className="hidden lg:block text-theme-200">view</span>
                                            <span className=" text-theme-200 pl-4 duration-300 transform  translate-x-0 lg:group-hover:translate-x-2">
                                                <svg className="fill-current lg:h-4 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default Banner;
