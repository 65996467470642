import React from "react";

const Container = ({ className = "", children }) => {
    return (
        <div className={`flex flex-col sm:min-h-screen h-auto touch-none overflow-hidden w-full  z-50 justify-center items-center ${className}`}>
            {children}
        </div>
    );
};

export default Container;
