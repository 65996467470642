/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    useState, useEffect, useCallback, useRef,
} from "react";
// import Doragon from "../../images/doragonsticker7.svg";
import Doragon from "../../images/doragonfavimage.png";

const Levels = ({ playPauseButton }) => {
    return (
        <div className=" relative h-10 ml-4 mr-2">
            <div className={`${playPauseButton ? "icon-bars" : "bars"} h-10 `}>
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
            </div>
        </div>
    );
};

const MusicPlayer = () => {
    const [ open, setOpen ] = useState(false);
    const [ play, setPlay ] = useState(false);
    document.documentElement.classList.add("dark");
    const { Amplitude } = window;
    function loadPlayer() {
        Amplitude.init({
            bindings: {
                37: "prev",
                39: "next",
                32: "play_pause",
            },
            // debug: true,
            // callbacks: {
            //     timeupdate: function () {
            //         let percentage = Amplitude.getSongPlayedPercentage();

            //         if (Number.isNaN(percentage)) {
            //             percentage = 0;
            //         }

            //         /**
            //  * Massive Help from: https://nikitahl.com/style-range-input-css
            //  */
            //         const slider = document.getElementById("song-percentage-played");
            //         slider.style.backgroundSize = `${percentage}% 100%`;
            //     },
            // },
            songs: [
                {
                    name: "Aruarian Dance",
                    artist: "Nujabes",
                    // album: "Soon It Will Be Cold Enough",
                    url: "/audio/nujabes_aruarian_dance.mp3",
                    cover_art_url: "/images/ararian-dance.jpg",
                },
                {
                    name: "Howl's Moving Castle ( Lo-Fi Hip hop )",
                    artist: "Goga",
                    // album: "Die Digital",
                    url: "/audio/goga - howl's moving castle ( Lo-Fi Hip hop ).mp3",
                    // cover_art_url: "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg",
                },
                {
                    name: "Animal Crossing Theme Song (OFFICIAL TRAP REMIX)",
                    artist: "TH3 DARP",
                    album: "We Are But Hunks of Wood Remixes",
                    url: "/audio/ANIMAL CROSSING THEME SONG (OFFICIAL TRAP REMIX) - TH3 DARP.mp3",
                    // cover_art_url: "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg",
                },
                // {
                //     name: "Dusk To Dawn",
                //     artist: "Emancipator",
                //     album: "Dusk To Dawn",
                //     url: "https://521dimensions.com/song/DuskToDawn-Emancipator.mp3",
                //     cover_art_url: "https://521dimensions.com/img/open-source/amplitudejs/album-art/from-dusk-to-dawn.jpg",
                // },
                // {
                //     name: "Anthem",
                //     artist: "Emancipator",
                //     album: "Soon It Will Be Cold Enough",
                //     url: "https://521dimensions.com/song/Anthem-Emancipator.mp3",
                //     cover_art_url: "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg",
                // },
            ],
        });
    }
    const getTitle = useCallback(() => {
        const { name } = Amplitude.getActiveSongMetadata();
        const { artist } = Amplitude.getActiveSongMetadata();
        return `${name} by ${artist}`;
    }, [ Amplitude, open ]);

    const getName = useCallback(() => {
        const { name } = Amplitude.getActiveSongMetadata();
        return name;
    }, [ Amplitude, open ]);

    const getArtist = useCallback(() => {
        const { artist } = Amplitude.getActiveSongMetadata();
        return artist;
    }, [ Amplitude, open ]);

    const isPlaying = useCallback(() => {
        const { player_state } = Amplitude.getConfig();

        if (player_state === "paused") {
            // console.log("player_state paused", player_state);
            return false;
        }
        if (player_state === "stopped") {
            // console.log("player_state paused", player_state);
            return false;
        }
        // console.log("player_state else", player_state);
        return true;
    }, [ Amplitude.getConfig().player_state, play ]);
    // console.log(getArtist(), "player_state");
    useEffect(() => {
        isPlaying();
    }, [ play ]);

    // console.log("isPlaying", Amplitude.getConfig());
    useEffect(() => {
        window.onkeydown = function (e) {
            return !(e.keyCode === 32);
        };
        loadPlayer();
        getArtist();
        getName();
        isPlaying();
    }, [ ]);
    return (
        <div className="hidden lg:block">
            <div
                className={`hidden md:block fixed  list__item-roadmap border-theme-800 bg-theme-200 left-0 ml-4 z-70 
            ${window.location.pathname === "/den" ? "bottom-32" : "bottom-14"}
            `}
                style={{ zIndex: 100 }}
            >
                <div className="relative duration-100  ">
                    <button
                        type="button"
                        className="absolute duration-300 hover:scale-110 active:scale-95 transform flex items-center justify-center -top-5 -right-5 z-30 h-10 w-10 border-2 rounded-full overflow-hidden bg-white text-black bg-theme-400 border-olive"
                        onClick={() => { return setOpen(!open); }}
                    >
                        {open ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 fill-current border border-theme-800 rounded-full " viewBox="0 0 24 24">
                                <path d="M0 10h24v4h-24z" />
                            </svg>
                        ) : (

                            <svg
                                className="fill-current lg:h-4 h-3 border border-theme-800 rounded-full"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="#F3DFBF"
                                stroke="#F3DFBF"
                                strokeWidth="2"
                            >
                                <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                            </svg>
                        )}
                    </button>
                    <div title={getTitle()} className="duration-300   textbg  h-14 shadow-md  flex  rounded-md relative border border-theme-800 ">
                        <img alt="Doragon Logo" className=" transform duration-200  h-20 -translate-y-8" src={Doragon} />
                        {/* <img className=" transform duration-200  h-20 -translate-y-6" src="../../images/doragonlogo2.png" /> */}
                        <Levels playPauseButton={isPlaying()} />
                        {/* <div className="icon  ml-4 mr-4 pausedicon mt-2">
                                <span className="bg-opacity-80 bg-black  mt-2 pt-2" />
                                <span className="bg-opacity-80 bg-black" />
                                <span className="bg-opacity-80 bg-black" />
                            </div> */}

                        <div className={`flex block ${open ? "pl-3" : "-pl-3"}`}>

                            <div className={` text-left  pr-2 flex flex-col justify-center 
                            ${open ? "w-32" : "w-10"}
                            ${getName()?.length === undefined ? "w-5" : ""}
                            `}
                            >
                                <p
                                    className={`font-bold uppercase text-md truncate ${open ? "w-40" : "w-20"}   `}
                                    // data-amplitude-song-info="name"
                                >
                                    {getName() || "Aruarian Dance" }
                                </p>
                                <p
                                    className="uppercase  text-sm text-white-white opacity-40 truncate "
                                >
                                    {getArtist() || "Nujabes" }
                                </p>
                            </div>

                            {/* <div className="w-full grid grid-cols-3 rounded-md w-36 mr-2"> */}
                            <div className="h-control-panel  z-50 px-5 rounded-b-xl  border-t border-gray-200 flex items-center justify-between space-x-2 ml-3    ">
                                <div
                                    role="button"
                                    // onClick={() => {
                                    //     setPlay(!play);
                                    //     window.onkeydown = function (e) {
                                    //         return e.keyCode === 32;
                                    //     };
                                    // }}
                                    // tabIndex={0}
                                    className="cursor-pointer mb-4 amplitude-prev h-8 w-8 flex items-center justify-center transform hover:scale-110 active:scale-95"
                                >
                                    <svg width="24" height="24" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 32">
                                        <path d="M26 7C26 5.76393 24.5889 5.05836 23.6 5.8L11.6 14.8C10.8 15.4 10.8 16.6 11.6 17.2L23.6 26.2C24.5889 26.9416 26 26.2361 26 25V7Z" fill="#f8ead4" stroke="#f8ead4" strokeWidth="2" strokeLinejoin="round" />
                                        <path d="M6 5L6 27" stroke="#f8ead4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div
                                    className="cursor-pointer amplitude-play-pause w-14 h-14 border-theme-400 rounded-full bg-white border-2 border-play-pause-light-border shadow-xl flex items-center justify-center bg-theme-800  transform hover:scale-110 active:scale-95 "
                                    onClick={() => {
                                        setPlay(!play);
                                        window.onkeydown = function (e) {
                                            return e.keyCode === 32;
                                        };
                                    }}
                                    // tabIndex={0}
                                >
                                    <svg id="play-icon" className=" ml-1 justify-self-center self-center" width="20" viewBox="0 0 31 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M29.6901 16.6608L4.00209 0.747111C2.12875 -0.476923 0.599998 0.421814 0.599998 2.75545V33.643C0.599998 35.9728 2.12747 36.8805 4.00209 35.6514L29.6901 19.7402C29.6901 19.7402 30.6043 19.0973 30.6043 18.2012C30.6043 17.3024 29.6901 16.6608 29.6901 16.6608Z" className="fill-theme-200" />
                                    </svg>
                                    <svg id="pause-icon" width="20" viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="6" height="36" rx="3" className="fill-theme-200" />
                                        <rect x="18" width="6" height="36" rx="3" className="fill-theme-200" />
                                    </svg>
                                </div>
                                <div
                                    className="cursor-pointer amplitude-next mb-3  transform hover:scale-110 active:scale-95"
                                    onClick={() => {
                                        if (isPlaying()) {
                                            setPlay(true);
                                        }
                                        setPlay(false);
                                    }}
                                >
                                    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 7C6 5.76393 7.41115 5.05836 8.4 5.8L20.4 14.8C21.2 15.4 21.2 16.6 20.4 17.2L8.4 26.2C7.41115 26.9416 6 26.2361 6 25V7Z" fill="#f8ead4" stroke="#f8ead4" strokeWidth="2" strokeLinejoin="round" />
                                        <path d="M26 5L26 27" stroke="#f8ead4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MusicPlayer;
